import api from "../../../services/api.service"


export const kanbanModule = {
    namespaced: true,
    state: {},
    getters: {
        canReadKanban(state, getters, rootState, rootGetters) {
            return rootGetters['user/canRead']('kanban');
        },
        canEditKanban(state, getters, rootState, rootGetters) {
            return rootGetters['user/canEdit']('kanban');
        },
    },
    mutations: {},
    actions: {
        getData() {
            return new Promise(function (resolve) {

                resolve();

            })
        },


        getKanbanById(actions, id) {
            return new Promise((resolve, reject) => {

                api.sendRequest('kanban/id/' + id, {}).then(
                    (ans) => {

                        if (ans["kanban"] == null)
                            return reject('Could not get kanban ');

                        resolve(ans["kanban"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        getAllActiveRealEstates(actions) {
            return new Promise((resolve, reject) => {

                api.sendRequest('kanban/get' , {
                    buids: actions.rootGetters['data/getSelectedBranchesIds'].join(","),

                }).then(
                    (ans) => {

                        if (ans["RealEstates"] == null)
                            return reject('Could not get active kanbans ');

                        resolve(ans);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },

        updateKanban(actions, kanban) {
            return new Promise((resolve, reject) => {

                api.sendRequest('kanban/update', null, kanban).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Update kanban', message: 'Kanban updated successfully'},
                            {root: true});

                        if (ans["kanbanId"] == null)
                            return reject('Could not update kanban ');

                        resolve(ans["kanbanId"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        pushList(actions, {propertyId,list}) {
            return new Promise((resolve, reject) => {


                api.sendRequest('kanban/pushToList/'+propertyId, null, list).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Kanban', message: 'Updated successfully'},
                            {root: true});

                        if (ans["kanban"] == null)
                            return reject('Could not add list ');

                        resolve(ans["kanban"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },

    },
};
