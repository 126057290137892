import api from "../../../services/api.service"
import {vue} from '@/main'

export const chatModule = {
    namespaced: true,
    state: {
        connection: null,
        connected: false,
        authenticated: false,
        rooms: [],
        profile: {},
        connectedUsers: [],
        messages: {}
    },
    getters: {
        isConnected(state){
            return state.connected === true;
        },
            isAuthenticated(state){
            return state.connected === true && state.authenticated === true;
        },
        connection(state) {
            return state.connection;
        },
        credentials(state, getters, rootState, rootGetters) {
            let credentials = {};
            credentials.username = rootGetters['user/myUsername'];
            credentials.token = localStorage.Bearer;
            return credentials;
        },
        rooms(state) {
            return state.rooms
        },
        connectedUsers(state){
            return state.connectedUsers;
        },
        getMessagesByRoom: (state) => (roomId)=>{
           return  state.messages[roomId] || {totalElements:0, loadedPages: [], roomId, messages: []}
        }
    },
    mutations: {
        setConnection(state, connection) {
            if(connection != null){
                state.connection = connection;
                state.connected = true;
            } else {
                state.connection = null;
                state.connected = false;
            }
        },
        setProfile(state, profile) {
            state.profile = profile;
            state.authenticated = true;
        },
        setRooms(state, rooms) {
            state.rooms = rooms || [];
        },
        setRoom(state, newRoom) {
            const index = state.rooms.findIndex(room => room.id === newRoom.id);
            if(index > -1){
                state.rooms[index] = newRoom
            } else {
                state.rooms.push(newRoom)
            }
            state.rooms = [...state.rooms]
        },
        removeRoom(state, roomId) {
            const index = state.rooms.findIndex(room => room.id === roomId);
            if(index > -1){
                state.rooms.splice(index, 1);
            }
            state.rooms = [...state.rooms]
        },
        setUserConnected(state, userId){
            state.connectedUsers.push(userId);
        },
        setUserDisconnected(state, userId){
            const index = state.connectedUsers.indexOf(userId);
            if (index > -1) {
                state.connectedUsers.splice(index, 1);
            }
        },
        setConnectedUsers(state, users){
            state.connectedUsers = users || [];
        },
        setMessages(state, data) {
            let roomMessages = state.messages[data.room];
            if (roomMessages != null) {
                let messages = roomMessages.messages;
                let messagesMap = messages.map((message, index) => ({...message, index})).toObject("id");
                data.messages.forEach(message=>{
                    let currentMessage = messagesMap[message.id];
                    if(currentMessage == null){
                        messages.unshift(message);
                        return;
                    }
                    messages[currentMessage.index] = message;
                })
                roomMessages.totalElements = data.totalElements;
                roomMessages.loadedPages.add(data.page)
            } else {
                roomMessages = {
                    totalElements: data.totalElements,
                    loadedPages: new Set([data.page]),
                    roomId: data.room,
                    messages: (data.messages || []).reverse()
                }
            }
            roomMessages.loaded = roomMessages.loadedPages.size >= data.totalPages
            roomMessages.lastPageLoaded = Math.max(...roomMessages.loadedPages);
            state.messages[data.room] = {...roomMessages};

            vue.$root.$emit("chat::message-updated", {roomId:data.room});
        },
        resetMessages(state, roomId) {
            state.messages[roomId] = null;
        },
        addMessages(state, data){
            let roomMessages = state.messages[data.room];
            if(roomMessages == null){
                return;
            }
            if(roomMessages.messages == null){
                roomMessages.messages = [];
            }
            roomMessages.messages.push(data.newMessage);
            roomMessages.totalElements = data.totalElements + 1;
            vue.$root.$emit("chat::message-updated", {roomId:data.room,  tempId:data.tempId});
        },
        removeMessages(state, data){
            let roomMessages = state.messages[data.room];
            roomMessages.messages = roomMessages.messages.filter(message => !data.deletedMessages.includes(message.id))
            if(data.unsetMessages != null && data.unsetMessages.length > 0){
                roomMessages.messages.forEach(message => {
                    if(message.replyMessageId != null && data.unsetMessages.includes(message.id) ){
                        delete message.replyMessageId;
                        delete message.replyMessage;
                    }
                })
            }
            state.messages[data.room] = {...roomMessages};
            vue.$root.$emit("chat::message-updated", {roomId:data.room});
        }
    },
    actions: {
        connect({dispatch, commit, rootGetters}) {
            if(window.config.chatUrl == null){
                return;
            }
            let ws = new WebSocket(window.config.chatUrl);
            ws.onopen = () => commit('setConnection', ws)
            ws.onclose  = () => dispatch('onDisconnect');
            ws.onmessage = data => dispatch('onMessage', data);
        },
        onDisconnect({dispatch, commit}){
            commit('setConnection', null);
            setTimeout(()=>dispatch('connect'), 5000)
        },
        onMessage({dispatch, commit, getters, rootGetters}, data) {
            let message = JSON.parse(data.data);
            if(message.status !== true){
                dispatch('notifications/error', {title: 'Chat', message: message.message}, {root: true});
            }
            switch (message.objects.action) {
                case "CONNECTION":
                    return dispatch('auth');
                case "AUTH":
                    commit("setProfile", message.objects.profile)
                    dispatch('refreshConnected');
                    return dispatch('refreshRooms');
                case "CONNECTED":
                    commit("setConnectedUsers", message.objects.usersId)
                    return;
                case "USER_CONNECTED":
                    commit("setUserConnected", message.objects.userId)
                    return;
                case "USER_DISCONNECTED":
                    commit("setUserDisconnected", message.objects.userId)
                    return;
                case "ROOMS":
                    commit("setRooms", message.objects.rooms)
                    return;
                case "ROOM":
                    commit("setRoom", message.objects.room)
                    return;
                case "SEEN_MESSAGES":
                    commit("setRoom", message.objects.room)
                    return;
                case "NEW_ROOM":
                    commit("setRoom", message.objects.newRoom)
                    return;
                case "EDIT_ROOM":
                    commit("setRoom", message.objects.room)
                    return;
                case "LEAVE_ROOM": {
                    let userId = message.objects.removedUser;
                    if (rootGetters['user/myId'] === userId)
                        commit("removeRoom", message.objects.roomId)
                    return;
                }
                case "GET_MESSAGES":
                    commit("setMessages", message.objects)
                    return;
                case "MESSAGE":
                    dispatch('getRoom', message.objects.room)
                    commit("addMessages", message.objects)
                    return;
                case "DELETE_MESSAGE":
                    dispatch('getRoom', message.objects.room)
                    commit("removeMessages", message.objects)
                    return;
                case "TYPING":
                    vue.$root.$emit("chat::message-typing", message.objects);
                    return
            }
        },
        auth({dispatch, getters}) {
            dispatch('sendWSMessage', {action: "AUTH", "data": getters.credentials})
        },
        refreshConnected({dispatch}) {
            dispatch('sendWSMessage', {action: "CONNECTED"})
        },
        refreshRooms({dispatch}) {
            dispatch('sendWSMessage', {action: "ROOMS"})
        },
        getRoom({dispatch}, roomId) {
            dispatch('sendWSMessage', {action: "ROOM", data: roomId})
        },
        retrieveMessages({dispatch}, {roomId, page, messagesPerPage}) {
            dispatch('sendWSMessage', {action: "GET_MESSAGES", "data": {roomId, page, messagesPerPage}})
        },
        sendMessage({dispatch}, message) {
            dispatch('sendWSMessage', {action: "MESSAGE", "data": message})
        },
        deleteMessage({dispatch}, messageId) {
            dispatch('sendWSMessage', {action: "DELETE_MESSAGE", "data": messageId})
        },
        sendTyping({dispatch}, roomId) {
            dispatch('sendWSMessage', {action: "TYPING", "data": roomId})
        },
        seenMessage({dispatch}, {roomId, messagesId}) {
            if(messagesId == null || messagesId.length <= 0){
                return
            }
            dispatch('sendWSMessage', {action: "SEEN_MESSAGES", "data": {roomId, messagesId}})
        },
        createRoom({dispatch}, room) {
            dispatch('sendWSMessage', {action: "NEW_ROOM", "data": room})
        },
        editRoom({dispatch}, room) {
            dispatch('sendWSMessage', {action: "EDIT_ROOM", "data": room})
        },
        sendWSMessage({dispatch, commit, getters, rootGetters}, data) {
            let connection = getters.connection;
            connection.send(JSON.stringify(data));
        },
    },
};
