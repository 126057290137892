import api from "../../services/api.service"


export const usersModule = {
    namespaced: true,
    state: {
        data: {},
    },
    getters: {
        getOrganization: (state) => (id) => {
            if (state.data.orgs[id] == null)
                return {};
            return state.data.orgs[id];
        },
        getRoleName: (state) => (rId) => {
            if (state.data == null || state.data.roles == null || state.data.roles[rId] == null)
                return "";
            return state.data.roles[rId]
        },
        getRoles(state){
            if (state.data == null || state.data.roles == null)
                return {};
            return state.data.roles;
        },
        getRolesAsArray(state){
            if (state.data == null || state.data.roles == null)
                return [];
            return Object.keys(state.data.roles).map(k=>({id:parseInt(k),name:state.data.roles[k]}));
        },
        getAllBranches(state){
            if(state.data == null || state.data.branches == null)
                return [];

            return state.data.branches;
        },
        getMailsAlias(state){
            if(state.data == null || state.data.mails == null)
                return {};

            return state.data.mails;
        },
    },
    mutations: {
        updateData(state, data) {
            let stateData = {};
            stateData.status = data["UserStatus"];
            stateData.roles = data["Roles"];
            stateData.orgs = data["Organization"];
            stateData.branches = data["branches"];
            stateData.mails = data["Mails"];
            state.data = stateData;
        }
    },
    actions: {
        getData({state,commit, dispatch}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('superuser/user/data').then(
                    (ans) => {

                        commit('updateData', ans);
                        resolve(state);
                    }, (error) => {
                        dispatch('notifications/error',
                            {
                                title: 'Getting data',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        refresh(actions, {filter,amountPerPage,page}) {
            if (typeof filter !== "object")
                filter = {}
            return new Promise(function (resolve, reject) {
                api.sendRequest('superuser/user/get', {
                    buids: actions.rootGetters['data/getSelectedBranchesIds'].join(","),
                    amount: amountPerPage,
                    page                                                                      ,
                    },filter).then(
                    (ans) => {
                        if (ans["Users"] == null)
                            return reject("users not loaded");
                        resolve(ans);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        //Get a user
        get({dispatch}, {id}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('superuser/user/id/'+id).then(
                    (ans) => {
                        let user = {businessUnits: ans["branches"], ...ans["User"]};
                        console.log(user);
                        resolve(user)
                    }, (error) => {

                        reject(error.message)
                    })
            })
        },
        update({dispatch, state}, {id, user}) {
            return new Promise(function (resolve, reject) {
                //Check if user is not undefined
                if (typeof user !== "object")
                    reject("Invalid user")
                //generate a new object in order to not update the user object
                //In order to prevent a bug or a mistake, the id must be send
                let request = {...user,id};


                api.sendRequest('superuser/user/update', null, request).then(
                    (ans) => {
                        //In case of success send notification
                        dispatch('notifications/success',
                          {title: {text:'%s updated',arguments:['user']}, message:{text:'%s updated successfully',arguments:['user']} },
                            {root: true});
                        resolve(ans)
                    }, (error) => {
                        dispatch('notifications/error',
                            {
                                title: 'Update user',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        register({dispatch, state}, {user}) {
            return new Promise(function (resolve, reject) {
                //Check if user is not undefined
                if (typeof user !== "object")
                    reject("Invalid user")

                api.sendRequest('superuser/user/add', null,user).then(
                    (ans) => {
                        //In case of success send notification
                        dispatch('notifications/success',
                          {title: {text:'Register %s',arguments:['client']}, message:{text:'%s created successfully with id: %s',arguments:['Client',ans.UserId]} },
                          {root: true});
                        resolve(ans["UserId"])
                    }, (error) => {
                        dispatch('notifications/error',
                            {
                                title: 'Register user',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
    }
};
