import api from '../../../services/api.service'

export const messageModule = {
    namespaced: true,
    state: {},
    getters: {
        canReadMonetary(state, getters, rootState, rootGetters) {
            return rootGetters['user/canRead']('client_message');
        },
        canEditMonetary(state, getters, rootState, rootGetters) {
            return rootGetters['user/canEdit']('client_message');
        },

    },
    mutations: {},
    actions: {
        getData() {
            return new Promise(function (resolve) {

                resolve();

            })
        },

        getAllMessages(actions) {

            return new Promise(function (resolve, reject) {
                api.sendRequest('messages/unseen',{

                    buids: actions.rootGetters['data/getSelectedBranchesIds'].join(","),

                }).then(
                    (ans) => {
                        if (ans["messages"] == null)
                            return reject('Could not get Messages ');

                        resolve(ans);
                    }, (error) => {

                        reject(error.message)
                    })
            })

        },
        setMessagesSeen(actions,contractId) {

            return new Promise(function (resolve, reject) {
                api.sendRequest('messages/seen/'+contractId).then(
                    (ans) => {


                        resolve(ans);
                    }, (error) => {

                        reject(error.message)
                    })
            })

        },

    },
};
