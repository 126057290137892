import api from '../../../services/api.service'

export const orderModule = {
    namespaced: true,
    state: {
        data: {
            types: {},
            loaded: false
        },
        orders: [],
        page: {
            amountPerPage: 20,
            total: 1,
            number: 1,
        },
    },
    getters: {
        canReadOrders(state, getters, rootState, rootGetters) {
            return rootGetters['user/canRead']('order')
        },
        canEditOrders(state, getters, rootState, rootGetters) {
            return rootGetters['user/canEdit']('order')
        },
        isLoaded(state) {
            return state.data.loaded
        },
        getTypes(state) {
            if (state.data.loaded !== true) {
                return {}
            }
            return state.data.types
        },
        hasTypes(state) {
            if (state.data.loaded !== true) {
                return false
            }
            return Object.values(state.data.types).length > 1
        },
        getTypeName: (state) => (id) => {
            if (state.data.loaded !== true || state.data.types == null || state.data.types[id] == null) {
                return ''
            }
            return state.data.types[id].name
        },
        getTypeDefaultIsPublic: (state) => (id) => {
            if (state.data.loaded !== true || state.data.types == null || state.data.types[id] == null) {
                return ''
            }
            return state.data.types[id].permission !== 1
        },
        allOrderStatus2() {
            return ["PENDING", "WAITING_FOR_PAYMENT", "PAYMENT_FAILED", "CANCELLED", "PAID", "PAID_CASH", "SHIPPED", "COMPLETED", "CART", "CASH_OPEN"]
        },
        allOrderStatus() {
            return [
                {value: "PENDING", text: 'PENDING'},
                {value: 'WAITING_FOR_PAYMENT', text: 'WAITING_FOR_PAYMENT'},
                {value: 'PAYMENT_FAILED', text: 'PAYMENT_FAILED'},
                {value: 'CANCELLED', text: 'CANCELLED'},
                {value: 'PAID', text: 'PAID'},
                {value: 'PAID_CASH', text: 'PAID_CASH'},
                {value: 'SHIPPED', text: 'SHIPPED (coming soon)', disabled: true},
                {value: 'COMPLETED', text: 'COMPLETED'},
                {value: 'CART', text: 'CART', disabled: true},
                {value: 'CASH_OPEN', text: 'CASH_OPEN', disabled: true}]
        },
        allMonetaryStatus() {
            return [
                {value: 'PAID', text: 'PAID'},
                {value: 'CANCELLED', text: 'CANCELLED'},
              /*  {value: "PENDING", text: 'PENDING'},
                {value: 'EXPIRED', text: 'EXPIRED'},
                {value: 'PAYMENT_FAILED', text: 'PAYMENT_FAILED'},
                {value: 'CASH_TAKEN', text: 'CASH OUT'},
                {value: 'CASH_IN', text: 'CASH IN'},
                {value: 'PAID_CASH', text: 'PAID_CASH'},*/
            ]
        }
    },
    mutations: {
        updateOrders(state, data) {
            //todo get orders
            state.orders = data['orders']
            state.page.amountPerPage = data['Amount']
            state.page.total = data['Total']
            state.page.number = data['Page']
            console.log("updateOrders state: {}", state)
        },
        updateData(state, data) {
            if (data['OrderStatusTypes'] != null) {
                state.data.types = data['OrderStatusTypes']
            }
            state.data.loaded = true
        }
    },
    actions: {
        getData({state, commit, dispatch}, force) {
            return new Promise(function (resolve, reject) {
                if (force !== true && state.data.loaded) {
                    resolve()
                }
                api.sendRequest('order/data')
                    .then(
                        (ans) => {

                            commit('updateData', ans)
                            resolve()
                        }, (error) => {
                            dispatch('notifications/error', {
                                title: 'Getting data',
                                message: (typeof error === 'object' && error.message !== undefined ? error.message : error)
                            }, {root: true})
                            reject(error.message)
                        })
            })
        },

        //This function will return all the but not save it
        getOrders({dispatch, state,rootGetters}, {page, amountPerPage, filter, sortby, sortcolumn}) {
            if (typeof filter !== 'object') {
                filter = {}
            }
            return new Promise(function (resolve, reject) {
                dispatch('getData', false)
                    .finally(() => {
                        api.sendRequest('order/get', {
                                amount: (amountPerPage == null ? state.page.amountPerPage : amountPerPage)
                                , page: page == null ? 0 : page,
                                buids: rootGetters['data/getSelectedBranchesIds'].join(",")

                            , sortby
                                , sortcolumn,
                            }
                            , filter)
                            .then(
                                (ans) => {

                                    if (ans['orders'] == null) {
                                        return reject('Orders failed to refresh')
                                    }

                                    resolve({
                                        orders: ans['orders'],
                                        amountPerPage: ans['Amount'],
                                        total: ans['Total'],
                                        page: ans['Page']
                                    })
                                }, (error) => {

                                    reject(error.message)
                                })
                    })

            })
        },
        //Get a Order
        get({dispatch}, {id}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('order/id/' + id)
                    .then(
                        (ans) => {

                            resolve(ans)
                        }, (error) => {

                            reject(error.message)
                        })
            })
        },
        register({dispatch}, {order}) {
            return new Promise(function (resolve, reject) {
                //Check if client is not undefined
                if (typeof order !== 'object') {
                    return reject('Invalid order')
                }

                api.sendRequest('order/add', null, order)
                    .then(
                        (ans) => {
                            //In case of success send notification
                            dispatch('notifications/success',
                                {
                                    title: {text: 'Register %s', arguments: ['order']},
                                    message: {
                                        text: '%s created successfully with id: %s',
                                        arguments: ['order', ans.OrderId]
                                    }
                                },
                                {root: true})

                            resolve(ans)
                        }, (error) => {
                            dispatch('notifications/error',
                                {
                                    title: 'Register order',
                                    message: (typeof error === 'object' && error.message !== undefined ? error.message : error)
                                },
                                {root: true})
                            reject(error.message)
                        })

            })
        },
        update({dispatch}, {id, order}) {
            return new Promise(function (resolve, reject) {
                //Check if client is not undefined
                if (typeof order !== 'object') {
                    return reject('Invalid order')
                }
                //generate a new object in order to not update the client object and add the field action
                //In order to prevent a bug or a mistake, the id must be send
                let request = {
                    ...order,
                    id: id
                }

                api.sendRequest('order/update', null, request)
                    .then(
                        (ans) => {
                            //In case of success send notification
                            dispatch('notifications/success',
                                {
                                    title: {text: '%s updated', arguments: ['order']},
                                    message: {text: '%s updated successfully', arguments: ['order']}
                                },
                                {root: true})

                            resolve(ans)
                        }, (error) => {
                            dispatch('notifications/error',
                                {
                                    title: 'Update order',
                                    message: (typeof error === 'object' && error.message !== undefined ? error.message : error)
                                },
                                {root: true})
                            reject(error.message)
                        })
            })
        },
        deleteOrder({dispatch}, id) {
            return new Promise(function (resolve, reject) {

                api.sendRequest('order/delete/' + id)
                    .then(
                        (ans) => {
                            //In case of success send notification
                            dispatch('notifications/success',
                                {
                                    title: {text: '%s deleted', arguments: ['order']},
                                    message: {text: '%s deleted successfully', arguments: ['order']}
                                },
                                {root: true})

                            resolve(ans)
                        }, (error) => {
                            dispatch('notifications/error',
                                {
                                    title: 'Update order',
                                    message: (typeof error === 'object' && error.message !== undefined ? error.message : error)
                                },
                                {root: true})
                            reject(error.message)
                        })
            })
        },
        downloadReport(actions, filter) {
            return new Promise(function (resolve, reject) {
                console.log('downloadReport()')
                api.downloadfile('/excel/download/order', {
                    bearer: encodeURI(localStorage.Bearer),
                    filter: btoa(filter),
                    buids: actions.rootGetters['data/getSelectedBranchesIds'].join(',')
                })
            })
        },
        getExcelUrl(actions, filter) {
            return new Promise(function (resolve, reject) {
                let params = {
                    bearer: encodeURI(localStorage.Bearer),
                    filter: (JSON.stringify(filter)),
                    buids: actions.rootGetters['data/getSelectedBranchesIds'].join(",")
                };
                let query = new URLSearchParams(params).toString()
                resolve(window.config.apiUrl + '/excel/download/order?' + query);
            });
        }
        ,
        getOrderById(actions, id) {
            return new Promise((resolve, reject) => {

                api.sendRequest('order/id/' + id, {}).then(
                    (ans) => {
                        if (ans["order"] == null)
                            return reject('Could not get order');
                        resolve(ans["order"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        getOrderStats(actions, {from, until}) {
            return new Promise((resolve, reject) => {

                api.sendRequest('order/stats', {from, until}).then(
                    (ans) => {
                        if (ans["OrderStats"] == null)
                            return reject('Could not get order stats');
                        resolve(ans["OrderStats"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        getOrderProductsStats(actions, {from, until}) {
            return new Promise((resolve, reject) => {

                api.sendRequest('order/top-products', {from, until}).then(
                    (ans) => {
                        resolve(ans);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
    },
};
