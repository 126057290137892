import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'


// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// Feather font icon - For form-wizard
// require('@core/assets/fonts/fontawesome/css/all.css') // For form-wizard
require('@core/assets/fonts/fontawesomeV6/css/all.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

require('@core/scss/vue/libs/vue-flatpicker.scss');
