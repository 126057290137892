String.prototype.format = function () {
    let args = [...arguments];
    if (args.length === 1 && Array.isArray(args[0]))
        args = [...args[0]];
    return args.reduce((p, c) => p.replace(/%s/, c), this);
};

Array.prototype.toObject = function (key, onItemAdd, defaultItems) {
    if (typeof defaultItems != "object")
        defaultItems = {};
    return this.reduce((obj, item) => Object.assign(obj, {[item[key]]: (typeof onItemAdd == "function" ? onItemAdd(item) : item)}), {...defaultItems})
};

Array.prototype.groupBy = function (key) {
    return this.reduce(function (rv, x) {
        let xKey =  (typeof key == "function") ? key(x) : x[key];
        (rv[xKey] = rv[xKey] || []).push(x);
        return rv;
    }, {});
};

Array.prototype.mergeNames = function () {
    return this.reduce((mergedNames, name) => mergedNames + (name || "").trim() + " ", "")
}

Array.prototype.mergeArraysInObject = function (key, onArrAdd) {
    return this.reduce((mergedArr, item) => {
        let arr = item[key];
        if (!Array.isArray(arr))
            return mergedArr;

        return mergedArr.concat(typeof onArrAdd == "function" ? onArrAdd(arr) : arr);
    }, [])
};

Array.prototype.move = function (from, to) {
    this.splice(to, 0, this.splice(from, 1)[0]);
};

export function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}

/***
 * Deep merge two object
 * @param target{Object}
 * @param source{Object}
 * @param override {Boolean} : Set true to override the path
 * @returns {Object}
 */
export function mergeDeep(target, source,override) {
    let output = Object.assign({}, target || {});
    if (!isObject(target) || !isObject(source))
        return output;
    Object.keys(source).forEach(key => {
        if (!isObject(source[key])) {
            if(override === true || output[key] === null) {
                Object.assign(output, {[key]: source[key]});
            }
            return;
        }
        if (!(key in target)) {
            Object.assign(output, {[key]: source[key]});
            return;
        }
        output[key] = mergeDeep(target[key], source[key]);
    });
    return output;

}
