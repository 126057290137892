import {vue} from "../../main";

export const translatorModule = {
    namespaced: true,
    state: {
        lang: "",
    },
    getters: {
        msg: (state) => (key, data) => {
            if (key == null || typeof key !== "string")
                return key;
            let x = state.lang;//Reactive update
            if (vue.$te(key) && vue.$t(key) !== '') {
                return data ? vue.$t(key, data) : vue.$t(key);
            }
            let temp = key.replace(/ /g, "");
            if (vue.$te(temp) && vue.$t(temp) !== '') {
                return vue.$t(temp, data);
            }
            temp = temp.toLocaleLowerCase();
            return vue.$te(temp) && vue.$t(temp) !== '' ? vue.$t(temp, data) : key;
        },
        getCurrentLang(state){
            return state.lang.toLowerCase();
        },
        currentLang(){
            return localStorage.selectedLang || "en";
        },
        currentFormattedLang(state){

            if(state.lang == null)
                return "fr_FR";
            switch (state.lang.toLowerCase()) {
                case "fr":
                    return "fr_FR";
                case "en":
                    return "en_US";
                case "es":
                    return "es_ES";
                case "de":
                    return "de_DE";
                case "pt":
                    return "pt_BR";
                case "he":
                    return "he_IL";
            }
            return "en_US";
        }
    },
    mutations: {
        setLanguage(state, lang){
            state.lang = lang || localStorage.selectedLang;
            vue.$i18n.locale= state.lang;
            localStorage.setItem('selectedLang', state.lang)
            vue.$root.$emit("bv::refresh::table");
            vue.$root.$emit('crm::lang-updated',state.selected)
            state.loaded = true;
            vue.$root.$emit("crm::language::updated");
            return state.selected;
        }
    },
    actions: {

    },

};
