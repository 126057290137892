import api from '../../../services/api.service'

export const notesModule = {
  namespaced: true,
  state: {
    data: {
      types: {},
      loaded: false
    },
    notes: [],
    page: {
      amountPerPage: 20,
      total: 1,
      number: 1,
    },
  },
  getters: {
    canReadNotes(state, getters, rootState, rootGetters) {
      return rootGetters['user/canRead']('note')
    },
    canEditNotes(state, getters, rootState, rootGetters) {
      return rootGetters['user/canEdit']('note')
    },
    isLoaded(state) {
      return state.data.loaded
    },
    getTypes(state) {
      if (state.data.loaded !== true) {
        return {}
      }
      return state.data.types
    },
    hasTypes(state) {
      if (state.data.loaded !== true) {
        return false
      }
      return Object.values(state.data.types).length > 1
    },
    getTypeName: (state) => (id) => {
      if (state.data.loaded !== true || state.data.types == null || state.data.types[id] == null) {
        return ''
      }
      return state.data.types[id].name
    },
    getTypeDefaultIsPublic: (state) => (id) => {
      if (state.data.loaded !== true || state.data.types == null || state.data.types[id] == null) {
        return ''
      }
      return state.data.types[id].permission !== 1
    },

  },
  mutations: {
    updateNotes(state, data) {
      //todo get notes
      state.notes = data['Notes']
      state.page.amountPerPage = data['Amount']
      state.page.total = data['Total']
      state.page.number = data['Page']
    },
    updateData(state, data) {
      if (data['NoteTypeNames'] != null) {
        state.data.types = data['NoteTypeNames']
      }
      state.data.loaded = true
    }
  },
  actions: {
    getData({ state, commit, dispatch }, force) {
      return new Promise(function (resolve, reject) {
        if (force !== true && state.data.loaded) {
          resolve()
        }
        api.sendRequest('note/data')
          .then(
            (ans) => {

              commit('updateData', ans)
              resolve()
            }, (error) => {
              dispatch('notifications/error', {
                title: 'Getting data',
                message: (typeof error === 'object' && error.message !== undefined ? error.message : error)
              }, { root: true })
              reject(error.message)
            })
      })
    },
    //This function will save the notes in this class (in vuex store)
    getAllNotes({ dispatch, commit, state }, { page, amountPerPage, filter }) {
      state.status.loaded = false
      if (typeof filter !== 'object') {
        filter = {}
      }
      return new Promise(function (resolve, reject) {
        dispatch('getNotes', {
          amount: (amountPerPage == null ? state.page.amountPerPage : amountPerPage),
          page: page == null ? 0 : page,
          filter
        })
          .then(
            (ans) => {

              if (ans['Notes'] == null) {
                return reject('Notes failed to refresh')
              }

              commit('updateNotes', ans)
              resolve(state)
            }, (error) => {

              reject(error.message)
            })
      })
    },
    //This function will return all the but not save it
    getNotes({ dispatch, state }, { page, amountPerPage, filter }) {
      if (typeof filter !== 'object') {
        filter = {}
      }
      return new Promise(function (resolve, reject) {
        dispatch('getData', false)
          .finally(() => {
            api.sendRequest('note/get', {
              amount: (amountPerPage == null ? state.page.amountPerPage : amountPerPage),
              page: page == null ? 0 : page,
            }, filter)
              .then(
                (ans) => {

                  if (ans['Notes'] == null) {
                    return reject('Notes failed to refresh')
                  }

                  resolve({
                    notes: ans['Notes'],
                    amountPerPage: ans['Amount'],
                    total: ans['Total'],
                    page: ans['Page']
                  })
                }, (error) => {

                  reject(error.message)
                })
          })

      })
    },
    //Get a Note
    get({ dispatch }, { id }) {
      return new Promise(function (resolve, reject) {
        api.sendRequest('note/id/' + id)
          .then(
            (ans) => {

              resolve(ans.Note)
            }, (error) => {

              reject(error.message)
            })
      })
    },
    register({ dispatch }, { note }) {
      return new Promise(function (resolve, reject) {
        //Check if client is not undefined
        if (typeof note !== 'object') {
          return reject('Invalid note')
        }

        api.sendRequest('note/add', null, note)
          .then(
            (ans) => {
              //In case of success send notification
              dispatch('notifications/success',
                { title: { text: 'Register %s', arguments: ['note'] }, message: { text: '%s created successfully with id: %s', arguments: ['note', ans.NoteId] } },
                { root: true })

              resolve(ans)
            }, (error) => {
              dispatch('notifications/error',
                {
                  title: 'Register note',
                  message: (typeof error === 'object' && error.message !== undefined ? error.message : error)
                },
                { root: true })
              reject(error.message)
            })

      })
    },
    update({ dispatch }, { id, note }) {
      return new Promise(function (resolve, reject) {
        //Check if client is not undefined
        if (typeof note !== 'object') {
          return reject('Invalid note')
        }
        //generate a new object in order to not update the client object and add the field action
        //In order to prevent a bug or a mistake, the id must be send
        let request = {
          ...note,
          id: id
        }

        api.sendRequest('note/update', null, request)
          .then(
            (ans) => {
              //In case of success send notification
              dispatch('notifications/success',
                { title: { text: '%s updated', arguments: ['note'] }, message: { text: '%s updated successfully', arguments: ['note'] } },
                { root: true })

              resolve(ans)
            }, (error) => {
              dispatch('notifications/error',
                {
                  title: 'Update note',
                  message: (typeof error === 'object' && error.message !== undefined ? error.message : error)
                },
                { root: true })
              reject(error.message)
            })
      })
    },
    deleteNote({ dispatch }, id) {
      return new Promise(function (resolve, reject) {

        api.sendRequest('note/delete/' + id)
          .then(
            (ans) => {
              //In case of success send notification
              dispatch('notifications/success',
                { title: { text: '%s deleted', arguments: ['note'] }, message: { text: '%s deleted successfully', arguments: ['note'] } },
                { root: true })

              resolve(ans)
            }, (error) => {
              dispatch('notifications/error',
                {
                  title: 'Update note',
                  message: (typeof error === 'object' && error.message !== undefined ? error.message : error)
                },
                { root: true })
              reject(error.message)
            })
      })
    },
    downloadReport(actions, filter) {
      return new Promise(function (resolve, reject) {
        console.log('downloadReport()')
        api.downloadfile('/excel/download/note', {
          bearer: encodeURI(localStorage.Bearer),
          filter: btoa(filter),
          buids: actions.rootGetters['data/getSelectedBranchesIds'].join(',')
        })
      })
    },
    getExcelUrl(actions, filter) {
      return new Promise(function (resolve, reject) {
        let params = {
          bearer: encodeURI(localStorage.Bearer),
          filter: (JSON.stringify(filter)),
          buids: actions.rootGetters['data/getSelectedBranchesIds'].join(",")
        };
        let query = new URLSearchParams(params).toString()
        resolve(window.config.apiUrl + 'excel/download/note?' + query);
      });
    }

  },
};
