import api from "../../services/api.service"


export const mailEventsModule = {
    namespaced: true,
    state: {

        data: {
            names: [],
            langs: [],
        },
        status: {
            loaded: false
        },


    },
    mutations: {

        updateData(state, data) {
            state.data.names = data["MailEvents"];
            state.data.langs = data["AllBuLanguages"];
        }
    },
    getters: {
        getNames(state) {
            if (state.data.names == null)
                return [];
            return state.data.names;
        },
        getNameByIndex: (state) => (index) => {
            if (state.data.names == null)
                return null;
            return state.data.names[index];
        },
        getLangs(state) {
            if (state.data.langs == null)
                return [];
            return state.data.langs;
        },

    },
    actions: {
        loadData({state, dispatch}) {
            return new Promise(function (resolve) {
                //TODO: CHECK DATA IS LOAD
                dispatch('getData').then(() => {
                    resolve();
                }).catch((e) => {
                    console.log("mailevent.loadData() error", e);
                    resolve(e)
                });
            });
        },
        getData({state, commit, dispatch}) {
            state.status.loaded = false;
            return new Promise(function (resolve, reject) {
                api.sendRequest('mailevents/data').then(
                    (ans) => {

                        commit('updateData', ans);
                        resolve(state);
                    }, (error) => {
                        dispatch('notifications/error',
                            {
                                title: 'Getting data',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        refresh(actions, filter) {
            if (typeof filter !== "object")
                filter = {};
            return new Promise(function (resolve, reject) {
                actions.dispatch('loadData').then(() => {
                    api.sendRequest('mailevents/get',
                        {
                            buids: actions.rootGetters['data/getSelectedBranchesIds'].join(","),
                            },filter).then(
                        (ans) => {

                            if (ans["Mails"] == null)
                                return reject("Invalid Mails");

                            resolve(ans["Mails"]);
                        }, (error) => {

                            reject(error.message)
                        })

                });
            })
        },
        //Get a mailEvent
        get({dispatch}, {id}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('mailevents/id/'+id).then(
                    (ans) => {

                        resolve(ans.Mails)
                    }, (error) => {

                        reject(error.message)
                    })
            })
        },
        update({dispatch}, {id, mailEvent}) {
            return new Promise(function (resolve, reject) {
                //Check if mailEvent is not undefined
                if (typeof mailEvent !== "object")
                    reject("Invalid mailEvent");
                //generate a new object in order to not update the mailEvent object and add the field action
                let body = {...mailEvent};
                //In order to prevent a bug or a mistake, the id must be send
                body['id'] = id;

                api.sendRequest('mailevents/update',null, body).then(
                    (ans) => {
                        //In case of success send notification
                        dispatch('notifications/success',
                            {title: 'Update mailEvent', message: 'MailEvent updated successfully'},
                            {root: true});

                        //send error
                        resolve(ans)
                    }, (error) => {
                        dispatch('notifications/error',
                            {
                                title: 'Update mailEvent',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        register({dispatch,}, {mailEvent}) {
            return new Promise(function (resolve, reject) {
                //Check if mailEvent is not undefined
                if (typeof mailEvent !== "object")
                    reject("Invalid mailEvent");
                api.sendRequest('mailevents/add', null,{...mailEvent}).then(
                    (ans) => {
                        //In case of success send notification
                        dispatch('notifications/success', {
                            title: 'Register mailEvent',
                            message: 'MailEvent created successfully with id: ' + ans.id
                        }, {root: true});

                        resolve(ans["id"])
                    }, (error) => {
                        //send error
                        dispatch('notifications/error',
                            {
                                title: 'Register mailEvent',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        send_Mail({dispatch,}, {mailEvent}) {
            //Check if client is not undefined
            if (typeof mailEvent != "object")
                mailEvent = {};

            if (Array.isArray(mailEvent.clientList))
                mailEvent.clientList = mailEvent.clientList.toString().replace('[', '').replace(']', '')

            return new Promise(function (resolve, reject) {
                //Check if client is not undefined

                api.sendRequest('/mailevents/sendPromotion',null,{...mailEvent}).then(
                    () => {
                        //In case of success send notification
                        dispatch('notifications/success',
                            {title: 'Email', message: 'Mail sent successfully'},
                            {root: true});


                        //send error
                        resolve()
                    }, (error) => {
                        dispatch('notifications/error',
                            {
                                title: 'Register client',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
    }
};
