import api from "../../services/api.service"


export const affiliateModule = {
    namespaced: true,
    state: {
        affiliates: [],
        data: { },
        status: {
            loaded: false,
            error:''
        },


    },
    getters:{
        isLoaded(state){
            return state.status != null && state.status.loaded === true;
        },
        getError(state){
            return state.status != null && state.status.error
        },
        allAffiliates(state){
            if(state.affiliates == null)
                return [];
            
            return state.affiliates;
        },

    },
    mutations: {
        updateData(state, data) {
            console.log("updateData");
            let d = {};
            state.data = d;
        }
    },
    actions: {
        loadData({state, dispatch}) {
            return new Promise(function (resolve, reject) {
                dispatch('getData').then(() => {
                    dispatch('refresh', {page: 0, amountPerPage: 20}).then(r => resolve(r)).catch(e => reject(e))
                }).catch((e) => reject(e));
            });
        },
        getData({state, commit,dispatch}) {
            state.status.loaded = false;
            return new Promise(function (resolve, reject) {
                api.sendRequest('superuser/affiliate', {action: "data",}).then(
                    (ans) => {

                        commit('updateData', ans);
                        resolve(state);
                    }, (error) => {
                        dispatch('notifications/error',
                            {
                                title: 'Getting data',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        refresh({dispatch, state}, {filter}) {
            state.status.loaded = false;
            if (typeof filter !== "object")
                filter = {};
            return new Promise(function (resolve, reject) {
                api.sendRequest('superuser/affiliate',
                    {
                        action: "get",
                        ...filter
                    }).then(
                    (ans) => {

                        if (ans["Affiliate"] == null)
                            reject('Affiliates failed to refresh');

                        state.status.loaded = true;
                        state.affiliates = ans["Affiliate"];
                        resolve(state);
                    }, (error) => {
                        state.status.error = error.message;

                        reject(error.message)
                    })
            })
        },
        //Get a businessUnit
        get({dispatch}, {id}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('superuser/affiliate', {id: id, action: "id"}).then(
                    (ans) => {

                        resolve(ans.Affiliate)
                    }, (error) => {

                        reject(error.message)
                    })
            })
        },
        update({dispatch, state}, {id, businessunit}) {
            return new Promise(function (resolve, reject) {
                //Check if businessUnit is not undefined
                if (typeof businessunit !== "object")
                    reject("Invalid businessUnit")
                //generate a new object in order to not update the businessUnit object and add the field action
                let request = {action: "update", ...businessunit};
                //In order to prevent a bug or a mistake, the id must be send
                request['id'] = id;

                api.sendRequest('superuser/affiliate', request).then(
                    (ans) => {
                        //In case of success send notification
                        dispatch('notifications/success',
                            {title: 'Update Affiliate', message: 'Affiliate updated successfully'},
                            {root: true});

                        //send error
                        resolve(ans)
                    }, (error) => {
                        dispatch('notifications/error',
                            {
                                title: 'Update Affiliate',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        register({dispatch, state}, {businessunit}) {
            return new Promise(function (resolve, reject) {
                //Check if businessUnit is not undefined
                if (typeof businessunit !== "object")
                    reject("Invalid businessUnit")
                api.sendRequest('superuser/affiliate', {action: "add", ...businessunit}).then(
                    (ans) => {
                        //In case of success send notification
                        dispatch('notifications/success',
                            {title: 'Register business unit', message: 'Business unit created successfully with id: ' + ans.BusinessUnitId},
                            {root: true});

                        //send error
                        resolve(ans)
                    }, (error) => {
                        dispatch('notifications/error',
                            {
                                title: 'Register business unit',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        }
    }
};
