import api from "../../../services/api.service"


export const ksvModule = {
    namespaced: true,
    state: {
        data: {types:[],categories:[]},
    },
    getters: {
        canReadRealEstate(state, getters, rootState, rootGetters) {
            return rootGetters['user/canRead']('real_estate');
        },
        canEditRealEstate(state, getters, rootState, rootGetters) {
            return rootGetters['user/canEdit']('real_estate');
        },
        getAllCategories(state) {
            if (state.data == null || state.data.categories == null)
                return [];
            return state.data.categories;
        },

        getAllTypes(state) {
            if (state.data == null || state.data.types == null)
                return [];
            return state.data.types;
        },
        allStatusNoActive() {
            return [
                {value: "NEW", text: 'NEW'},
                {value: 'EXPOSE', text: 'EXPOSE'},
                {value: 'DISABLED', text: 'DISABLED'}
               ]
        },
        allKsvStatus() {
            return [
                {value: "APPLICATIONCREATED", text: 'APPLIED-WAITING FOR DATA'},
                {value: 'DONE', text: 'DATA SUCCESSFULLY FETCHED'}

               ]
        },


    },
    mutations: {
        updateData(state, data) {
            let stateData = {};
            stateData.loaded = true;
            stateData.categories = data["Categories"];
            stateData.types = data["Types"];
            state.data = stateData;
        }
    },
    actions: {
        getData({state, commit, dispatch}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/data').then(
                    (ans) => {

                        commit('updateData', ans);
                        resolve(state);
                    }, (error) => {
                        dispatch('notifications/error',
                            {
                                title: 'Getting data',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        copySuccess(actions,id){
            actions.dispatch('notifications/success',
                {title: 'Success', message: 'Ksv  ID '+id+' copied'},
                {root: true});

        },


        getAllKsvs(actions,{ page, amount, filter,sortcolumn, sortby }) {

            return new Promise(function (resolve, reject) {
                actions.dispatch('getData', false).finally(() => {
                    api.sendRequest('ksv/get', {
                        buids: actions.rootGetters['data/getSelectedBranchesIds'].join(","),
                        page,
                        amount,
                        sortcolumn,
                        sortby
                    },filter).then(
                        (ans) => {
                            if (ans["Ksvs"] == null)
                                return reject('Could not get Ksvs ');

                            resolve(ans);
                        }, (error) => {
                            reject(error.message)
                        })
                })

            })
        },
        createOrFetchKsv(action, id) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('ksv/fetchOrCreate/'+id).then(
                    (ans) => {
                        if (ans["Ksv"] == null)
                            return reject('Could not generate ksv request ');
                        action.dispatch('notifications/success',
                            {title: {text:'Ksv'},
                                message:{text:'%s applied successfully',arguments:['Ksv']} },
                            {root: true});

                        resolve(ans);
                    }, (error) => {
                        action.dispatch('notifications/error',
                            {
                                title: {text:'Ksv %s',arguments:['application error']},
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },


        getKsvById(actions,id){
            return new Promise((resolve, reject) => {

                api.sendRequest('ksv/id/'+id, {}).then(
                    (ans) => {

                        if (ans["Ksv"] == null)
                            return reject('Could not get ksv ');

                        resolve(ans["Ksv"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },

        updateKsv(actions,ksv){
            return new Promise((resolve, reject) => {

                api.sendRequest('ksv/update',null, ksv).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Update ksv', message: 'Ksv updated successfully'},
                            {root: true});

                        if (ans["KsvId"] == null)
                            return reject('Could not update ksv ');

                        resolve(ans["KsvId"]);
                    }, (error) => {
                        actions.dispatch('notifications/error',
                            {
                                title: {text:'Ksv %s',arguments:['application error']},
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        addKsv(actions,ksv){
            return new Promise((resolve, reject) => {
                api.sendRequest('ksv/add', null,ksv).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Add ksv', message: 'Ksv created successfully'},
                            {root: true});

                        if (ans["KsvId"] == null)
                            return reject('Could not create ksv ');

                        console.log("ksv answer created: ",ans)
                        resolve(ans["KsvId"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        }
    },
};
