//https://github.com/surmon-china/vue-quill-editor
//https://codepen.io/helloCaptMomo/pen/WNdGOJB
//https://github.com/quilljs/quill/issues/1451

import Vue from 'vue'
import Quill from 'quill'
import VueQuillEditor from 'vue-quill-editor'

import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme

const setUpQuill = function SetupQuill() {
    let AlignStyle = Quill.import("attributors/style/align");
    Quill.register(AlignStyle, true);
    let BackgroundStyle = Quill.import("attributors/style/background");
    Quill.register(BackgroundStyle, true);
    let ColorStyle = Quill.import("attributors/style/color");
    Quill.register(ColorStyle, true);
    let DirectionStyle = Quill.import("attributors/style/direction");
    Quill.register(DirectionStyle, true);
    let FontStyle = Quill.import("attributors/style/font");
    Quill.register(FontStyle, true);
    FontStyle.whitlelist = ['sans serif','serif', 'monospace'];
    let SizeStyle = Quill.import("attributors/style/size");
    SizeStyle.whitelist = ["12px", "16px", "18px", "32px"];
    Quill.register(SizeStyle, true);
    customHeader();
    setUpIndent();
    const toolbarOptions = [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        ["blockquote", "code-block"],

        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }], // superscript/subscript
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ direction: "rtl" }], // text direction

        [{ size: ["14px", "16px", "18px"] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],

        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ font: [] }],
        [{ align: [] }],

        ["clean"], // remove formatting button
        ["link", "image"]
    ];
    setDefaultMargins();
    //setDefaultMarginsForH();
    console.log(Quill.imports);
};
const setUpIndent = function setUpIndentStyle() {
    const Parchment = Quill.import("parchment");
    class IndentAttributor extends Parchment.Attributor.Style {
        add(node, value) {
            if (value === 0) {
                this.remove(node);
                return true;
            } else {
                return super.add(node, `${value}em`);
            }
        }
    }

    let IndentStyle = new IndentAttributor("indent", "text-indent", {
        scope: Parchment.Scope.BLOCK,
        whitelist: ["1em", "2em", "3em", "4em", "5em", "6em", "7em", "8em", "9em"]
    });
    Quill.register(IndentStyle, true);
};
const setDefaultMargins = () => {
    const Block = Quill.import("blots/block");
    class Pblock extends Block {
        static create(value) {
            let node = super.create();
            node.setAttribute(
                "style",
                "margin-top:0px;margin-right:0px;margin-bottom:0px;margin-left:0px;line-height:107%;"
            );
            return node;
        }
    }
    Quill.register(Pblock, true);
};
const customHeader = () => {
    const Block = Quill.import("formats/header");
    class Hblock extends Block {
        static create(value) {
            let node = super.create(value);
            node.setAttribute(
                "style",
                "margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:107%;"
            );
            return node;
        }
        static formats(node) {
            return this.tagName.indexOf(node.tagName) + 1;
        }
    }
    Hblock.blotName = 'header';
    Hblock.tagName = ['H1', 'H2', 'H3', 'H4', 'H5', 'H6'];
    Quill.register(Hblock, true);
};
setUpQuill();

Vue.use(VueQuillEditor, /* { default global options } */)
