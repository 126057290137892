import api from "../../../services/api.service"


export const categoryModule = {
    namespaced: true,
    state: {
        data: {},
    },
    getters: {
        canReadCategory(state, getters, rootState, rootGetters) {
            return rootGetters['user/canRead']('category');
        },
        canEditCategory(state, getters, rootState, rootGetters) {
            return rootGetters['user/canEdit']('category');
        },
        getAllCategories(state) {
            if (state.data == null || state.data.categories == null)
                return [];
            return state.data.categories;
        },

    },
    mutations: {
        updateData(state, data) {
            console.log("categoryModule ->updateData", data)
            let stateData = {};
            stateData.loaded = true;
            stateData.categories = data["Categories"];
            state.data = stateData;
        }
    },
    actions: {
        loadData({state, dispatch}) {
            return new Promise(function (resolve, reject) {
                if (state.data.loaded === true)
                    return resolve();

                if (Object.keys(state.data).length === 0 && state.data.constructor === Object) {
                    dispatch('getData').then(() => resolve(), (e) => reject(e));
                } else
                    return resolve();
            });
        },
        getData({state, commit, dispatch}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('category/data').then(
                    (ans) => {

                        commit('updateData', ans);
                        resolve(state);
                    }, (error) => {
                        dispatch('notifications/error',
                            {
                                title: 'Getting data',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        getAllCategories(actions,filter) {

            return new Promise(function (resolve, reject) {
                actions.dispatch('getData', false).finally(() => {
                    api.sendRequest('category/get', {

                    },filter).then(
                        (ans) => {
                            if (ans["Categories"] == null)
                                return reject('Could not get categories ');

                            resolve(ans["Categories"]);
                        }, (error) => {

                            reject(error.message)
                        })
                })

            })
        },

        getCategoryById(actions,id){
            return new Promise((resolve, reject) => {

                api.sendRequest('category/id/'+id, {}).then(
                    (ans) => {

                        if (ans["Category"] == null)
                            return reject('Could not get category ');

                        resolve(ans["Category"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },

         updateCategory(actions,category){
            return new Promise((resolve, reject) => {

                api.sendRequest('category/update',null, category).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Update category', message: 'Category updated successfully'},
                            {root: true});

                        if (ans["CategoryId"] == null)
                            return reject('Could not update category ');

                        resolve(ans["CategoryId"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        addCategory(actions,{category,file}){
            return new Promise((resolve, reject) => {

                api.uploadFile('category', file,"add",{name:category.name, desc: category.description}).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Add category', message: 'Category created successfully'},
                            {root: true});

                        if (ans["CategoryId"] == null)
                            return reject('Could not create category ');

                        resolve(ans["CategoryId"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },

    },
};
