import api from "../../../services/api.service"


export const voucherModule = {
    namespaced: true,
    state: {
        data: {types: [], categories: []},
    },
    getters: {
        canReadProduct(state, getters, rootState, rootGetters) {
            return rootGetters['user/canRead']('product');
        },
        canEditProduct(state, getters, rootState, rootGetters) {
            return rootGetters['user/canEdit']('product');
        },
        getAllCategories(state) {
            if (state.data == null || state.data.categories == null)
                return [];
            return state.data.categories;
        },

        getAllTypes(state) {
            if (state.data == null || state.data.types == null)
                return [];
            return state.data.types;
        },

    },
    mutations: {
        updateData(state, data) {
            let stateData = {};
            stateData.loaded = true;
            stateData.categories = data["Categories"];
            stateData.types = data["Types"];
            state.data = stateData;
        }
    },
    actions: {
        getData({state, commit, dispatch}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('product/data').then(
                    (ans) => {

                        commit('updateData', ans);
                        resolve(state);
                    }, (error) => {
                        dispatch('notifications/error',
                            {
                                title: 'Getting data',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },

        getAllVoucher(actions, {page, amount, filter, sortcolumn, sortby}) {

            return new Promise(function (resolve, reject) {
                actions.dispatch('getData', false).finally(() => {
                    api.sendRequest('voucher/get', {
                        buids: actions.rootGetters['data/getSelectedBranchesIds'].join(","),
                        page, amount, sortcolumn, sortby
                    }, filter).then(
                        (ans) => {
                            if (ans["Vouchers"] == null)
                                return reject('Could not get Vouchers ');

                            resolve(ans);
                        }, (error) => {

                            reject(error.message)
                        })
                })

            })
        },


        getVoucherById(actions, id) {
            return new Promise((resolve, reject) => {

                api.sendRequest('voucher/id/' + id, {}).then(
                    (ans) => {

                        if (ans["Product"] == null)
                            return reject('Could not get products ');

                        resolve(ans["Product"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        archiveProduct(actions, id) {
            return new Promise((resolve, reject) => {

                api.sendRequest('voucher/archive/' + id, {}).then(
                    (ans) => {

                        if (ans["product"] == null)
                            return reject('Could not archive product');

                        resolve(ans["product"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        activateProduct(actions, id) {
            return new Promise((resolve, reject) => {

                api.sendRequest('voucher/activate/' + id, {}).then(
                    (ans) => {

                        if (ans["product"] == null)
                            return reject('Could not activate product');

                        resolve(ans["product"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },


        addVoucher(actions, product) {
            return new Promise((resolve, reject) => {
                api.sendRequest('voucher/add', null, product).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Add voucher', message: 'voucher created successfully'},
                            {root: true});

                        if (ans["ProductId"] == null)
                            return reject('Could not create voucher ');

                        resolve(ans["ProductId"]);
                    }, (error) => {
                        actions.dispatch('notifications/error',
                            {

                                title: {text:'Register %s',arguments:['Product']},
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        updateVoucher(actions, product) {
            return new Promise((resolve, reject) => {

                api.sendRequest('voucher/update', null, product).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Update voucher', message: 'voucher updated successfully'},
                            {root: true});

                        if (ans["ProductId"] == null)
                            return reject('Could not update voucher ');

                        resolve(ans["ProductId"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },

    },
};
