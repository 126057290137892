import api from "../../../services/api.service"


export const deviceModule = {
    namespaced: true,
    state: {},
    getters: {
        canReadDevice(state, getters, rootState, rootGetters) {
            return rootGetters['user/canRead']('device');
        },
        canEditDevice(state, getters, rootState, rootGetters) {
            return rootGetters['user/canEdit']('device');
        },
        getAllDevices: (state) => {
            if (state.data.devices == null)
                return {};

            return state.data.devices;
        },
    },
    mutations: {
        updateData(state, data) {
            console.log("deviceModule ->updateData", data)
            let stateData = {};
            stateData.loaded = true;
            stateData.devices=data["devices"]
            state.data = stateData;
        }
    },
    actions: {
        getData(actions) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('devices/getData', {
                    buids: actions.rootGetters['data/getSelectedBranchesIds'].join(","),

                }
            ).then(
                    (ans) => {

                        actions.commit('updateData', ans);
                        resolve(actions.state);
                    }, (error) => {
                        actions.dispatch('notifications/error',
                            {
                                title: 'Getting data',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },

        getAllDevices(actions) {

            return new Promise(function (resolve, reject) {
                actions.dispatch('getData', false).finally(() => {
                    api.sendRequest('devices/getAll',
                        {
                            buids: actions.rootGetters['data/getSelectedBranchesIds'].join(","),

                        }).then(
                        (ans) => {
                            if (ans["devices"] == null)
                                return reject('Could not get devices ');

                            resolve(ans["devices"]);
                        }, (error) => {

                            reject(error.message)
                        })
                })

            })
        },

        getDeviceById(actions,id){
            return new Promise((resolve, reject) => {

                if(isNaN(id))
                    reject("Invalid id");

                api.sendRequest('devices/get/'+id, {}).then(
                    (ans) => {

                        if (ans["device"] == null)
                            return reject('Could not get devices ');

                        resolve(ans["device"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },

        updateDevice(actions,device){
            return new Promise((resolve, reject) => {


                api.sendRequest('devices/update',null, device).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Update device', message: 'Device updated successfully'},
                            {root: true});

                        if (ans["device"] == null)
                            return reject('Could not get device ');

                        resolve(ans["device"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        createDevice(actions,device){
            return new Promise((resolve, reject) => {


                api.sendRequest('devices/add',null, device).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Add device', message: 'Device added successfully'},
                            {root: true});

                        if (ans["device"] == null)
                            return reject('Could not get device ');

                        resolve(ans["device"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        }

    },
};
