import Vue from 'vue'
import numberFormatter from 'number-formatter'
import i18n from '../plugins/vue-i18n'

Vue.use(numberFormatter)
//https://www.npmjs.com/package/number-formatter
Vue.filter('numberFormatter', function (value, format) {
  if (value == null) {
    return ''
  }
  return numberFormatter(format || i18n.t('numberFormat'), value)
});

Vue.filter('toFixed', function (value, number) {
  if (value == null || isNaN(value)) {
    return value || ''
  }
  return parseFloat(value).toFixed(number || 2)
});
