import api from "../../../services/api.service"


export const buildingModule = {
    namespaced: true,
    state: {
        data: {types: [], categories: []},
    },
    getters: {
        fileTags(state, getters, rootState, rootGetters) {
            return [
                {value: "Kaufvertrag", title: "Kaufvertrag", icon: "fas fa-file-contract"},
                {value: "Finanzierung", title: "Finanzierung", icon: "fas fa-file-contract"},
                {value: "Bauakte", title: "Bauakte", icon: "fas fa-file-contract"},
                {value: "Hausverwaltung", title: "Hausverwaltung", icon: "fas fa-file-contract"},
                {value: "Versicherung", title: "Versicherung", icon: "fas fa-file-contract"},
                {value: "Fotos", title: "Fotos", icon: "fas fa-file-contract"},
                {value: "Marketing", title: "Marketing", icon: "fas fa-file-contract"},
                {value: "Gutachten ", title: "Gutachten ", icon: "fas fa-file-contract"},
                {value: "Kreditvertrag", title: "Kreditvertrag", icon: "fas fa-file-contract"},
                {value: "Bauvorhaben", title: "Bauvorhaben", icon: "fas fa-file-contract"},
                {value: "Expose", title: "Expose", icon: "fas fa-file-contract"},
                {value: "Sonstiges", title: "Sonstiges", icon: "fas fa-file-contract"},
            ]
        },
        allBuildingUsers(state, getters, rootState, rootGetters) {
            return rootGetters['user/canRead']('building_all_user');
        },
        canOperateBuilding(state, getters, rootState, rootGetters) {
            return rootGetters['user/canOperate']('building');
        },
        canReadRealEstate(state, getters, rootState, rootGetters) {
            return rootGetters['user/canRead']('real_estate');
        },
        canEditRealEstate(state, getters, rootState, rootGetters) {
            return rootGetters['user/canEdit']('real_estate');
        },
        canUploadExpose(state, getters, rootState, rootGetters) {
            return rootGetters['user/canEdit']('building_expose');
        },
        canCreateInvoice(state, getters, rootState, rootGetters) {
            return rootGetters['user/canCreate']('invoice');
        },
        canEditInvoice(state, getters, rootState, rootGetters) {
            return rootGetters['user/canCreate']('invoice');
        },
        getAllCategories(state) {
            if (state.data == null || state.data.categories == null)
                return [];
            return state.data.categories;
        },

        getAllTypes(state) {
            if (state.data == null || state.data.types == null)
                return [];
            return state.data.types;
        },
        allStatusNoActive() {
            return [
                {value: "NEW", text: 'NEW'},
                {value: 'EXPOSE', text: 'EXPOSE'},
                {value: 'DISABLED', text: 'DISABLED'}
            ]
        },
        allStatuse() {
            return [
                {value: "Neubau", text: 'Neubau'},
                {value: 'Altbau', text: 'Altbau'},
                {value: 'Villahaus', text: 'Villahaus'},
                {value: 'Altbau_Renoviert', text: 'Altbau-Renoviert'},
                {value: 'Tiny_Houses', text: 'Tiny-Houses'},

            ]
        },


    },
    mutations: {
        updateData(state, data) {
            let stateData = {};
            stateData.loaded = true;
            stateData.categories = data["Categories"];
            stateData.types = data["Types"];
            state.data = stateData;
        }
    },
    actions: {

        copySuccess(actions, id) {
            actions.dispatch('notifications/success',
                {title: 'Success', message: 'Building  ID ' + id + ' copied'},
                {root: true});

        },

        uploadExcel(actions, {file, params}) {
            return new Promise(function (resolve, reject) {
                api.uploadFile('api/excel', file, "uploadFileAndInsertData",
                    {table: "Building", params: btoa(JSON.stringify(params || {}))}).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Uploading file', message: 'File uploaded successfully'},
                            {root: true});

                        if (ans["TransformedData"] == null)
                            return reject('Could not add buildings ');
                        resolve();
                    }, (error) => {
                        actions.dispatch('notifications/error',
                            {
                                title: 'Uploading file',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            });
        },
        handleTodoEventBuilding(actions, {item, event, id}) {
            return new Promise((resolve, reject) => {

                api.sendRequest('building/todo/' + id + '/' + event, {}, item).then(
                    (ans) => {

                        if (ans["Building"] == null)
                            return reject('Could not handle todo event');
                        actions.dispatch('notifications/success',
                            {title: 'Update Todo list', message: 'Todo event handled successfully'},
                            {root: true});
                        resolve(ans["Building"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        uploadExcelClient(actions, {fileClient, params}) {
            return new Promise(function (resolve, reject) {
                api.uploadFile('api/excel', fileClient, "uploadFileAndInsertData",
                    {table: "BuildingClient", params: btoa(JSON.stringify(params || {}))}).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Uploading file', message: 'File uploaded successfully'},
                            {root: true});
                        resolve();
                    }, (error) => {
                        actions.dispatch('notifications/error',
                            {
                                title: 'Uploading file',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            });
        },
        getAllBuildings(actions, {page, amount, filter, sortcolumn, sortby}) {

            return new Promise(function (resolve, reject) {
                api.sendRequest('building/get', {
                    buids: actions.rootGetters['data/getSelectedBranchesIds'].join(","),
                    page,
                    amount,
                    sortcolumn,
                    sortby
                }, filter).then(
                    (ans) => {
                        if (ans["Buildings"] == null)
                            return reject('Could not get Buildings ');

                        resolve(ans);
                    }, (error) => {
                        reject(error.message)
                    })
            })

        },
        getAllBuildingsBasics(actions, {ids,properties}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('building/get/basic', {
                    buids: actions.rootGetters['data/getSelectedBranchesIds'].join(","),
                    ids: ids,
                    properties: properties //Boolean retrieve properties
                }).then(
                    (ans) => {
                        resolve(ans);
                    }, (error) => {
                        reject(error.message)
                    })
            })

        },
        updateBankDocumentFile(action, {id, bankId, file}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('building/update/file/' + id + '/' + bankId, null, file).then(
                    (ans) => {

                        action.dispatch('notifications/success',
                            {
                                title: {text: 'file %s', arguments: ['status']},
                                message: {text: '%s updated successfully', arguments: ['file']}
                            },
                            {root: true});
                        resolve(ans["editableFields"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        updateDocumentFile(action, {id, file}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('building/update/file/' + id, null, file).then(
                    (ans) => {

                        action.dispatch('notifications/success',
                            {
                                title: {text: 'file %s', arguments: ['status']},
                                message: {text: '%s updated successfully', arguments: ['file']}
                            },
                            {root: true});
                        resolve(ans["editableFields"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },

        fetchHostawayContracts(actions, id) {
            return new Promise((resolve, reject) => {

                api.sendRequest('building/fetchReservationHostaway/' + id, {}).then(
                    (ans) => {

                        if (ans["Building"] == null)
                            return reject('Could not fetch reservations from hostaway ');

                        resolve(ans["Building"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        setBankToBuilding(actions, {id, newBank}) {

            return new Promise((resolve, reject) => {

                api.sendRequest('building/addBank/' + id, null, newBank).then(
                    (ans) => {

                        if (ans["Building"] == null)
                            return reject('Could not add bank to building ');

                        actions.dispatch('notifications/success',
                            {title: 'Adding Bank', message: 'Bank added successfully'},
                            {root: true});
                        resolve(ans["Building"]);
                    }, (error) => {

                        actions.dispatch('notifications/error',
                            {
                                title: 'Adding Bank',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })


        },
        sendChoosenFilesToMail(actions, {buildingId, email, filesId, isBank}) {

            return new Promise(function (resolve, reject) {
                api.sendRequest('building/sendBuildingFiles/' + buildingId, {
                    isBank,
                    email,
                    fileIds: filesId

                }).then(
                    (ans) => {
                        if (ans["userids"] == null)
                            return reject('Could not send email ');

                        actions.dispatch('notifications/success',
                            {title: 'Mail Documents', message: 'Email sent successfully'},
                            {root: true});

                        resolve(ans["userids"]);
                    }, (error) => {

                        actions.dispatch('notifications/error',
                            {
                                title: 'Mail Documents',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })

        },
        requestExposeMail(actions, {buildingId, type}) {

            return new Promise(function (resolve, reject) {
                api.sendRequest('building/requestExpose/' + buildingId, {
                    type
                }).then(
                    (ans) => {
                        if (ans["buildingId"] == null)
                            return reject('Could not request expose ');

                        actions.dispatch('notifications/success',
                            {title: 'Document request', message: 'Document request sent successfully'},
                            {root: true});

                        resolve(ans["buildingId"]);
                    }, (error) => {

                        actions.dispatch('notifications/error',
                            {
                                title: 'Document request',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })

        },

        getBuildingById(actions, id) {
            return new Promise((resolve, reject) => {

                api.sendRequest('building/id/' + id, {}).then(
                    (ans) => {

                        if (ans["Building"] == null)
                            return reject('Could not get building ');

                        resolve(ans["Building"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },

        updateBuilding(actions, building) {
            return new Promise((resolve, reject) => {

                api.sendRequest('building/update', null, building).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Update building', message: 'Building updated successfully'},
                            {root: true});

                        if (ans["Building"] == null)
                            return reject('Could not update building ');

                        resolve(ans["Building"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        addBuilding(actions, building) {
            return new Promise((resolve, reject) => {
                api.sendRequest('building/add', null, building).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Add building', message: 'Building created successfully'},
                            {root: true});

                        if (ans["BuildingId"] == null)
                            return reject('Could not create building ');

                        console.log("building answer created: ", ans)
                        resolve(ans["BuildingId"]);
                    }, (error) => {
                        actions.dispatch('notifications/error',
                            {
                                title: 'Add building',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        getExcelTemplateUrl(actions) {
            return new Promise(function (resolve, reject) {
                let params = {
                    bearer: encodeURI(localStorage.Bearer)
                };
                let query = new URLSearchParams(params).toString()
                resolve(window.config.apiUrl + 'excel/download/template/building?' + query);
            });
        },
        getExcelTemplateUrlClients(actions) {
            return new Promise(function (resolve, reject) {
                let params = {
                    bearer: encodeURI(localStorage.Bearer)
                };
                let query = new URLSearchParams(params).toString()
                resolve(window.config.apiUrl + 'excel/download/template/buildingClient?' + query);
            });
        },
        sendUserInviteMail(actions, {userIds, buildingId}) {

            return new Promise(function (resolve, reject) {
                api.sendRequest('building/sendUserMail',
                    {userIds, buildingId}, null).then(
                    (ans) => {
                        if (ans["userIds"] == null)
                            return reject('Could not send email ');

                        actions.dispatch('notifications/success',
                            {title: 'Email new User', message: 'Email sent successfully'},
                            {root: true});

                        resolve(ans["userIds"]);
                    }, (error) => {

                        actions.dispatch('notifications/error',
                            {
                                title: 'Email new User',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })

        },
        sendBankAgentMail(actions, {userIds, buildingId}) {

            return new Promise(function (resolve, reject) {
                api.sendRequest('building/sendAgentMail',
                    {userIds, buildingId}, null).then(
                    (ans) => {
                        if (ans["userIds"] == null)
                            return reject('Could not send email ');

                        actions.dispatch('notifications/success',
                            {title: 'Email agents', message: 'Email sent successfully'},
                            {root: true});

                        resolve(ans["userIds"]);
                    }, (error) => {

                        actions.dispatch('notifications/error',
                            {
                                title: 'Email agents',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })

        },

        addExpenses(actions, {id, expenses}) {
            return new Promise((resolve, reject) => {
                api.sendRequest('building/addExpenses/' + id, {}, expenses).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Update Building', message: 'Building expenses added successfully'},
                            {root: true});
                        resolve(ans["Building"]);
                    }, (error) => {
                        actions.dispatch('notifications/error',
                            {
                                title: 'Adding expense',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        calculatePreviousMonth(actions, id) {
            return new Promise((resolve, reject) => {
                api.sendRequest('building/expenses-calculation/previous/' + id).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Update Building', message: 'Building expenses added successfully'},
                            {root: true});
                        resolve(ans["buildingExpenses"]);
                    }, (error) => {
                        actions.dispatch('notifications/error',
                            {
                                title: 'Adding expense',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        deleteExpenses(actions, {id, expenseId}) {
            return new Promise((resolve, reject) => {
                api.sendRequest('building/deleteExpenses/' + id + '/' + expenseId, {}, null).then(
                    (ans) => {

                        if (ans["Building"] == null)
                            return reject('Could not delete building expenses');
                        actions.dispatch('notifications/success',
                            {title: 'Update Building', message: 'Building expenses deleted successfully'},
                            {root: true});
                        resolve(ans["Building"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        retrieveInvoice(actions, id) {
            return new Promise((resolve, reject) => {
                api.sendRequest('building/invoice/retrieve/' + id, {}).then(
                    (ans) => {
                        resolve({invoice: ans["Invoice"], invoiceDetails: ans["InvoiceDetails"]});
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        createDraftInvoice(actions, invoice) {
            return new Promise((resolve, reject) => {
                api.sendRequest('building/invoice/draft/generate', {}, invoice).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Update Building', message: 'Building invoice created successfully'},
                            {root: true});
                        resolve({invoice: ans["Invoice"], invoiceDetails: ans["InvoiceDetails"]});
                    }, (error) => {
                        actions.dispatch('notifications/error',
                            {
                                title: 'Create invoice',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        updateDraftInvoice(actions, {id, invoice}) {
            return new Promise((resolve, reject) => {
                api.sendRequest('building/invoice/draft/update/' + id, {}, invoice).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Update Building', message: 'Building invoice updated successfully'},
                            {root: true});
                        resolve({invoice: ans["Invoice"], invoiceDetails: ans["InvoiceDetails"]});
                    }, (error) => {
                        actions.dispatch('notifications/error',
                            {
                                title: 'Update invoice',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        finalizeDraftInvoice(actions, invoiceId) {
            return new Promise((resolve, reject) => {
                api.sendRequest('building/invoice/draft/finalize/'+invoiceId, {}).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Update Building', message: 'Building invoice finalized successfully'},
                            {root: true});
                        resolve({invoice: ans["Invoice"], invoiceDetails: ans["InvoiceDetails"]});
                    }, (error) => {
                        actions.dispatch('notifications/error',
                            {
                                title: 'Finalize invoice',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        deleteInvoice(actions, id) {
            return new Promise((resolve, reject) => {
                api.sendRequest('building/invoice/delete/' + id, {}).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Update Building', message: 'Building invoice deleted successfully'},
                            {root: true});
                        resolve(ans["Building"]);
                    }, (error) => {
                        actions.dispatch('notifications/error',
                            {
                                title: 'Delete invoice',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        }
    },
};
