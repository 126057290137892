import api from "../../services/api.service"

export const calendarModule = {
    namespaced: true,
    state: {
        calendars: [], // my calendars
        all_calendars: [], // Without user filters
        data: {type:{}},
        status: {
            loaded: false,
            error: ''
        },


    },
    getters: {
        allCalendars(state) {
            if (!Array.isArray(state.all_calendars))
                return [];

            return state.all_calendars;
        },

        getTypes(state) {
            if (state.data.type == null)
                return {};

            return state.data.type
        },
        getRentTypes(state) {
            if (state.data.typeRent == null)
                return {};

            return state.data.typeRent
        },
        getType:(state)=>(typeId)=> {
            if (state.data.type == null || state.data.type[typeId] == null)
                return {};

            return state.data.type[typeId]
        },
        getTypeName:(state)=>(typeId)=> {
            if (state.data.type == null || state.data.type[typeId] == null)
                return "";
            return state.data.type[typeId].name;
        },
        getTypeColor: (state) => (id) => {
            if (state.data.type == null || id == null || state.data.type[id] == null)
                return "#1867c0";

            return state.data.type[id].color;
        },
        canDismissOtherCalendar(state, getters, rootState, rootGetters) {
            return rootGetters['user/canOperate']('allcalendar');
        },
        canReadAllCalendar(state, getters, rootState, rootGetters) {
            return rootGetters['user/canRead']('allcalendar');
        },
    },
    mutations: {
        updateData(state, exData) {


            console.log("calendar.module -> updateData()")
            let data = {};

            if (Array.isArray(exData["CalendarTypes"])) {
                console.log("updateData() is CalendarTypes",exData["CalendarTypes"]);
                data.type = exData["CalendarTypes"].reduce((obj, item) => Object.assign(obj, {[item.id]: item}), {});
            }



            if (Array.isArray(exData["CalendarTypes"])) {
                data.typeRent = exData["CalendarTypes"].filter(obj=>obj.id===6).reduce((obj, item) => Object.assign(obj, {[item.id]: item}), {});
            }

            Object.assign(state.data,data);
        },
    },
    actions: {
        loadData({state, dispatch}) {

            return new Promise(function (resolve) {
                if (Object.keys(state.data).length === 0 && state.data.constructor === Object) {
                    dispatch('getData').then(() => {
                        resolve();
                    }).catch((e) => {
                        console.error(e);
                        resolve()
                    });
                } else
                    resolve();
            });
        },
        checkData({state, dispatch},) {

            return new Promise(function (resolve, reject) {
                if (state.data != null && state.data.type != null && Object.keys(state.data.type).length > 0)
                    return resolve();

                dispatch('getData').finally(() => {
                    return resolve();
                })
            });
        },
        loadDataFilter({state, dispatch}, {userid}) {


            if (Object.keys(state.data).length === 0 && state.data.constructor === Object)
                return new Promise(function (resolve, reject) {
                    dispatch('getData').then(() => {
                        dispatch('refreshFilter', {
                            userid: userid,
                            page: 0,
                            amountPerPage: 20
                        }).then(r => resolve(r)).catch(e => reject(e))
                    }).catch((e) => reject(e));
                });
            return dispatch('refreshFilter', {userid: userid, page: 0, amountPerPage: 20});
        },
        getData({commit, state, dispatch}) {
            state.status.loaded = false;
            return new Promise(function (resolve, reject) {
                api.sendRequest('calendar/data').then(
                    (ans) => {

                        commit('updateData', ans);

                        resolve(ans);
                    }, (error) => {
                        dispatch('notifications/error',
                            {
                                title: 'Getting data',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        getCalendars(actions, filter) {

            if (filter == null || typeof filter != "object")
                filter = {};
            return new Promise(function (resolve, reject) {
                actions.dispatch('loadData').then(() => {

                    api.sendRequest('calendar/get', null,filter).then(
                        (ans) => {

                            if (ans["Calendars"] == null)
                                reject('Calendars units failed to refresh');

                            resolve(ans["Calendars"]);
                        }, (error) => {

                            reject(error.message)
                        })
                })
            });
        },
        getTreasureCalendars(actions, {filter, prices}) {

            if (filter == null || typeof filter != "object")
                filter = {};
            return new Promise(function (resolve, reject) {
                actions.dispatch('loadData').then(() => {
                    api.sendRequest('calendar/getRent', {prices},filter).then(
                        (ans) => {
                            if (ans["Calendars"] == null)
                                reject('Calendars units failed to refresh');
                            resolve(ans["Calendars"]);
                        }, (error) => {
                            reject(error.message)
                        })
                })
            });
        },
        refreshFilter({dispatch, state}, {userid, refreshFilter}) {
            state.status.loaded = false;

            return new Promise(function (resolve, reject) {
                api.sendRequest('calendar/get',null,
                    {
                        userid: userid,
                    }).then(
                    (ans) => {

                        if (ans["Calendars"] == null)
                            reject('Calendars units failed to refresh');

                        state.status.loaded = true;
                        state.all_calendars = ans["Calendars"];

                        resolve(state);
                    }, (error) => {
                        state.status.error = error.message

                        reject(error.message)
                    })
            })
        },
        //Get a calendar
        get({dispatch}, {id}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('calendar/id/'+id).then(
                    (ans) => {

                        resolve(ans.Calendar)
                    }, (error) => {

                        reject(error.message)
                    })
            })
        },
        update({dispatch, state}, {id, event}) {
            return new Promise(function (resolve, reject) {
                //Check if calendar is not undefined
                if (typeof event !== "object")
                    reject("Invalid calendar")
                //generate a new object in order to not update the calendar object and add the field action
                let body = {...event};
                //In order to prevent a bug or a mistake, the id must be send
                body['id'] = id;
                api.sendRequest('calendar/update',null, body).then(
                    (ans) => {
                        //In case of success send notification
                        dispatch('notifications/success',
                            {title: 'Update calendar', message: 'calendar updated successfully'},
                            {root: true});

                        //update table (in order to see the changes)

                        //send error
                        resolve(ans)
                    }, (error) => {
                        dispatch('notifications/error',
                            {
                                title: 'Update calendar',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        register({dispatch}, {event}) {
            return new Promise(function (resolve, reject) {
                //Check if calendar is not undefined
                if (typeof event !== "object")
                    reject("Invalid calendar");
                //Copy in order to not change the original object
                let body = {...event};

                console.log("Calendar.register(): ", body);
                api.sendRequest('calendar/add',null, body).then(
                    (ans) => {
                        //In case of success send notification
                        dispatch('notifications/success',
                            {
                                title: 'Register in calendar',
                                message: 'Event created successfully with id: ' + ans.CalendarId
                            },
                            {root: true});

                        //send error
                        resolve(ans['CalendarId'])
                    }, (error) => {
                        dispatch('notifications/error',
                            {
                                title: 'Register in calendar',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        dismiss({dispatch}, {eventId}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('calendar/dismiss', {id: eventId}).then(
                    (ans) => {
                        /*In case of success send notification*/
                        dispatch('notifications/success', {
                            title: 'Dismiss event',
                            message: 'Event dismissed successfully'
                        }, {root: true});

                        //send error
                        resolve(ans)
                    }, (error) => {
                        dispatch('notifications/error', {
                            title: 'Dismiss event',
                            message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                        }, {root: true});
                        reject(error.message)
                    })
            })
        },
        getExternalCalendars(actions, {month, year}) {
            return new Promise(function (resolve) {
                //https://www.hebcal.com/home/195/jewish-calendar-rest-api
                api.sendExternalRequest('https://www.hebcal.com/hebcal/', 'GET', {
                    v: '1', // version. Required.
                    cfg: 'json', // output JSON instead of HTML. Also variant cfg=fc for fullcalendar.io integration
                    maj: 'on', // Major holidays
                    min: 'on', // Minor holidays (Tu BiShvat, Lag B’Omer, …)
                    mod: 'on', // Modern holidays (Yom HaShoah, Yom HaAtzma’ut, …)
                    nx: 'on', // Rosh Chodesh
                    year: (year == null || isNaN(year) ? 'now' : year), //“now” for current year, or 4-digit YYYY such as 2003
                    month: (month == null || isNaN(month) ? 'x' : month), //“x” for entire Gregorian year, or use a numeric month (1=January, 6=June, etc.)
                    ss: 'on', // Special Shabbatot (Shabbat Shekalim, Zachor, …)
                    mf: 'on', // Minor fasts (Ta’anit Esther, Tzom Gedaliah, …)
                    i: 'on' // Israel holidays and Torah readings
                }).then(function (ans) {

                    console.log("getExternalCalendars()", ans)
                    resolve(ans.data.items);

                    // let items = ans.data.items;
                    // resolve(items.reduce((obj, item) => Object.assign(obj, {[item.date]: item}), {}));

                }).catch(function (ans) {
                    console.error("getExternalCalendars() reject", ans)
                    resolve({});
                })
            });
        },

        registerCalendarType({dispatch}, calendarType) {
            return new Promise(function (resolve, reject) {
                //Check if calendar is not undefined
                if (typeof calendarType !== "object")
                    return reject("Invalid calendar status");
                //Reset creationTime and modified field in order to prevent a bug
                api.sendRequest('calendar/addCalendarType', null,calendarType).then(
                    (ans) => {
                        dispatch('getData');
                        //In case of success send notification
                        dispatch('notifications/success',
                            {
                                title: 'Register Calendar status',
                                message: 'Calendar type created successfully with id: ' + ans.calendarTypeId
                            },
                            {root: true});

                        resolve(ans)
                    }, (error) => {
                        dispatch('notifications/error',
                            {
                                title: 'Register Calendar status',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })

            })
        },
        updateCalendarType({dispatch}, {id,calendarType}) {
            return new Promise(function (resolve, reject) {
                //Check if calendar is not undefined
                if (typeof calendarType !== "object")
                    return reject("Invalid calendar status");
                if (isNaN(id))
                    return reject("Invalid calendar status id");
                //Reset creationTime and modified field in order to prevent a bug
                api.sendRequest('calendar/updateCalendarType', null,{...calendarType,id}).then(
                    (ans) => {
                        dispatch('getData');
                        //In case of success send notification
                        dispatch('notifications/success',
                            {
                                title: 'Register Calendar status',
                                message: 'Calendar type updated successfully'
                            },
                            {root: true});

                        resolve(ans)
                    }, (error) => {
                        dispatch('notifications/error',
                            {
                                title: 'Register Calendar status',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })

            })
        },
        //Get a client appointments
        clientAppointments(actions, {clientId}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('calendar/appointments/'+clientId).then(
                  (ans) => {

                      resolve(ans.Appointments)
                  }, (error) => {

                      reject(error.message)
                  })
            })
        },
    }
};
