import api from "../../../services/api.service"


export const couponCampaignsModule = {
    namespaced: true,
    state: {
        data: {types:[],categories:[]},
    },
    getters: {
        canReadCouponCampaigns(state, getters, rootState, rootGetters) {
            return rootGetters['user/canRead']('couponCampaigns');
        },
        canEditCouponCampaigns(state, getters, rootState, rootGetters) {
            return rootGetters['user/canEdit']('couponCampaigns');
        },
        getAllCategories(state) {
            if (state.data == null || state.data.categories == null)
                return [];
            return state.data.categories;
        },

    },
    mutations: {

    },
    actions: {

        getAllCouponCampaigns(actions) {

            return new Promise(function (resolve, reject) {
                    api.sendRequest('couponCampaigns/getAll', {
                        buids: actions.rootGetters['data/getSelectedBranchesIds'].join(","),

                    }).then(
                        (ans) => {
                            if (ans["CouponCampaigns"] == null)
                                return reject('Could not get couponCampaigns ');

                            resolve(ans["CouponCampaigns"]);
                        }, (error) => {

                            reject(error.message)
                        })
                })


        },

        getCouponCampaignById(actions,id){
            return new Promise((resolve, reject) => {

                api.sendRequest('couponCampaigns/get/'+id, {}).then(
                    (ans) => {

                        if (ans["CouponCampaign"] == null)
                            return reject('Could not get couponCampaignss ');

                        resolve(ans["CouponCampaign"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },

         updateCouponCampaign(actions,couponCampaigns){
            return new Promise((resolve, reject) => {

                api.sendRequest('couponCampaigns/update',null, couponCampaigns).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Update couponCampaign', message: 'CouponCampaigns updated successfully'},
                            {root: true});

                        if (ans["CouponCampaign"] == null)
                            return reject('Could not update couponCampaigns ');

                        resolve(ans["CouponCampaign"]);
                    }, (error) => {
                        actions.dispatch('notifications/error',
                            {title: 'Update couponCampaigns', message: error.message},
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        addCouponCampaigns(actions,couponCampaigns){
            return new Promise((resolve, reject) => {

                api.sendRequest('couponCampaigns/create', null,couponCampaigns).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Add couponCampaigns', message: 'CouponCampaigns created successfully'},
                            {root: true});

                        if (ans["CouponCampaignId"] == null)
                            return reject('Could not create couponCampaigns ');

                        resolve(ans["CouponCampaignId"]);
                    }, (error) => {
                        actions.dispatch('notifications/error',
                            {title: 'Add couponCampaigns', message: error.message},
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        addCouponProduct(actions,{couponCampaigns,cid}){
            return new Promise((resolve, reject) => {

                api.sendRequest('couponCampaigns/addCampaignProducts/'+cid, null,couponCampaigns).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Add coupon', message: 'Coupon successfully added'},
                            {root: true});

                        if (ans["CouponCampaign"] == null)
                            return reject('Could not add coupon');

                        resolve(ans["CouponCampaign"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        removeCouponProduct(actions,{campaignId,couponId}){
            return new Promise((resolve, reject) => {

                api.sendRequest('couponCampaigns/deleteCampaignProducts/'+campaignId+'/'+couponId, {}).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Removing coupons', message: 'Coupon successfully deleted'},
                            {root: true});

                        if (ans["CouponCampaign"] == null)
                            return reject('Could not delete coupon ');

                        resolve(ans["CouponCampaign"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        createCoupon(actions,{couponCampaigns,cid}){
            return new Promise((resolve, reject) => {

                api.sendRequest('couponCampaigns/addCampaignVoucher/'+cid, null,couponCampaigns).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Add coupon', message: 'Coupon successfully added'},
                            {root: true});

                        if (ans["CouponCampaign"] == null)
                            return reject('Could not add coupon');

                        resolve(ans["CouponCampaign"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        removeCouponApi(actions,{campaignId,couponId}){
            return new Promise((resolve, reject) => {

                api.sendRequest('couponCampaigns/deleteCampaignProducts/'+campaignId+'/'+couponId, {}).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Removing coupons', message: 'Coupon successfully deleted'},
                            {root: true});

                        if (ans["CouponCampaign"] == null)
                            return reject('Could not delete coupon ');

                        resolve(ans["CouponCampaign"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        }
    },
};
