import api from "@/services/api.service";

export const realEstatePriceLabsModule = {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        getAllPriceLabs(actions, request) {
            request = (request || {});
            request.buIds = actions.rootGetters['data/getSelectedBranchesIds'];
            return new Promise((resolve, reject) => {
                api.sendRequest('realEstate/priceLabs/all', null, request).then(
                    (ans) => {
                        resolve(ans);
                    }, (error) => {
                        actions.dispatch('notifications/error',
                            {
                                title: {text: 'Getting data', arguments: ['failed']},
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        updatePriceLabs(actions, priceLab) {
            return new Promise((resolve, reject) => {
                api.sendRequest('realEstate/priceLabs/update', null, priceLab).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Update PriceLabs', message: 'PriceLabs updated successfully'},
                            {root: true});

                        resolve(ans["PriceLabs"]);
                    }, (error) => {

                        actions.dispatch('notifications/error',
                            {
                                title: {text: 'Update %s', arguments: ['failed']},
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});

                        reject(error.message)
                    })
            });
        },
        fetchAll(actions) {
            return new Promise((resolve, reject) => {
                api.sendRequest('realEstate/priceLabs/refresh', null, null).then(
                    (ans) => {
                        resolve(ans);
                    }, (error) => {
                        actions.dispatch('notifications/error',
                            {
                                title: {text: 'Getting data', arguments: ['failed']},
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        }
    }
}
