import api from "../../../services/api.service"


export const refinanceModule = {
    namespaced: true,
    state: {
        data: {types: [], categories: []},
    },
    getters: {
        isShowLessForBanker(state, getters, rootState, rootGetters) {
            return rootGetters['user/canRead']('bankerRefinanceExlusive');
        },
        canReadRealEstate(state, getters, rootState, rootGetters) {
            return rootGetters['user/canRead']('real_estate');
        },
        canEditRealEstate(state, getters, rootState, rootGetters) {
            return rootGetters['user/canEdit']('real_estate');
        },
        getAllCategories(state) {
            if (state.data == null || state.data.categories == null)
                return [];
            return state.data.categories;
        },

        getAllTypes(state) {
            if (state.data == null || state.data.types == null)
                return [];
            return state.data.types;
        },
        allStatusNoActive() {
            return [
                {value: "NEW", text: 'NEW'},
                {value: 'EXPOSE', text: 'EXPOSE'},
                {value: 'DISABLED', text: 'DISABLED'}
            ]
        },
        allStatus() {
            return [
                {value: "NEW", text: 'NEW'},
                {value: 'IN_PROGRESS', text: 'inprogress'},
                {value: 'DECLINED', text: 'DECLINED'},
                {value: 'APPROVED', text: 'APPROVED'},
                {value: 'CLOSED', text: 'CLOSED'},
            ]
        },

        allTypes() {
            return [
                {value: "Marketing", text: 'Marketing'},
                {value: 'Reisebüros & Firmen Akquise', text: 'Reisebüros & Firmen Akquise'},
                {value: 'Management', text: 'Management'},
                {value: 'Buchhaltung & Rechnungen', text: 'Buchhaltung & Rechnungen'}
            ]
        },
        fileTags(state, getters, rootState, rootGetters) {
            return [
                {value: "unknown", title: "unknown", icon: "fas fa-question"},
                {value: "contract", title: "contract", icon: "fas fa-file-contract"},
                {value: "signedContract", title: "signed contract", icon: "fas fa-file-signature"},
                {value: "clientDocument", title: "client document Id", icon: "fas fa-passport"},
                {value: "clientIncomes", title: "client incomes", icon: "fas fa-university"},
                {value: "clientDocument", title: "client document", icon: "fas fa-id-card"},
                {value: "document", title: "document", icon: "fas fa-file-alt"}
            ]
        },

    },
    mutations: {
        updateData(state, data) {
            let stateData = {};
            stateData.loaded = true;
            stateData.categories = data["Categories"];
            stateData.types = data["Types"];
            state.data = stateData;
        }
    },
    actions: {

        copySuccess(actions, id) {
            actions.dispatch('notifications/success',
                {title: 'Success', message: 'Refinance  ID ' + id + ' copied'},
                {root: true});

        },
        sendChoosenFilesToMail(actions,{refinanceId,email,filesId}){

            return new Promise(function (resolve, reject) {
                api.sendRequest('refinance/sendRefinanceFiles/' +refinanceId ,{

                    email,
                    fileIds:filesId

                }).then(
                    (ans) => {
                        if (ans["userids"] == null)
                            return reject('Could not send email ');

                        actions.dispatch('notifications/success',
                            {title: 'Mail Documents', message: 'Email sent successfully'},
                            {root: true});

                        resolve(ans["userids"]);
                    }, (error) => {

                        actions.dispatch('notifications/error',
                            {
                                title: 'Mail Documents',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })

        },
        sendAllFilesByMailToUser(actions,{refinanceId}){

            return new Promise(function (resolve, reject) {
                api.sendRequest('refinance/sendRefinanceDocsToUser/' +refinanceId,{}).then(
                    (ans) => {
                        if (ans["userids"] == null)
                            return reject('Could not send email ');

                        actions.dispatch('notifications/success',
                            {title: 'Mail Documents', message: 'Email sent successfully'},
                            {root: true});

                        resolve(ans["userids"]);
                    }, (error) => {

                        actions.dispatch('notifications/error',
                            {
                                title: 'Mail Documents',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })

        },



        sendBankerMail(actions,{banks,refinanceId}){

            return new Promise(function (resolve, reject) {
                api.sendRequest('refinance/sendAgentMail',
                    {refinanceId}, banks).then(
                    (ans) => {
                        if (ans["userids"] == null)
                            return reject('Could not send email ');

                        actions.dispatch('notifications/success',
                            {title: 'Email banker', message: 'Email sent successfully'},
                            {root: true});

                        resolve(ans["userids"]);
                    }, (error) => {

                        actions.dispatch('notifications/error',
                            {
                                title: 'Email banker',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })

        },

        getAllRefinances(actions, {page, amount, filter, sortcolumn, sortby}) {

            return new Promise(function (resolve, reject) {
                    api.sendRequest('refinance/get', {
                        buids: actions.rootGetters['data/getSelectedBranchesIds'].join(","),
                        page,
                        amount,
                        sortcolumn,
                        sortby
                    }, filter).then(
                        (ans) => {
                            if (ans["Refinances"] == null)
                                return reject('Could not get Refinances ');

                            resolve(ans);
                        }, (error) => {
                            reject(error.message)
                        })
                })

        },
        getRefinanceList() {

            return new Promise(function (resolve, reject) {
                api.sendRequest('refinance/getRefinanceList', null, null).then(
                    (ans) => {
                        if (ans["Refinances"] == null)
                            return reject('Could not get refinanceList ');

                        resolve(ans["Refinances"]);
                    }, (error) => {

                        reject(error.message)
                    })
            })

        },

        getRefinanceById(actions, id) {
            return new Promise((resolve, reject) => {

                api.sendRequest('refinance/id/' + id, {}).then(
                    (ans) => {

                        if (ans["Refinance"] == null)
                            return reject('Could not get refinance ');

                        resolve(ans["Refinance"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },

        updateRefinance(actions, refinance) {
            return new Promise((resolve, reject) => {

                api.sendRequest('refinance/update', null, refinance).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Update refinance', message: 'Refinance updated successfully'},
                            {root: true});

                        if (ans["RefinanceId"] == null)
                            return reject('Could not update refinance ');

                        resolve(ans["RefinanceId"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        addRefinance(actions, {id,type}) {
            return new Promise((resolve, reject) => {
                api.sendRequest('refinance/add/'+id+'/'+type, null).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Add refinance', message: 'Refinance request created successfully'},
                            {root: true});

                        if (ans["RefinanceId"] == null)
                            return reject('Could not create refinance ');

                        console.log("refinance answer created: ", ans)
                        resolve(ans["RefinanceId"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        }

    },
};
