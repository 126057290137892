import {vue} from "../../../main";
export const cleaningModule = {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchAllCleaningTasks(actions, {page, amount, filter, sortcolumn, sortby}) {
            let params = {
                buids: actions.rootGetters['data/getSelectedBranchesIds'].join(","),
                page,
                amount,
                sortcolumn,
                sortby
            };
            return this.$api.baseApi().post('/cleaning/history', filter, {params})
                .then(response => {
                    return response.data?.objects;
                }).catch(error => {
                    actions.dispatch('notifications/error',
                        {
                            title: {text: '%s - failed to retrieve', arguments: ['cleaning']},
                            message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                        },
                        {root: true});
                    return error;
                });
        },
        fetchById(actions, id) {
            return this.$api.baseApi().get(`/cleaning/get/${id}`)
                .then(response => {
                    return response.data?.objects;
                }).catch(error => {
                    actions.dispatch('notifications/error',
                        {
                            title: {text: '%s - failed to retrieve', arguments: ['cleaning']},
                            message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                        },
                        {root: true});
                    return error;
                });
        },
        fetchCleaningTasks(actions, filter) {
            let params = {
                buids: actions.rootGetters['data/getSelectedBranchesIds'].join(","),
            };
            return this.$api.baseApi().post('/cleaning/tasks', filter, {params})
                .then(response => {
                    return response.data?.objects;
                }).catch(error => {
                    actions.dispatch('notifications/error',
                        {
                            title: {text: '%s - failed to retrieve', arguments: ['cleaning']},
                            message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                        },
                        {root: true});
                    return error;
                });
        },
        saveCleaningTask(actions, {id,request}) {
            return this.$api.baseApi().post(`/cleaning/update/${id}/status`, request)
                .then(response => {
                    actions.dispatch('notifications/success',
                        {
                            // title: vue.$t('update_success'),
                            title: 'Update clean task',
                            message: 'Task updated successfully',
                        },
                        {root: true});
                    return response.data?.objects;
                }).catch(error => {
                    actions.dispatch('notifications/error',
                        {
                            title: 'Update clean task',
                            message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                        },
                        {root: true});
                    return error;
                });
        },
    }
}
