import api from "@/services/api.service";


export const realEstatesContractsStatsModule = {
    namespaced: true,
    actions: {
        getCheckinStats(actions, request) {
            request = (request || {});
            request.buids= actions.rootGetters['data/getSelectedBranchesIds'];
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/contract/stats/checkin', null, request).then(
                    (ans) => {
                        resolve(ans);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        getExpensesStats(actions, request) {
            request = (request || {});
            request.buids= actions.rootGetters['data/getSelectedBranchesIds'];
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/contract/stats/expenses', null, request).then(
                    (ans) => {
                        resolve(ans);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        getExpensesStatsReport(actions, request) {
            let $this = this;
            request = (request || {});
            request.buids= actions.rootGetters['data/getSelectedBranchesIds'];
            return new Promise(function (resolve, reject) {
                $this.$api.downloadApi().post('realEstate/contract/stats/expenses/report', request).then(
                    (ans) => {
                        resolve(ans);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        }
    }
}
