import api from '../../../services/api.service'

export const smsModule = {
    namespaced: true,
    state: {},
    getters: {
        canReadMonetary(state, getters, rootState, rootGetters) {
            return rootGetters['user/canRead']('client_sms');
        },
        canEditMonetary(state, getters, rootState, rootGetters) {
            return rootGetters['user/canEdit']('client_sms');
        },

    },
    mutations: {},
    actions: {
        getData() {
            return new Promise(function (resolve) {

                resolve();

            })
        },

        getAllSms(actions, { page, amountPerPage, filter,sortby,sortcolumn }) {

            return new Promise(function (resolve, reject) {
                actions.dispatch('getData', false).finally(() => {
                    api.sendRequest('sms/get', {
                        amount: amountPerPage,
                        buids: actions.rootGetters['data/getSelectedBranchesIds'].join(","),

                        page: page == null ? 0 : page,
                        sortby, sortcolumn,
                    },filter).then(
                        (ans) => {
                            if (ans["messages"] == null)
                                return reject('Could not get messages ');
                            resolve({
                                messages: ans['messages'],
                                amountPerPage: ans['Amount'],
                                total: ans['Total'],
                                page: ans['Page']
                            })
                        }, (error) => {

                            reject(error.message)
                        })
                })

            })
        },

    },
};
