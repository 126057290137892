import $ from 'jquery'
import { vue } from '@/main'

export default {

  get(url, params, header) {
    return vue.$api.baseApi().get(url,{params: params, headers: header || {}})
        .then((response) => response.data.objects)
  },
  sendRequest(url, getParams, bodyParams, header, bearer) {
    if(bearer !== false) {
      return vue.$api.baseApi().post(url, bodyParams, {params: getParams, headers: header || {}})
          .then((response) => response.data.objects)
          /*.then((response) => {
            let body
            if (typeof data !== 'object') {
              body = {
                status: false,
                message: textStatus
              }
            } else {
              body = data
            }

            // get status
            if (headers.status === 400) {
              //Todo logout
              reject(body)
              return
            }

            if(headers.getResponseHeader("X-Authorization") != null){
              localStorage.setItem('Bearer', headers.getResponseHeader("X-Authorization"))
            }
            if (body.status === true) {
              resolve(body.objects)
              return
            }

            if (body.message == null) {
              body.message = 'Unknown error'
            }

            reject(body)
          })*/
    }else {
      return vue.$api.basic().post(url, bodyParams, {params: getParams, headers: header || {}})
    }
  },

  /*sendRequest(url, getParams, bodyParams, header, bearer) {
    let $this = this
    const msg = vue.$store.getters['translator/msg']

    header = header || {}
    header['Content-Type'] = 'application/json'
    header['Accept'] = '*!/!*'
    header['Authorization'] = bearer !== false && localStorage.Bearer != null ? ("Bearer " + localStorage.Bearer) : null
    if (localStorage.base != null) {
      header['org'] = localStorage.base
    }

    getParams = getParams || {}
    //Remove null values
    Object.keys(getParams)
      .filter(key => getParams[key] == null)
      .forEach((key) => getParams[key] = '')
    bodyParams = bodyParams || {}
    return new Promise(function (resolve, reject) {

      let ajaxParams = {
        type: 'POST',
        url: `${window.config.apiUrl}${url}?${new URLSearchParams(getParams).toString()}`,
        data: JSON.stringify(bodyParams),
        headers: header,
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true
      }
      ajaxParams['contentType'] = 'application/json'

      let request = $.ajax(ajaxParams)

      request.done(function (data, textStatus, headers) {
        // console.log("apiAns",{data, textStatus, headers})
        let body
        if (typeof data !== 'object') {
          body = {
            status: false,
            message: textStatus
          }
        } else {
          body = data
        }

        // get status
        if (headers.status === 400) {
          //Todo logout
          reject(body)
          return
        }

        if(headers.getResponseHeader("X-Authorization") != null){
          localStorage.setItem('Bearer', headers.getResponseHeader("X-Authorization"))
        }
        if (body.status === true) {
          resolve(body.objects)
          return
        }

        if (body.message == null) {
          body.message = 'Unknown error'
        }

        reject(body)
      })
      request.fail(function (jqXHR, textStatus) {
        let message
        let json = jqXHR.responseJSON
        if (json == null) {
          if (jqXHR.status === 401) {
            message = 'Access denied'
          } else if (jqXHR.status < 200) {
            message = 'Connection to server failed'
          } else {
            message = textStatus
          }
        } else {
          if (json.objects == null) {
            json.objects = {}
          }

          message = $this.getError(json, jqXHR.status)
        }

        reject({
          status: false,
          message: message,
          code: jqXHR.status
        })
      })
    })
  },*/

  sendExternalRequest(url, requestType, params, header, isJsonAns) {
    if (requestType != null) {
      requestType = requestType.toString()
        .toUpperCase()
      if (requestType !== 'POST' && requestType !== 'GET') {
        requestType = 'GET'
      }
    } else {
      requestType = 'GET'
    }
    return new Promise(function (resolve, reject) {

      let request = $.ajax({
        type: requestType,
        url,
        data: params,
        headers: header
      })

      request.done(function (data, textStatus, headers) {

        let body

        if (isJsonAns !== false) {
          if (typeof data !== 'object') {
            body = JSON.parse(data)
          } else {
            body = data
          }
        }

        resolve({
          status: (headers.status == 200),
          data: body,
          code: headers.status
        })
      })
      request.fail(function (jqXHR, textStatus) {
        let message
        if (jqXHR.responseJSON == null) {
          if (jqXHR.status === 401) {
            message = 'Access denied'
          } else if (jqXHR.status < 200) {
            message = 'Connection to server failed'
          } else {
            message = textStatus
          }
        } else {
          message = jqXHR.responseJSON.message != null ? jqXHR.responseJSON.message : textStatus
        }

        reject({
          status: false,
          message: message,
          code: jqXHR.status
        })
      })
    })
  },
  uploadFile(url, files, action, params) {
    console.log(files)
    let header = {}
    params = params || {}
    if (localStorage.Bearer != null) {
      header['Authorization'] = "Bearer " + localStorage.Bearer
    }
    return new Promise(function (resolve, reject) {
      let data = new FormData()
      data.append('file', files)
      let request = $.ajax({
        url: `${window.config.apiUrl}${url}/${action}?1=1&${$.param(params)}`,
        headers: header,
        data: data,
        cache: false,
        contentType: false,
        processData: false,
        method: 'POST',
        type: 'POST', // For jQuery < 1.9
      })
      request.done(function (data, textStatus, headers) {

        let body
        if (typeof data !== 'object') {
          body = {
            status: false,
            message: textStatus
          }
        } else {
          body = data
        }

        // get status
        if (headers.status === 400) {
          //Todo logout
          reject(body)
          return
        }

        if (body.status === true) {
          resolve(body.objects)
          return
        }

        if (body.message == null) {
          body.message = 'Unknown error'
        }

        reject(body)
      })
      request.fail(function (jqXHR, textStatus) {
        let message
        if (jqXHR.responseJSON == null) {
          if (jqXHR.status === 401) {
            message = 'Access denied'
          } else if (jqXHR.status < 200) {
            message = 'Connection to server failed'
          } else {
            message = textStatus
          }
        } else {
          message = jqXHR.responseJSON.message != null ? jqXHR.responseJSON.message : textStatus
        }

        reject({
          status: false,
          message: message,
          code: jqXHR.status
        })
      })
    })
  },


  getError(error, defaultMsg) {
    if (typeof error != 'object') {
      return defaultMsg
    }
    const msg = vue.$store.getters['translator/msg']
    let message = null
    switch (error.errorType) {
      case 'REQUIRED':
        message = msg(error.objects.field) + ' ' + msg('is required')
        break

      case 'PERMISSION':
        message = msg('Permission') + ': ' + msg(error.objects.permission) + ' [' + msg(error.objects.action) + '] ' + msg('is required')
        break

    }
    if (message == null) {
      message = error.message || defaultMsg
    }
    return message
  },

  openWindow(url, title) {
    let left = (screen.width / 2) - (880 / 2)
    let top = (screen.height / 2) - (516 / 2)
    window.open(url, title, 'width=880,height=516, top=' + top + ', left=' + left + '')
  },

  downloadfile(url, params) {

    let header = {}
    if (localStorage.Bearer != null) {
      header['Authorization'] = "Bearer " + localStorage.Bearer
    }
    $.ajax({
      url: `${window.config.apiUrl}${url}`,
      type: 'GET',
      dataType: 'binary',
      headers: header,
      data: params,
      // processData: false,
      success: function (blob) {
        var windowUrl = window.URL || window.webkitURL;
        var url = windowUrl.createObjectURL(blob);
        /*anchor.prop('href', url);
        anchor.prop('download', fileName);
        anchor.get(0).click();*/
        windowUrl.revokeObjectURL(url);
      }
    });
  }
}
