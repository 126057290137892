import api from "../../../services/api.service"


export const faceDetectionModule = {
    namespaced: true,
    state: {},
    getters: {
        canReadFaceDetection(state, getters, rootState, rootGetters) {
            return rootGetters['user/canRead']('faceDetection');
        },


    },
    mutations: {

    },
    actions: {

        getAllFaceCounter(actions,filter) {

            return new Promise(function (resolve, reject) {
                api.sendRequest('facedetection/data/all', {buids:actions.rootGetters['data/getSelectedBranchesIds'].join(","),},filter).then(
                    (ans) => {
                        if (ans["faces"] == null)
                            return reject('Could not get faces ');

                        resolve(ans);
                    }, (error) => {

                        reject(error.message)
                    })

            })
        },
        //FILTER BY DEVICE FUNCTIONS

        getAllFaceCounterByDevice(actions,filter) {

            return new Promise(function (resolve, reject) {
                api.sendRequest('facedetection/data/device/'+filter.deviceId,
                    {buids:actions.rootGetters['data/getSelectedBranchesIds'].join(","),},filter).then(
                    (ans) => {
                        if (ans["faces"] == null)
                            return reject('Could not get faces ');

                        resolve(ans);
                    }, (error) => {

                        reject(error.message)
                    })

            })
        },
        getAllImagesFaces(actions,{filter,paginator}) {

            return new Promise(function (resolve, reject) {
                api.sendRequest('facedetection/images/all',
                    {buids:actions.rootGetters['data/getSelectedBranchesIds'].join(","),...paginator},filter).then(
                    (ans) => {
                        if (ans["images"] == null)
                            return reject('Could not get faces ');

                        resolve(ans);
                    }, (error) => {

                        reject(error.message)
                    })

            })
        },


        getAllImagesFacesByDevice(actions,{filter,paginator}) {

            return new Promise(function (resolve, reject) {
                console.log("vuex gate face image by device with id: ",filter.deviceId)
                api.sendRequest('facedetection/images/device/'+filter.deviceId,{buids:actions.rootGetters['data/getSelectedBranchesIds'].join(","),...paginator},filter).then(
                    (ans) => {
                        if (ans["images"] == null)
                            return reject('Could not get faces ');

                        resolve(ans);
                    }, (error) => {

                        reject(error.message)
                    })

            })
        },


    },
};
