import api from "../../../services/api.service"


export const taxModule = {
    namespaced: true,
    actions: {
        getTaxes({commit}) {
            return new Promise(function (resolve, reject) {
                    api.sendRequest('product/tax-rate/all').then(
                        (ans) => {
                            resolve(ans["taxes"]);
                        }, (error) => {
                            reject(error.message)
                        });
                }
            )
        }
    }
}
