import Vue from 'vue'


import i18n from './plugins/vue-i18n'
import {router} from './router'
import store from './store'
import App from './App.vue'


import vueResources from "vue-resource"
import PortalVue from 'portal-vue'

import Notifications from 'vue-notification'
import velocity from 'velocity-animate'
//import Vuetify from './plugins/vuetify'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import Vuelidate from 'vuelidate'
import $ from 'jquery';

require('./plugins/ui-core');
require('./plugins/custom-prototypes');
require('./plugins/vSelect');
require('./plugins/moment');
require('./plugins/VueCtkDateTimePicker');
// require('./plugins/vue-material');
require('./plugins/vue2-dropzone');
require('./plugins/chartjs-plugin-labels');
require('./plugins/fullcalendar');
require('./plugins/vee-validate');
require('./plugins/quill-editor');
require('./plugins/swiper');
require('./plugins/vue-swatches');
require('./plugins/vue-ripple-directive');
require('./plugins/v-calendar');
require('./plugins/vue-month-picker');
require('./plugins/axios');
require('./plugins/vue-signature-pad');
require('./plugins/vue-advanced-chat');
require('./plugins/number-formatter');
require('./plugins/handy-scroll');
require('./plugins/vue-form-wizard');

//DEBUG
import api from './services/api.service'
import BootstrapVue from "bootstrap-vue";

window.$ = window.jQuery = $;

Vue.config.productionTip = false;
Vue.use(vueResources);
Vue.use(PortalVue);
Vue.use(BootstrapVue);

import VueLazyload from 'vue-lazyload';

Vue.use(VueLazyload);

Vue.use(Notifications, {velocity});

//Vue.component(Card)
//Vue.component(StatsCard)
//Vue.component(ChartCard)

// Material Design

Vue.use(PerfectScrollbar);
Vue.use(Vuelidate);
import * as VueGoogleMaps from 'vue2-google-maps';

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDuYbooGHoscpVUkamK-qq1OBBE-erTVUQ',
    libraries: 'places',
  },
});

// Import the component
import FullCalendar from 'vue-fullcalendar';

// Register the component globally or locally
Vue.component('full-calendar', FullCalendar);

Vue.config.productionTip = false

export const vue = new Vue({
  router,
  store,
  i18n,
 // vuetify:Vuetify,
  render: h => h(App),
}).$mount('#app')

window.addEventListener('message', function (event) {
  // IMPORTANT: Check the origin of the data!
  if (event.origin.indexOf((window.protocol != null ? window.protocol : "http:") + "//" + window.location.host) < 0) {
    vue.$store.dispatch("data/onExternalMsg", {origin: event.origin, data: event.data})
  }
});

window.onerror = function (message, source, line, column, error) {
  console.log('ONE ERROR HANDLER TO RULE THEM ALL:', {message, source, line, column, error});
}
window.addEventListener('message', function (event) {
  // IMPORTANT: Check the origin of the data!
  if (event.origin.indexOf((window.protocol != null ? window.protocol : "http:") + "//" + window.location.host) < 0) {
    vue.$store.dispatch("data/onExternalMsg", {origin: event.origin, data: event.data})
  }
});
window.api = api;
