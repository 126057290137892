import api from "../../services/api.service";


export const documentModule = {
    namespaced: true,
    state: {
        data: {
            loaded: false,
        },


    },
    getters: {
        canReadDocuments(state, getters, rootState, rootGetters) {
            return rootGetters['user/canRead']('client_documents');
        },
    },
    mutations: {
        updateData(state, data) {
            let stateData = {};
            stateData.loaded = true;
            state.data = stateData;
        }
    },
    actions: {
        loadData({state, dispatch}) {
            return new Promise(function (resolve, reject) {
                if (state.data.loaded === true)
                    return resolve();

                if (Object.keys(state.data).length === 0 && state.data.constructor === Object) {
                    dispatch('getData').then(() => resolve(), (e) => reject(e));
                } else
                    return resolve();
            });
        },
        getData({state, commit, dispatch}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('client/data').then(
                    (ans) => {

                        commit('updateData', ans);
                        resolve(state);
                    }, (error) => {
                        dispatch('notifications/error',
                            {
                                title: 'Getting data',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },

        getClientDocuments(actions, clientId) {

            return new Promise(function (resolve, reject) {
                actions.dispatch('getData', false).finally(() => {
                    api.sendRequest('documents/client/' + clientId).then(
                        (ans) => {

                            if (ans["Documents"] == null)
                                return reject('Could not get Documents');


                            resolve(ans["Documents"]);
                        }, (error) => {

                            reject(error.message)
                        })
                })

            })
        },

        uploadFile(actions, {file, group, clientId}) {
            return new Promise(function (resolve, reject) {
                api.uploadFile('documents', file, "add", {group, client: clientId}).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Uploading file', message: 'File uploaded successfully'},
                            {root: true});
                        resolve(ans['document']);
                    }, (error) => {
                        actions.dispatch('notifications/error',
                            {
                                title: 'Uploading file',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            });
        },
        uploadUserFile(actions, {file,onUploadProgress}) {
            let $this = this;
            return new Promise(function (resolve, reject) {
                const formData = new FormData();
                formData.append("file", file);
                $this.$api.uploadApi().post('documents/user-add', formData, {onUploadProgress}).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Uploading file', message: 'File uploaded successfully'},
                            {root: true});
                        let data = ans.data.objects;
                        resolve({document: data['document'], file});
                    }, (error) => {
                        actions.dispatch('notifications/error',
                            {
                                title: 'Uploading file',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            });
        },
        //Deprecated
        getUrlForDownload(actions, {fileId, tokenParam, userFile}) {
            return new Promise(function (resolve, reject) {
                let bearer = tokenParam ? "?token-bearer=" + localStorage.Bearer : "";
                let url = userFile ? "documents/download/userFile/" : "documents/download/file/";
                resolve(window.config.apiUrl + url + fileId + bearer)
            });
        },
        downloadFile(actions, {fileId, clientId}) {
            let $this = this;
            return new Promise(function (resolve, reject) {
                $this.$api.downloadApi().get('documents/download/file/'+fileId, {params: {client: clientId}})
                    .then(
                        (response) => {
                            console.log(response.headers)

                            let contentType = response.headers["content-type"] || "";
                            let contentDisposition = response.headers["Content-disposition"] || "";
                            let fileName = "file";
                            if (contentDisposition.includes("filename=")){
                                fileName = contentDisposition.substr(contentDisposition.toString().indexOf("filename=") + 9);
                            }
                            if (fileName === "file" && contentType.includes("filename=")) {
                                fileName = contentType.substr(contentType.toString().indexOf("filename=") + 9)
                            }
                            resolve({data: new Blob([response.data], {type: contentType}), fileName, contentType});
                        }, (error) => {
                            actions.dispatch('notifications/error',
                                {
                                    title: 'Downloading file',
                                    message: 'Could not download file'
                                },
                                {root: true});
                            reject(error)
                        })
            })
        },
        downloadCustomFile(actions, {fileId}) {
            let $this = this;
            return new Promise(function (resolve, reject) {
                $this.$api.downloadApi().get('documents/download/customFile/'+fileId,null)
                    .then(
                        (response) => {

                            let contentType = response.headers["content-type"] || "";
                            let contentDisposition = response.headers["Content-disposition"] || "";
                            let fileName = "file";
                            if (contentDisposition.includes("filename=")){
                                fileName = contentDisposition.substr(contentDisposition.toString().indexOf("filename=") + 9);
                            }
                            if (fileName === "file" && contentType.includes("filename=")) {
                                fileName = contentType.substr(contentType.toString().indexOf("filename=") + 9)
                            }
                            resolve({data: new Blob([response.data], {type: contentType}), fileName, contentType});
                        }, (error) => {
                            actions.dispatch('notifications/error',
                                {
                                    title: 'Downloading file',
                                    message: 'Could not download file'
                                },
                                {root: true});
                            reject(error)
                        })
            })
        },
        downloadUserFile(actions, {fileId, onDownloadProgress}) {
            let $this = this;
            return new Promise(function (resolve, reject) {
                $this.$api.downloadApi().get('documents/download/userFile/'+fileId,{onDownloadProgress})
                    .then(
                        (response) => {
                            console.log(response.headers)

                            let contentType = response.headers["content-type"] || "";
                            let contentDisposition = response.headers["content-disposition"] || "";
                            let fileName = "file";
                            if (contentDisposition.includes("filename=")){
                                fileName = contentDisposition.substr(contentDisposition.toString().indexOf("filename=") + 9);
                            }
                            if (fileName === "file" && contentType.includes("filename=")) {
                                fileName = contentType.substr(contentType.toString().indexOf("filename=") + 9)
                            }
                            resolve({data: new Blob([response.data], {type: contentType}), fileName, contentType});
                        }, (error) => {
                            actions.dispatch('notifications/error',
                                {
                                    title: 'Downloading file',
                                    message: 'Could not download file'
                                },
                                {root: true});
                            reject(error)
                        })
            })
        }
    }
};
