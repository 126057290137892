import api from "../../../services/api.service"


export const realEstateBookingModule = {
    namespaced: true,
    state: {
        data: {types:[],categories:[]},
    },
    getters: {
        canReadRealEstate(state, getters, rootState, rootGetters) {
            return rootGetters['user/canRead']('real_estate');
        },
        canEditRealEstate(state, getters, rootState, rootGetters) {
            return rootGetters['user/canEdit']('real_estate');
        },
        allBookingStatus(){
            return [
                {value: "ACTIVE", text: 'ACTIVE'},
                {value: "PENDING", text: 'PENDING'},
                {value: "CANCELLED", text: 'CANCELLED'},
                {value: "COMPLETED", text: 'COMPLETED'}]
        }


    },
    mutations: {
        updateData(state, data) {
            console.log("realEstateModule ->updateData", data)
            let stateData = {};
            stateData.loaded = true;

            state.data = stateData;
        }
    },
    actions: {
        getData({state, commit, dispatch}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstateAppointment/data').then(
                    (ans) => {

                        commit('updateData', ans);
                        resolve(state);
                    }, (error) => {
                        dispatch('notifications/error',
                            {
                                title: 'Getting data',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },


        getRequestById(actions, requestId) {
            return new Promise((resolve, reject) => {

                api.sendRequest('realEstateAppointment/request/' + requestId, {}).then(
                    (ans) => {

                        if (ans["BookingRequest"] == null)
                            return reject('Could not get request ');

                        resolve(ans);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        sendConfirmMail(actions, request) {
            return new Promise((resolve, reject) => {

                api.sendRequest('realEstateAppointment/approve', {},request).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Confirmation email', message: 'Confirmation email was sent successfully'},
                            {root: true});

                        if (ans["RealEstatesAppointment"] == null)
                            return reject('Could not send request');

                        resolve(ans);
                    }, (error) => {

                        actions.dispatch('notifications/error',
                            {
                                title: 'Confirmation email',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },

        getAllRealEstatesBookings(actions,{ page, amount, filter,sortcolumn, sortby }) {

            return new Promise(function (resolve, reject) {
                    api.sendRequest('realEstateAppointment/get',
                        {amount: (amount == null ? 20 : amount),page: page == null ? 0 : page,sortby, sortcolumn,}
                        ,filter).then(
                        (ans) => {
                            if (ans["RealEstatesAppointments"] == null)
                                return reject('Could not get appointments ');

                            resolve({
                                appointments: ans['RealEstatesAppointments'],
                                amountPerPage: ans['Amount'],
                                total: ans['Total'],
                                page: ans['Page']
                            })
                        }, (error) => {

                            reject(error.message)
                        })
                })

        },

        getAppointmentById(actions, { id }){
            return new Promise((resolve, reject) => {

                api.sendRequest('realEstateAppointment/id/'+id).then(
                    (ans) => {

                        if (ans["RealEstatesAppointment"] == null)
                            return reject('Could not get real estate appointment ');

                        resolve(ans)
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },

        updateRealEstateBooking(actions,appointment){
            return new Promise((resolve, reject) => {

                api.sendRequest('realEstateAppointment/update',null, appointment).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Update realEstate', message: 'RealEstate updated successfully'},
                            {root: true});

                        if (ans["RealEstateId"] == null)
                            return reject('Could not update realEstate ');

                        resolve(ans["RealEstateId"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },

    },
};
