import api from "@/services/api.service";

export const realEstatesBuyContractsModule = {
    namespaced: true,
    state: {
        data: {types: [], categories: []},
    },
    getters: {
        canReadRealEstate(state, getters, rootState, rootGetters) {
            return rootGetters['user/canRead']('real_estate');
        },
        canEditRealEstate(state, getters, rootState, rootGetters) {
            return rootGetters['user/canEdit']('real_estate');
        },
        fileTags(state, getters, rootState, rootGetters) {
            return [
                {value: "unknown", title: "unknown", icon: "fas fa-question"},
                {value: "contract", title: "contract", icon: "fas fa-file-contract"},
                {value: "signedContract", title: "signed contract", icon: "fas fa-file-signature"},
                {value: "clientDocument", title: "client document Id", icon: "fas fa-passport"},
                {value: "clientIncomes", title: "client incomes", icon: "fas fa-university"},
                {value: "clientDocument", title: "client document", icon: "fas fa-id-card"},
                {value: "document", title: "document", icon: "fas fa-file-alt"}
            ]
        },
        allStatus() {
            return [
                {value: "NEW", text: 'NEW'},
                {value: 'REVIEWING', text: 'REVIEWING'},
                {value: 'WAITING_FOR_DATA', text: 'WAITING FOR DATA'},
                {value: 'OWNER_APPROVED', text: 'OWNER APPROVED'},
                {value: 'COMPLETED', text: 'COMPLETED'}
            ]
        },
    },
    mutations: {},
    actions: {
        getAllContracts(actions, {page, amount, filter, sortcolumn, sortby}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/purchase/contract/get', {
                    buids: actions.rootGetters['data/getSelectedBranchesIds'].join(","),
                    page, amount, sortcolumn, sortby
                }, filter).then(
                    (ans) => {
                        if (ans["contracts"] == null)
                            return reject('Could not get contracts ');

                        resolve(ans);
                    }, (error) => {

                        reject(error.message)
                    })
            })
        },
        getById(actions, id) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/purchase/contract/id/' + id).then(
                    (ans) => {
                        if (ans["contract"] == null)
                            return reject('Could not get contracts ');

                        resolve(ans);
                    }, (error) => {

                        reject(error.message)
                    })
            })
        },
        getContractParts(actions, id) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/purchase/contract/get-parts/' + id).then(
                    (ans) => {
                        if (ans["parts"] == null)
                            return reject('Could not get contracts parts');
                        resolve(ans);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        saveContractParts(actions, {id,textParts}) {
            let $this = this;
            return new Promise(function (resolve, reject) {
                $this.$api.baseApi().post('realEstate/purchase/contract/add-parts/' + id, textParts).then(
                    (ans) => {
                        let data = ans.data.objects;
                        if (data["parts"] == null)
                            return reject('Could not get save parts');
                        actions.dispatch('notifications/success',
                            {
                                title: {text: 'Contract parts'},
                                message: {text: '%s parts saved successfully', arguments: ['Contract']}
                            },
                            {root: true});
                        resolve(data);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        generateContractPdf(actions, contractId) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/contract/generateAndSavePurchase/' + contractId).then(
                    (ans) => {
                        if (ans["contract"] == null)
                            return reject('Could not generate and save contract');
                        actions.dispatch('notifications/success',
                            {
                                title: {text: 'Contract generator'},
                                message: {text: '%s generated and saved successfully', arguments: ['Contract']}
                            },
                            {root: true});

                        resolve(ans);
                    }, (error) => {
                        actions.dispatch('notifications/error',
                            {
                                title: {text: 'Contract %s', arguments: ['couldnt been generated']},
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })

            })
        },
        signContract(actions, {contractId, signature}) {
            let $this = this;
            return new Promise(function (resolve, reject) {
                $this.$api.baseApi().post('realEstate/purchase/contract/sign/' + contractId, {signature})
                    .then((response) => {
                        actions.dispatch('notifications/success',
                            {title: {text: 'Contract %s', arguments: ['signed']}, message: {text: '%s signed successfully', arguments: ['Contract']}},
                            {root: true});

                        resolve(response.data.objects);
                    }, (error) => {

                        actions.dispatch('notifications/error',
                            {
                                title: 'Sign contract',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        generateAndSendLink(action, id) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/purchase/contract/generateLink/' + id).then(
                    (ans) => {
                        if (ans["status"] == null)
                            return reject('Could not generate and send link ');
                        action.dispatch('notifications/success',
                            {
                                title: {text: 'Link generator'},
                                message: {text: '%s generated and sent successfully', arguments: ['Link']}
                            },
                            {root: true});

                        resolve(ans);
                    }, (error) => {
                        action.dispatch('notifications/error',
                            {
                                title: {text: 'Link %s', arguments: ['send error']},
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        createContract(actions, contract) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/purchase/contract/create/' + contract.propertyId, null, contract)
                    .then(
                        (ans) => {
                            if (ans["id"] == null)
                                return reject('Could not create contract');

                            actions.dispatch('notifications/success',
                                {
                                    title: {text: 'Contract %s', arguments: ['creation']},
                                    message: {text: '%s created successfully', arguments: ['Contract']}
                                },
                                {root: true});
                            resolve(ans["id"]);
                        }, (error) => {
                            actions.dispatch('notifications/error',
                                {
                                    title: {text: 'Contract %s', arguments: ['Error']},
                                    message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                                },
                                {root: true});
                            reject(error.message)
                        })
            })
        },
        updateContract(action, {id, contract}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/purchase/contract/update/contract/' + id, null, contract).then(
                    (ans) => {
                        if (ans["contract"] == null)
                            return reject('Could not update contract');

                        action.dispatch('notifications/success',
                            {
                                title: {text: 'Contract %s', arguments: ['details']},
                                message: {text: '%s updated successfully', arguments: ['Details']}
                            },
                            {root: true});
                        resolve(ans["contract"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        changeContractOwner(action, {id, ownerId}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/purchase/contract/update/contract/' + id + '/' + ownerId, null, null).then(
                    (ans) => {
                        if (ans["contract"] == null)
                            return reject('Could not update contract');

                        action.dispatch('notifications/success',
                            {
                                title: {text: 'Contract %s', arguments: ['details']},
                                message: {text: '%s updated successfully', arguments: ['Details']}
                            },
                            {root: true});
                        resolve(ans["contract"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        updateFilesData(action, {id, contract}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/purchase/contract/update/files/' + id, null, contract).then(
                    (ans) => {
                        if (ans["documents"] == null)
                            return reject('Could not update files');

                        action.dispatch('notifications/success',
                            {
                                title: {text: 'Contract %s', arguments: ['files']},
                                message: {text: '%s updated successfully', arguments: ['Files']}
                            },
                            {root: true});
                        resolve(ans["documents"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        updateStatus(action, {id, status}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/purchase/contract/update/status/' + id, null, status).then(
                    (ans) => {
                        if (ans["history"] == null)
                            return reject('Could not update status');

                        action.dispatch('notifications/success',
                            {
                                title: {text: 'Contract %s', arguments: ['status']},
                                message: {text: '%s updated successfully', arguments: ['Status']}
                            },
                            {root: true});
                        resolve(ans["history"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        updateApproveStatus(action, {id, approveStatus}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/purchase/contract/update/approve/' + id, null, approveStatus).then(
                    (ans) => {
                        if (ans["approveStatus"] == null)
                            return reject('Could not status');

                        action.dispatch('notifications/success',
                            {
                                title: {text: 'Approvement %s', arguments: ['status']},
                                message: {text: '%s updated successfully', arguments: ['Approvement']}
                            },
                            {root: true});
                        resolve(ans["approveStatus"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        updateEditableFields(action, {id, editableFields}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/purchase/contract/update/editable-fields/' + id, null, editableFields).then(
                    (ans) => {
                        if (ans["editableFields"] == null)
                            return reject('Could not status');

                        action.dispatch('notifications/success',
                            {
                                title: {text: 'Editable %s', arguments: ['status']},
                                message: {text: '%s updated successfully', arguments: ['Editable']}
                            },
                            {root: true});
                        resolve(ans["editableFields"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        updateDocumentFile(action, {id, file}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/purchase/contract/update/file/' + id, null, file).then(
                    (ans) => {

                        action.dispatch('notifications/success',
                            {
                                title: {text: 'file %s', arguments: ['status']},
                                message: {text: '%s updated successfully', arguments: ['file']}
                            },
                            {root: true});
                        resolve(ans["editableFields"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        sproofRequest(actions, {contractId}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('sproof/requestSignature/' + contractId,null, null).then(
                    (ans) => {

                        actions.dispatch('notifications/success',
                            {
                                title: {text: 'file %s', arguments: ['status']},
                                message: {text: '%s request sent successfully', arguments: ['Sproof']}
                            },
                            {root: true});
                        resolve(ans);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },

    }
}
