import Vue from 'vue';

import VueMoment from 'vue-moment';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/pt';
import 'moment/locale/fr';
// import 'moment/locale/he';
const moment= extendMoment(Moment);

Moment.locale(getLang())

Vue.use(VueMoment, {moment});
Vue.filter('moment', function (value, format, showTimeOnlyInSameDay) {
    if (value === null || value === undefined || format === undefined) {
        return ''
    }
    let momentValue = Moment(value);
    if (showTimeOnlyInSameDay === true && momentValue.isSame(Moment(), 'day')) {
        return Moment(value).format('HH:mm');
    }
    return Moment(value).format(format)
});

Vue.filter('momentTimeFromNow', function (value) {
    let today = Moment();
    let momentValue = Moment(value);
    if(today > momentValue){
        return momentValue.fromNow()
    }
    if(today.isSame(value, 'date')) {
        return Moment(value).format('HH:mm');
    }
    return momentValue.fromNow()
});

function getLang() {
    try {
        if (navigator.languages != null)
            return navigator.languages[0];
        return navigator.language;
    } catch (e) {
        console.warn('Error getting language', e);
        return 'de';
    }
}
