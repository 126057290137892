import api from "../../../services/api.service"


export const phoneRecordsModule = {
    namespaced: true,
    state: {
        data: {
            status: {},
            loaded: false
        },
        calls: [],
    },
    getters: {
        canReadPhone(state, getters, rootState, rootGetters) {
            return rootGetters['user/canRead']('phone');
        },
        canEditPhone(state, getters, rootState, rootGetters) {
            return rootGetters['user/canEdit']('phone');
        },
        getCalls(state) {
            return state.calls;
        },
        isLoaded(state) {
            return state.data.loaded
        },
        getStatus(state) {
            return state.data.status;
        }
    },
    mutations: {
        updateData(state, data) {
            if (data["CallTypeNames"] != null)
                state.data.status = data["CallTypeNames"];
            state.data.loaded = true;
        },
        setAllCalls(state, data) {
            state.calls = data.Phones;
            state.data.loaded = true;
        }
    },
    actions: {
        //add sendmail to the arguemtn list of the function
        update({state, dispatch}, {id,status}) {
            return new Promise(function (resolve, reject) {
                let request = {action: "update"};
                //In order to prevent a bug or a mistake, the id must be send
                request['id'] = id;
                request['status'] = status;
                request['sendmail'] = 1;
                api.sendRequest('/records/phone/update', request).then(
                    (ans) => {
                        resolve();
                    }, (error) => {
                        dispatch('notifications/error', {
                            title: 'Getting data',
                            message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                        }, {root: true});
                        reject(error.message)
                    })
            })
        },
        getData({state, commit, dispatch}, force) {
            return new Promise(function (resolve, reject) {
                if (force !== true && state.data.loaded)
                    resolve();
                api.sendRequest('/records/phone/data').then(
                    (ans) => {

                        commit('updateData', ans);
                        resolve();
                    }, (error) => {
                        dispatch('notifications/error', {
                            title: 'Getting data',
                            message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                        }, {root: true});
                        reject(error.message)
                    })
            })
        },
        //This function will return all the but not save it
        getAllCalls({dispatch, commit, state}, {page, amountPerPage, filter}) {

            if (typeof filter !== "object")
                filter = {};

            if (filter.id == null || filter.id.trim() === "" || isNaN(filter.id))
                filter.id = null;

            if (isNaN(Date.parse(filter.from)))
                filter.from = null;

            if (isNaN(Date.parse(filter.until)))
                filter.until = null;
            return new Promise(function (resolve, reject) {
                dispatch('getData', false).finally(() => {
                    state.data.loaded = false;
                    api.sendRequest('/records/phone/get', {
                        amount: (amountPerPage == null ? 20 : amountPerPage),
                        page: page == null ? 0 : page,
                    },filter).then(
                        (ans) => {

                            if (ans["Phones"] == null)
                                return reject('Could not get phone records');

                            commit("setAllCalls", ans);
                            resolve(ans["Phones"]);
                        }, (error) => {

                            reject(error.message)
                        })
                })

            })
        },

        //This function will return all the but not save it
        getPhoneRecords({dispatch, commit}, {page, amountPerPage, filter}) {

            if (typeof filter !== "object")
                filter = {};

            if (filter.id == null || filter.id.trim() === "" || isNaN(filter.id))
                filter.id = null;

            if (isNaN(Date.parse(filter.from)))
                filter.from = null;

            if (isNaN(Date.parse(filter.until)))
                filter.until = null;
            return new Promise(function (resolve, reject) {
                dispatch('getData', false).finally(() => {
                    api.sendRequest('/records/phone/get', {
                        amount: (amountPerPage == null ? 20 : amountPerPage),
                        page: page == null ? 0 : page,
                    },filter).then(
                        (ans) => {

                            if (ans["Phones"] == null)
                                return reject('Could not get phone records');


                            resolve(ans["Phones"]);
                        }, (error) => {

                            reject(error.message)
                        })
                })

            })
        },
        //Get a Profile
        get({dispatch}, {id}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('/records/phone/id/'+id).then(
                    (ans) => {
                        resolve(ans.Phone)
                    }, (error) => {

                        reject(error.message)
                    })
            })
        },
    },
};
