import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages() {
  console.log('loadLocaleMessages()')
  const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  // console.debug('loadLocaleMessages()', locales.keys())

  locales.keys().forEach(key => {
    const matched = key.split("/")
    const locale = matched[1]
    const fileName = matched[2]
    // console.debug('loadLocaleMessages()->', { matched, locale, fileName })
    if (messages[locale] == null) {
      messages[locale] = locales(key)
    } else {
      messages[locale] = { ...messages[locale], ...locales(key) }
    }
  })
  return messages
}

export default new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: loadLocaleMessages(),
  fallbackWarn: false,
  missingWarn: false,
  silentTranslationWarn: true
})


