import api from "../../services/api.service"


export const BranchSettingsModule = {
    namespaced: true,
    state: {
        kyc: {
            data: {},
            status: {
                loaded: false,
                error: ''
            },
            kycs: []
        },
        mail: {
            data: {},
            status: {
                loaded: false,
                error: ''
            },
            mails: []
        },
    },
    getters : {
        mail : state => {
            return state.mail;
        }
    },
    actions: {
        loadData({state, dispatch}) {
            if (state.kyc.status.loaded === true && state.mail.status.loaded === true)
                return new Promise((r) => r(state));
            return dispatch('refresh');
        },
        refresh({state}) {
            state.kyc.status.loaded = false;
            state.mail.status.loaded = false;
            state.kyc.status.error = '';
            state.mail.status.error = '';
            return new Promise(function (resolve) {
                let done = 0;

               /* api.sendRequest('superuser/kyc', {action: "get"}).then(
                    (ans) => {

                        if (ans["Kycs"] == null) {
                            state.kyc.status.error = 'Kyc failed to refresh';
                        } else {
                            state.kyc.status.loaded = true;
                            state.kyc.status.error = '';
                            state.kyc.kycs = ans["Kycs"];
                        }
                        if (++done === 2)
                            resolve(state);
                    }, (error) => {
                        state.kyc.status.error = error.message;
                        ++done;
                    });
*/
                api.sendRequest('superuser/mail/get').then(
                    (ans) => {

                        if (ans["Mails"] == null) {
                            state.mail.status.error = 'Mail failed to refresh';
                        } else {
                            state.mail.status.loaded = true;
                            state.mail.status.error = '';
                            state.mail.mails = ans["Mails"];
                        }
                        if (++done === 2)
                            resolve(state);
                    }, (error) => {
                        state.mail.status.error = error.message;
                        ++done;
                    });

                //In order to prevent to the program, check if api its already done
                function check() {
                    if (++done === 2)
                        resolve(state);
                    else
                        setTimeout(check, 3000);
                }

                check();


            })
        },
        //Get a 
        getMail({dispatch}, {id}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('superuser/mail/id/'+id).then(
                    (ans) => {

                        resolve(ans.Smtp)
                    }, (error) => {

                        reject(error.message)
                    })
            })
        },

        updateMail({dispatch}, {id, mail}) {
            return new Promise(function (resolve, reject) {
                //Check if  is not undefined
                if (typeof mail !== "object")
                    reject("Invalid ");
                //generate a new object in order to not update the  object and add the field action
                let body = {...mail};
                //In order to prevent a bug or a mistake, the id must be send
                body['id'] = id;

                api.sendRequest('superuser/mail/update',null, body).then(
                    (ans) => {
                        //In case of success send notification
                        dispatch('notifications/success',
                            {title: 'Update mail', message: 'Mail updated successfully'},
                            {root: true});
                        //update table (in order to see the changes)
                        dispatch('refresh');
                        //send error
                        resolve(ans)
                    }, (error) => {
                        dispatch('notifications/error',
                            {
                                title: 'Update mail',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },

        
        registerMail({dispatch}, {mail}) {
            return new Promise(function (resolve, reject) {
                //Check if  is not undefined
                if (typeof mail !== "object")
                    reject("Invalid ");
                api.sendRequest('superuser/mail/add', null,mail).then(
                    (ans) => {
                        //In case of success send notification
                        dispatch('notifications/success',
                            {
                                title: 'Register Mail',
                                message: 'Mail registered successfully with id: ' + ans.SmtpId
                            },
                            {root: true});
                        //update table (in order to see the changes)
                        dispatch('refresh');
                        //send error
                        resolve(ans.SmtpId)
                    }, (error) => {
                        dispatch('notifications/error',
                            {
                                title: 'Register Mail',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        }
    }
};
