<template>
  <div class="auth-wrapper auth-v1 px-2"
       style="background-image: url('https://treasurehome.at/uploads/images/loginPage.jpeg');
            background-size: cover;
            background-repeat: no-repeat;
            position: relative;">

    <div class="auth-inner  p-3" style="background-color: rgba(255,255,255,0.68)">

      <b-link class="brand-logo">
        <h1 class="brand-text text-primary ml-1" style="text-shadow: 2px 2px 2px rgb(187,155,155);">
          TREASURE HOME
        </h1>
      </b-link>
      <b-card-title class="mb-1" style="color: #0c141c">
        {{ msg('Welcome') }}! 👋
      </b-card-title>
      <div v-if="!isloggedIn">
        <b-card-text class="mb-2" style="color: #0c141c">
          {{ msg('Please sign-in to your account') }}
        </b-card-text>
        <!-- form -->
        <validation-observer
            ref="loginForm"
            #default="{invalid}">
          <b-form
              style="color: #0c141c"
              class="auth-login-form mt-2"
              @submit.prevent>
            <!-- email -->
            <b-form-group
            >

              <validation-provider
                  #default="{ errors }"
                  :name="msg('username')"
                  rules="required">
                <b-form-input
                    id="username"
                    v-model="username"
                    name="login-username"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="msg('Username')"
                    style="background-color: rgba(255,255,255,0.61)"
                    autofocus/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- password -->
            <b-form-group>

              <validation-provider
                  #default="{ errors }"
                  :name="msg('Password')"
                  rules="required">
                <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input
                      id="password"
                      v-model="password"
                      :type="passwordFieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false : null"
                      name="login-password"
                      style="background-color: rgba(255,255,255,0.61)"

                      :placeholder="msg('Password')"/>
                  <b-input-group-append is-text>
                    <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"/>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button variant="primary" type="submit" block :disabled="invalid || processing"
                      @click="submitLogin">
              <b-spinner style="margin-right: 5px; vertical-align: middle;" v-if="processing" small
                         type="grow"></b-spinner>
              <span class="align-center">{{ msg('Sign in') }}</span>
            </b-button>
          </b-form>
        </validation-observer>
      </div>
      <div v-else>
        <b-card-text class="mb-2">
          <p>{{ msg("Redirect to your account space in 5 seconds") }}</p>
        </b-card-text>
      </div>
    </div>
  </div>

</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required, email} from '@validations'
import {togglePasswordVisibility} from '@core/mixins/ui/forms'
import {mapActions, mapGetters, mapMutations} from 'vuex'
import {router} from '@/router'

export default {
  components: {

    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      username: '',
      password: '',
      status: '',
      // validation rules
      required,
      email,
      invalid: false,
      processing: false,
    }
  },
  computed: {
    ...mapGetters('user', ['isloggedIn']),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    this.setLanguage(null);
    // reset login status
    this.redirect('/')
  },
  methods: {
    ...mapActions('user', ['login']),
    ...mapMutations('translator', ['setLanguage']),
    ...mapActions('notifications', {notifyError: 'error'}),
    submitLogin() {
      let $this = this;
      console.log("submitLogin")
      $this.processing = true;
      let p = this.login({username: $this.username, password: $this.password})
      p.then(
          function (redirect) {
            $this.redirect(redirect);
          }, (e) => {
            $this.notifyError({title: "login", message: e})
          });
      p.finally(() => $this.processing = false);
    },
    redirect(defaultRedirect) {
      let url = new URL(window.location.href);
      let returnUrl = url.searchParams.get("returnUrl");
      if (returnUrl != null && returnUrl !== "") {
        returnUrl = returnUrl.trim();
        //even that vue check for xss attack we made a double check
        //remove all http or other protocols
        returnUrl = returnUrl.replace("http://", "").replace("https://", "").replace("://", "");
        //check for points
        returnUrl = returnUrl.replace(".", "");
        router.push(returnUrl)
      } else
        router.push(defaultRedirect)
    }
  }
}
</script>

<style lang="scss">

html, body {
  height: 100%;
  width: 100%;
}

@import '@core/scss/vue/pages/page-auth.scss';
</style>
