import api from "../../../services/api.service"


export const bankModule = {
    namespaced: true,
    state: {
        data: {types: [], categories: []},
    },
    getters: {
        canReadRealEstate(state, getters, rootState, rootGetters) {
            return rootGetters['user/canRead']('real_estate');
        },
        canEditRealEstate(state, getters, rootState, rootGetters) {
            return rootGetters['user/canEdit']('real_estate');
        },
        getAllCategories(state) {
            if (state.data == null || state.data.categories == null)
                return [];
            return state.data.categories;
        },

        getAllTypes(state) {
            if (state.data == null || state.data.types == null)
                return [];
            return state.data.types;
        },
        allStatusNoActive() {
            return [
                {value: "NEW", text: 'NEW'},
                {value: 'EXPOSE', text: 'EXPOSE'},
                {value: 'DISABLED', text: 'DISABLED'}
            ]
        },
        allStatus() {
            return [
                {value: "NEW", text: 'NEW'},
                {value: 'EXPOSE', text: 'EXPOSE'},
                {value: 'ACTIVE', text: 'ACTIVE'},
                {value: 'DISABLED', text: 'DISABLED'}
            ]
        },


    },
    mutations: {
        updateData(state, data) {
            let stateData = {};
            stateData.loaded = true;
            stateData.categories = data["Categories"];
            stateData.types = data["Types"];
            state.data = stateData;
        }
    },
    actions: {

        copySuccess(actions, id) {
            actions.dispatch('notifications/success',
                {title: 'Success', message: 'Bank  ID ' + id + ' copied'},
                {root: true});

        },

        uploadExcel(actions, {file}) {
            return new Promise(function (resolve, reject) {
                api.uploadFile('api/excel', file, "uploadFileAndInsertData", {table: "Bank"}).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Uploading file', message: 'File uploaded successfully'},
                            {root: true});
                        resolve();
                    }, (error) => {
                        actions.dispatch('notifications/error',
                            {
                                title: 'Uploading file',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            });
        },
        uploadExcelClient(actions, {fileClient, params}) {
            return new Promise(function (resolve, reject) {
                api.uploadFile('api/excel', fileClient, "uploadFileAndInsertData",
                    {table: "BankClient", params: btoa(JSON.stringify(params || {}))}).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Uploading file', message: 'File uploaded successfully'},
                            {root: true});
                        resolve();
                    }, (error) => {
                        actions.dispatch('notifications/error',
                            {
                                title: 'Uploading file',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            });
        },
        getAllBanks(actions, {page, amount, filter, sortcolumn, sortby}) {

            return new Promise(function (resolve, reject) {
                    api.sendRequest('bank/get', {
                        buids: actions.rootGetters['data/getSelectedBranchesIds'].join(","),
                        page,
                        amount,
                        sortcolumn,
                        sortby
                    }, filter).then(
                        (ans) => {
                            if (ans["Banks"] == null)
                                return reject('Could not get Banks ');

                            resolve(ans);
                        }, (error) => {
                            reject(error.message)
                        })
                })

        },
        getBankList() {

            return new Promise(function (resolve, reject) {
                api.sendRequest('bank/getBankList', null, null).then(
                    (ans) => {
                        if (ans["Banks"] == null)
                            return reject('Could not get bankList ');

                        resolve(ans["Banks"]);
                    }, (error) => {

                        reject(error.message)
                    })
            })

        },

        getBankById(actions, id) {
            return new Promise((resolve, reject) => {

                api.sendRequest('bank/id/' + id, {mortgages:"true"}).then(
                    (ans) => {

                        if (ans["Bank"] == null)
                            return reject('Could not get bank ');

                        resolve(ans["Bank"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },

        copyBank(actions, {bank,buid}) {
            return new Promise((resolve, reject) => {

                api.sendRequest('bank/copy', {bank,buid}).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Copy bank', message: 'Bank copied successfully'},
                            {root: true});

                        if (ans["BankId"] == null)
                            return reject('Could not copy bank ');

                        resolve(ans["BankId"]);
                    }, (error) => {
                        actions.dispatch('notifications/error',
                            {
                                title: 'Copy Bank',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)                    })
            })
        },
        updateBank(actions, bank) {
            return new Promise((resolve, reject) => {

                api.sendRequest('bank/update', null, bank).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Update bank', message: 'Bank updated successfully'},
                            {root: true});

                        if (ans["BankId"] == null)
                            return reject('Could not update bank ');

                        resolve(ans["BankId"]);
                    }, (error) => {
                        actions.dispatch('notifications/error',
                            {
                                title: 'Edit Bank',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)                    })
            })
        },
        addBank(actions, bank) {
            return new Promise((resolve, reject) => {
                api.sendRequest('bank/add', null, bank).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Add bank', message: 'Bank created successfully'},
                            {root: true});

                        if (ans["BankId"] == null)
                            return reject('Could not create bank ');

                        console.log("bank answer created: ", ans)
                        resolve(ans["BankId"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        }

    },
};
