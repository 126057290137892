import {vue} from '../../main'

function getBranches(branches){
    try {
        let cachedBranches =  JSON.parse(sessionStorage.getItem('selectedBranches'));
        console.log("getBranches() cachedBranches", {cachedBranches, branches})
        if (!Array.isArray(cachedBranches)) {
            return branches;
        }
        return cachedBranches.filter(b => branches.includes(b));
    }catch (e) {
        console.error("getBranches()",e)
        return branches;
    }
}


const dataModule = {
    namespaced: true,
    state: {
        affiliates: {},
        branches: {},
        selectedBranches: [],
        languages: {},
        organization: {},
        serverTimeDiff: 0,
        users: {},
        countries: {},
        selectedBranchId: 0,
        roles: null,
        admin: null,
        groups:{},
        adminRoleId:0
    },
    mutations: {
        update(state, data) {
            console.log("data.module->update()", data)
            if (data.affiliates != null)
                state.affiliates = data.affiliates;
            if (data.branches != null) {
                state.branches = data.branches;
                if(state.selectedBranches == null || state.selectedBranches.length === 0) {
                    state.selectedBranches = getBranches(Object.values(data.branches).map(b => b.id));
                }
            }
            if (data.languages != null)
                state.languages = data.languages;
            if (data.organization != null) {
                state.organization = data.organization;
                document.title = state.organization.name + " - CRM";
            }
            if (data.serverTimeDiff != null)
                state.serverTimeDiff = data.serverTimeDiff;
            if (data.users != null)
                state.users = data.users;
            if (data.countries != null)
                state.countries = data.countries;

            if (data.userId != null)
                state.userId = data.userId;
            if (data.roles != null)
                state.roles = data.roles;
            if (data.admin != null)
                state.admin = data.admin;

            if (data.BuGroups != null)
                state.groups = data.BuGroups;

            if (data.adminRoleId != null)
                state.adminRoleId = data.adminRoleId;



        },
        setSelectedBranches(state, selected) {

            if (selected == null)
                return state.selectedBranches = [];

            if (!Array.isArray(selected)) {
                console.error("Invalid branches", selected)
                return;
            }
            state.selectedBranches = selected;
            sessionStorage.setItem('selectedBranches', JSON.stringify(selected));
            vue.$root.$emit("crm::branch-updated");
        }
    },
    getters: {
        getAllUsers(states) {
            return Object.values(states.users);
        },
        getSelectedBranchesIds(state){

            if (!Array.isArray(state.selectedBranches))
                return [];
            return state.selectedBranches;

        },
        getGroups(state){
            if( state.groups == null)
                return {};
            return state.groups;
        },
        getAdminRoleId(state){
            if( state.adminRoleId == null)
                return 0;
            return state.adminRoleId;
        },
        getGroupById:(state)=>(groupid)=>{
            if(state.groups == null)
                return "";
            let group = state.groups[groupid];

            if(group == null)
                return "";

            return group
        },
        getOtherUsers(state, getters, rootState, rootGetters) {
            let users = {...state.users};
            delete users[rootGetters['user/myId']];
            return Object.values(users);
        },
        getUser: (state) => (uId) => {
            if (uId == null)
                return {};
            let u = state.users[uId];
            return (u == null) ? {} : u;
        },
        getUsersInTeam(state, getters) {
            if (state == null || state.roles == null || state.users == null)
                return [];

            let roles = getters["getTeamRoles"].map(r => r.id);
            if (roles == null)
                return [];

            return Object.values(state.users).filter(u => roles.includes(u.roleId))

        },
        getUsersByRole: (state) => (roleId) => {
            if (roleId == null || state.users == null)
                return [];
            return Object.values(state.users).filter(u => u.roleId === roleId);
        },
        getUsersNotByRole: (state) => (roleId) => {
            if (roleId == null || state.users == null)
                return [];
            return Object.values(state.users).filter(u => u.roleId !== roleId);
        },

        getUserFullName: (state) => (uId) => {
            if (uId == null)
                return '';
            let u = state.users[uId];
            if(u == null)
                return "";
            let fn = u.firstname || "";
            let ln = u.lastname || "";

            return fn + " " + ln;
        },
        getFullName: (state) => (user) => {
            if (user == null)
                return '';
            let fn = user.firstname || "";
            let ln = user.lastname || "";
            return fn + " " + ln;
        },
        getUserAddress: (state) => (uId) => {
            if (uId == null)
                return '';
            let u = state.users[uId];
            if (u == null)
                return "";
            if (u.address == null || u.address.trim() == "")
                return "";
            if (u.city == null || u.city.trim() == "")
                return u.address;
            return u.address + ", " + u.city;
        },
        getUserFirstName: (state) => (uId) => {
            if (uId == null)
                return {};
            let u = state.users[uId];
            return (u == null) ? "" : (u.firstname);
        },
        getUserLastName: (state) => (uId) => {
            if (uId == null)
                return {};
            let u = state.users[uId];
            return (u == null) ? "" : (u.lastname);
        },
        getUserAcronyms: (state) => (uId,points) => {
            if (uId == null)
                return "";
            let u = state.users[uId] || {};
            let fn = u.firstname || "";

            return (u == null) ? "" : (fn.charAt(0).toUpperCase() + (points == true ? "." : "") + (u.lastname != null && u.lastname.trim() !== "" ? u.lastname.charAt(0).toUpperCase() + (points == true ? "." : "") :""));
        },
        getUserColor: (state) => (uId) => {
            if (uId == null)
                return {};
            let u = state.users[uId];
            return (u == null) ? "" : (u.color);
        },
        getUserRole: (state) => (uId) => {
            if (uId == null)
                return {};
            let u = state.users[uId];
            return (u == null) ? 0 : u.roleId;
        },
        getRoles(state) {
            if (state == null || state.roles == null)
                return {};
            return state.roles;
        },
        getTeamRoles(state) {
            if (state == null || state.roles == null)
                return {};
            return Object.values(state.roles).filter(r => r.team === true);
        },
        getRole: (state) => (rId) => {
            if (state == null || state.roles == null || state.roles[rId] == null)
                return "";
            return state.roles[rId];
        },
        getRoleName: (state) => (rId) => {
            if (state == null || state.roles == null || state.roles[rId] == null)
                return "";
            return state.roles[rId].name;
        },
        getOrganization(state) {
            if (state.organization == null)
                return null;
            return state.organization;
        },
        defaultCountry(state) {
            if (state.organization == null)
                return null;
            return state.organization.country;
        },
        defaultLang(state) {
            if (state.organization == null)
                return null;
            return state.organization.lang;
        },
        orgHasJewishCalendar(state) {
            if (state.organization == null)
                return false;
            return state.organization.hebCalendar;
        },
        isCoachingOrg(state) {
            if (state.organization == null)
                return false;
            return state.organization.type === 1 || (Array.isArray(state.organization.typeParents) && state.organization.typeParents.findIndex(t => t === 1) >= 0);
        },
        isEnergyOrg(state) {
            if (state.organization == null)
                return false;
            return state.organization.type === 2 || (Array.isArray(state.organization.typeParents) && state.organization.typeParents.findIndex(t => t === 2) >= 0);
        },
        isIsolationOrg(state) {
            if (state.organization == null)
                return false;
            return state.organization.type === 3 || (Array.isArray(state.organization.typeParents) && state.organization.typeParents.findIndex(t => t === 3) >= 0);
        },
        isIsolationProOrg(state) {
            if (state.organization == null)
                return false;
            return state.organization.type === 4 || (Array.isArray(state.organization.typeParents) && state.organization.typeParents.findIndex(t => t === 4) >= 0);
        },
        isPompeOrg(state) {
            if (state.organization == null)
                return false;
            return state.organization.type === 5 || (Array.isArray(state.organization.typeParents) && state.organization.typeParents.findIndex(t => t === 5) >= 0);
        },
        getOrganizationWebSite(state) {
            if (state.organization == null || state.organization.website == null)
                return "";
            return state.organization.website;
        },
        getRealEstatesWebSite(state) {
            if (state.organization == null || state.organization.realEstateWebsite == null)
                return "";
            console.log("----------org",state.organization)
            return state.organization.realEstateWebsite;
        },
        getAllBranches: (state) => {
            if (state.branches == null)
                return {};

            return state.branches;
        },
        getAllBranchesByGroup: (state) => {
            if (state.branches == null)
                return {};
            return Object.values(state.branches).reduce((branches, branch) => {
                if(branches[branch.group] == null)
                    branches[branch.group] = [];
                branches[branch.group].push(branch);
                return branches;
            },{})
        },
        getBranch: (state) => (bId) => {
            if (state.branches == null || state.branches[bId] == null)
                return {};

            return state.branches[bId];
        },
        getBranchName: (state) => (bId) => {
            if (state.branches == null || state.branches[bId] == null)
                return "";

            return state.branches[bId].name;
        },
        currentBranch: (state) => {
            if (state.branches == null || state.branches[state.selectedBranchId] == null)
                return {};

            return state.branches[state.selectedBranchId];
        },
        currentBranchName: (state) => {
            if (state.branches == null || state.branches[state.selectedBranchId] == null)
                return "";

            return state.branches[state.selectedBranchId].name;
        },
        currentBranchCurrency: (state) => {
            if (state.branches == null || state.branches[state.selectedBranchId] == null)
                return "";

            return state.branches[state.selectedBranchId].cur.toLowerCase();
        },
        currentBranchPaymentLink: (state) => {
            if (state.branches == null || state.branches[state.selectedBranchId] == null)
                return "";

            return state.branches[state.selectedBranchId].externalLink;
        },
        currencySymbol:(state) => (cur)=> {

            if(cur == null)
                return  "";

        },
        getAllLanguage(state) {
            if (state.languages == null)
                return {};

            return state.languages;
        },
        getAllAffiliate(state) {
            if (state.affiliates == null)
                return {};

            return state.affiliates;
        },
        getLanguage: (state) => (id) => {
            if (state.languages[id] == null)
                return {};

            return state.languages[id];
        },
        getAllCountries(state) {
            if (state.countries == null)
                return {};
            return state.countries;
        },
        getCountry: (state) => (id) => { // iso
            if (state.countries[id] == null)
                return {};
            return state.countries[id];
        },
        getBanners(state) {
            return function (affId, camId) {
                if (typeof affId !== "number" || affId <= 0 || typeof camId !== "number" || camId <= 0)
                    return {};
                if (typeof state.affiliates !== "object" || typeof state.affiliates[affId] !== "object" || typeof state.affiliates[affId]['campaigns'] !== "object")
                    return {};
                let camps = state.affiliates[affId]['campaigns'];
                if (typeof camps[camId] !== "object" || typeof camps[camId]['banners'] !== "object")
                    return {};
                return camps[camId]['banners'];
            }
        },
        getCampaignByAffId(state) {
            return function (aId) {
                if (typeof aId !== "number" || aId <= 0)
                    return {};
                if (typeof state.affiliates !== "object" || typeof state.affiliates[aId] !== "object" || typeof state.affiliates[aId]['campaigns'] !== "object")
                    return {};
                return state.affiliates[aId]['campaigns'];
            }
        },
        getAffiliateIdByCampaignId: (state) => (cId) => {

            if (typeof cId !== "number" || cId <= 0)
                return 0;

            console.log("getAffiliateIdByCampaignId(): searching");
            let aff = Object.values(state.affiliates).find(function (aff) {

                if (typeof aff['campaigns'] !== "object")
                    return false;

                return Object.values(aff['campaigns']).findIndex((c) => c.id == cId) >= 0;
            });

            return aff != null ? aff.id : 0;
        },
        getAffiliateIdByBannerId: (state) => (bId) => {
            let res = {affiliateId: 0, campaignId: 0, bannerId: 0};

            if (typeof bId !== "number" || bId <= 0)
                return res;

            console.log("getAffiliateIdByBannerId(): searching");
            Object.keys(state.affiliates).forEach(function (a) {

                let aff = state.affiliates[a];
                if (typeof aff['campaigns'] !== "object")
                    return;

                if (res.affiliateId !== 0) return true;

                Object.keys(aff['campaigns']).forEach(function (c) {

                    let cam = aff['campaigns'][c];
                    if (typeof cam['banners'] !== "object")
                        return;

                    if (res.affiliateId !== 0) return true;


                    Object.keys(cam["banners"]).forEach(function (b) {
                        let ban = cam["banners"][b];
                        if (typeof cam["banners"][b] !== "object" || ban.id !== bId)
                            return;

                        res.affiliateId = aff.id;
                        res.campaignId = cam.id;
                        res.bannerId = ban.id;

                        return true;
                    })
                })
            });


            return res;
        },
        canReadUsers(state, getters, rootState, rootGetters) {
            return rootGetters['user/canRead']('user');
        },
        newServerTime: function (state) {
            return function (time) {
                return new Date((time != null ? time : Date.now()) + state.serverTimeDiff);
            }
        },
        currentServerTimeSpam(state) {
            return function (time) {
                return (time != null ? time : Date.now()) + state.serverTimeDiff;
            }
        },
        parseServerTime: function (state) {
            return function (time) {
                let parsed = Date.parse(time);
                console.log("parseServerTime() time=" + time + ", parsed= " + parsed)
                return (!isNaN(parsed) ? (parsed + state.serverTimeDiff) : parsed);
            }
        },
        parseServerTimeToLocal: function (state) {
            return function (time) {
                let parsed = Date.parse(time);
                return (!isNaN(parsed) ? (parsed - state.serverTimeDiff) : parsed);
            }
        },
        getAllCampaigns(state) {
            if (state.affiliates == null)
                return [];

            return Object.values(state.affiliates).reduce((ans, aff) => {
                ans = [...ans, ...(Object.values(aff.campaigns).reduce((arr, cam) => {
                    arr.push(cam);
                    return arr;
                }, []))];
                return ans;
            }, []);

        },
        isCompanyClients(state, getters) {
            let bu = getters['currentBranch'];
            if (bu.id != null)
                return bu['companyClients'];
            return Object.values(getters['getAllBranches']).some(bu => bu['companyClients'] == true)
        },
        getSettings(state, getters) {
            let bu = getters['currentBranch'];
            if (bu.id != null)
                return bu.settings;

            let org = getters['getOrganization'];
            if (org == null)
                return null;
            return org.settings;


        },
        clientTableFieldActive: (state, getters) => (field, ifNull) => {
            if (ifNull == null)
                ifNull = true;
            let settings = getters["getSettings"];
            if (settings == null)
                return ifNull;
            if (settings['client-table-' + field.toLowerCase()] == null)
                return ifNull;
            return settings['client-table-' + field.toLowerCase()] !== '0' && settings['client-' + field.toLowerCase()] !== '0';
        },
        clientFieldActive: (state, getters) => (field, ifNull) => {
            if (ifNull == null)
                ifNull = true;
            console.log("clientFieldActive():", {field, ifNull})
            let settings = getters["getSettings"];
            if (settings == null)
                return ifNull;
            if (settings['client-' + field.toLowerCase()] == null)
                return ifNull;

            if (settings['client-' + field.toLowerCase()] == null)
                return ifNull;


            return settings['client-' + field.toLowerCase()] !== '0';
        },
        role: (state, getters) => (field) => {

            let settings = getters["getSettings"];
            if (settings == null)
                return 0;

            return settings['role-' + field.toLowerCase()];
        },
        alias: (state, getters) => (field) => {

            let settings = getters["getSettings"];
            if (settings == null)
                return null;

            return settings['alias-' + field.toLowerCase()];
        },

        getAdmin(state) {
            return state.admin;
        },
    },
    actions: {
        onExternalMsg(actions, {origin, data}) {
            console.log("External Message from: " + origin + ", data: " + data)

            if (typeof data != "object") {
                data = {event: data}
            }
            switch (data.event) {
                case "payment":
                    //TODO: check origin with external payment link
                    vue.$root.$emit("crm::monetary::payment");
                    break;
                default:
                    console.log("Unknown message " + data.event)
            }
        }
    }
};

export default dataModule;
