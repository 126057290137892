import api from "../../../services/api.service"


export const nukiSmartLockModule = {
    namespaced: true,
    state: {},
    getters: {
        canReadNuki(state, getters, rootState, rootGetters) {
            return rootGetters['user/canRead']('nuki');
        },
        canEditNuki(state, getters, rootState, rootGetters) {
            return rootGetters['user/canEdit']('nuki');
        },
        nukiStatus() {
            return [
                {value: "INSTALLED", text: 'INSTALLIERT'},
                {value: 'TOINSTALL', text: 'NUKI NACHRÜSTEN'},
                {value: 'FAULT', text: 'NUKI FEHLER'},

            ]
        },

    },
    mutations: {},
    actions: {
        getData() {
            return new Promise(function (resolve) {

                resolve();

            })
        },

        getAllNukis(actions, {page, amount, filter, sortcolumn, sortby}) {

            return new Promise(function (resolve, reject) {
                api.sendRequest('nukiSmartLock/get', {page, amount, sortcolumn, sortby}, filter).then(
                    (ans) => {
                        if (ans["Nukis"] == null)
                            return reject('Could not get nukis ');

                        resolve(ans);
                    }, (error) => {

                        reject(error.message)
                    })
            })

        },
        sendMail(actions,{emailAddress,propertyId}){

            return new Promise(function (resolve, reject) {
                api.sendRequest('nukiSmartLock/sendMail',
                    {emailAddress,propertyId}, null).then(
                    (ans) => {
                        if (ans["email"] == null)
                            return reject('Could not send email ');

                        actions.dispatch('notifications/success',
                            {title: 'Email nuki installation', message: 'Email sent successfully'},
                            {root: true});

                        resolve(ans["email"]);
                    }, (error) => {

                        actions.dispatch('notifications/error',
                            {
                                title: 'Getting data',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })

        },
        getNukiList() {

            return new Promise(function (resolve, reject) {
                api.sendRequest('nukiSmartLock/getNukiList', null, null).then(
                    (ans) => {
                        if (ans["Nukis"] == null)
                            return reject('Could not get nukis ');

                        resolve(ans["Nukis"]);
                    }, (error) => {

                        reject(error.message)
                    })
            })

        },

        getNukiById(actions, id) {
            return new Promise((resolve, reject) => {

                api.sendRequest('nukiSmartLock/id/' + id, {}).then(
                    (ans) => {

                        if (ans["nuki"] == null)
                            return reject('Could not get nukiSmartLock ');

                        resolve(ans["nuki"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },

        updateNuki(actions, nuki) {
            return new Promise((resolve, reject) => {

                api.sendRequest('nukiSmartLock/update', null, nuki).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Update nuki', message: 'Nuki updated successfully'},
                            {root: true});

                        if (ans["nukiId"] == null)
                            return reject('Could not update nukiSmartLock ');

                        resolve(ans["nukiId"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        createNuki(actions, nuki) {
            return new Promise((resolve, reject) => {


                api.sendRequest('nukiSmartLock/add', null, nuki).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Add nuki', message: 'Nuki added successfully'},
                            {root: true});

                        if (ans["nukiId"] == null)
                            return reject('Could not create nukiSmartLock ');

                        resolve(ans["nukiId"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },

        lockNukiSmartLock(actions, id) {
            return new Promise((resolve, reject) => {

                if (isNaN(id))
                    reject("Invalid id");

                api.sendRequest('nukiSmartLock/lock/' + id, {}).then(
                    (ans) => {

                        if (ans["nuki"] == null)
                            return reject('Could not lock nukiSmartLock ');

                        resolve(ans["nuki"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },

        unlockNukiSmartLock(actions, id) {
            return new Promise((resolve, reject) => {

                if (isNaN(id))
                    reject("Invalid id");

                api.sendRequest('nukiSmartLock/unlock/' + id, {}).then(
                    (ans) => {

                        if (ans["nuki"] == null)
                            return reject('Could not unlock nukiSmartLock ');

                        resolve(ans["nuki"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
    },
};
