import api from "../../../services/api.service"


export const campaignModule = {
    namespaced: true,
    state: {},
    getters: {
        canReadCampaign(state, getters, rootState, rootGetters) {
            return rootGetters['user/canRead']('campaign');
        },
        canEditCampaign(state, getters, rootState, rootGetters) {
            return rootGetters['user/canEdit']('campaign');
        },

    },
    mutations: {

    },
    actions: {
        getData() {
            return new Promise(function (resolve) {

                resolve();

            })
        },

        getAllCampaigns(actions) {

            return new Promise(function (resolve, reject) {
                actions.dispatch('getData', false).finally(() => {
                    api.sendRequest('campaigns/getAll', {

                        buids: actions.rootGetters['data/getSelectedBranchesIds'].join(","),


                    }).then(
                        (ans) => {
                            if (ans["campaigns"] == null)
                                return reject('Could not get campaigns ');

                            console.log("getAllCampaings",ans["campaigns"])
                            resolve(ans["campaigns"]);
                        }, (error) => {

                            reject(error.message)
                        })
                })

            })
        },

        getCampaignById(actions,id){
            return new Promise((resolve, reject) => {

                if(isNaN(id))
                    reject("Invalid id");

                api.sendRequest('campaigns/get/'+id, {}).then(
                    (ans) => {

                        if (ans["campaign"] == null)
                            return reject('Could not get campaigns ');

                        resolve(ans["campaign"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },

        changeCampaignStatus(actions,campaign){
            return new Promise((resolve, reject) => {

                api.sendRequest('campaigns/status',null,campaign ).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Update campaign status', message: 'Campaign updated successfully'},
                            {root: true});

                        if (ans["campaign"] == null)
                            return reject('Could not update campaign ');

                        resolve(ans["campaign"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },  updateCampaign(actions,campaign){
            return new Promise((resolve, reject) => {

                api.sendRequest('campaigns/update',null, campaign).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Update campaign', message: 'Campaign updated successfully'},
                            {root: true});

                        if (ans["campaign"] == null)
                            return reject('Could not update campaign ');

                        resolve(ans["campaign"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        createCampaign(actions,campaign){
            return new Promise((resolve, reject) => {


                api.sendRequest('campaigns/create', null,campaign).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Add campaign', message: 'Campaign created successfully'},
                            {root: true});

                        if (ans["campaign"] == null)
                            return reject('Could not create campaign ');

                        resolve(ans["campaign"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },

        getDevicesByCampaign(actions, campaignid) {

            return new Promise(function (resolve, reject) {
                api.sendRequest('campaigns/devices/campaign/get/'+campaignid, {}).then(
                    (ans) => {
                        if (ans["devices"] == null)
                            return reject('devices failed to refresh');
                        resolve(ans["devices"]);
                    }, (error) => {
                        reject(error.message)
                    })

            })
        },
        setCampaignDevices(actions, {campaignid,deviceIds}) {

            return new Promise(function (resolve, reject) {
                if(!Array.isArray(deviceIds)){
                    return reject("Invalid devices");
                }
                api.sendRequest('campaigns/devices/campaign/set/'+campaignid, {
                    id:campaignid,
                    device:deviceIds.join(","),
                }).then(
                    (a) => {
                        actions.dispatch('notifications/success',
                            {title: 'Update devices', message: 'Devices updated successfully'},
                            {root: true});
                        resolve(a['devices']);
                    }, (error) => {
                        actions.dispatch('notifications/Error',
                            {title: 'Update devices', message: 'Could not update devices for campaign'},
                            {root: true});
                        reject(error.message)
                    })

            })
        }
    },
};
