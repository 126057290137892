import api from "../../services/api.service"


export const clientsModule = {
    namespaced: true,
    state: {
        data: {},


    },
    getters: {
        isDataLoaded(state) {
            return state.data.loaded === true;
        },
        getAllStatus(state) {
            if (state.data == null || state.data.status == null)
                return {};
            return state.data.status;
        },
        getAllStatusEventActions(state) {
            if (state.data == null || state.data.eventactions == null)
                return {};
            return state.data.eventactions;
        },
        getAllMailevents(state) {
            if (state.data == null || state.data.mailevents == null)
                return [];
            return state.data.mailevents;
        },
        getStatus: (state) => (stId) => {
            if (state.data == null || state.data.status == null || state.data.status[stId] == null)
                return {};
            return state.data.status[stId]
        },
        getStatusIcon: (state) => (stId) => {
            if (state.data == null || state.data.status == null || state.data.status[stId] == null || state.data.status[stId].icon == null)
                return null;
            return state.data.status[stId].icon;
        },
        getStatusName: (state) => (stId) => {
            if (state.data == null || state.data.status == null || state.data.status[stId] == null)
                return "";
            return state.data.status[stId].status;
        },
        getStatusColor: (state) => (stId) => {
            if (state.data == null || state.data.status == null || state.data.status[stId] == null || state.data.status[stId].color == null)
                return null;
            return state.data.status[stId].color
        },
        getColumns(state) {
            if (state.data == null || state.data.columns == null)
                return {};
            return state.data.columns;
        },
        hasTag(state, getters, rootState, rootGetters) {
            return rootGetters['data/clientTableFieldActive']('tag', false);
        },
        hasCreationtime(state, getters, rootState, rootGetters) {
            return rootGetters['data/clientTableFieldActive']('creationtime', false);
        },
        hasStatus(state, getters, rootState, rootGetters) {
            return rootGetters['data/clientTableFieldActive']('statusid', true);
        },

        roleInstalIs(state, getters, rootState, rootGetters) {
            let role = rootGetters['data/role']('instal');
            if (role == null)
                return 0;
            return parseInt(role);
        },
        roleConfirmIs(state, getters, rootState, rootGetters) {
            let role = rootGetters['data/role']('confirm');
            if (role == null)
                return 0;
            return parseInt(role);
        },
        roleCoordIs(state, getters, rootState, rootGetters) {
            let role = rootGetters['data/role']('coord');
            if (role == null)
                return 0;
            return parseInt(role);
        },

        rep3Alias(state, getters, rootState, rootGetters) {
            let name = rootGetters['data/alias']('rep3');
            if (name == null)
                return 'Representative3';
            return name;
        },
        groupAlias(state, getters, rootState, rootGetters) {
            let name = rootGetters['data/alias']('group');
            if (name == null)
                return 'Group';
            return name;
        },
        branchAlias(state, getters, rootState, rootGetters) {
            let name = rootGetters['data/alias']('bu');
            if (name == null)
                return 'Branches';
            return name;
        },
        roleCommIs(state, getters, rootState, rootGetters) {
            let role = rootGetters['data/role']('commercial');
            if (role == null)
                return 0;
            return parseInt(role);
        },
        hasAvatar(state, getters, rootState, rootGetters) {
            return rootGetters['data/clientTableFieldActive']('avatar', true);
        },
        hasCalendarWeek(state, getters, rootState, rootGetters) {
            return rootGetters['data/clientTableFieldActive']('week', true);
        },
        canEdit(state, getters, rootState, rootGetters) {
            return rootGetters['user/canEdit']('client');
        },
        canEditRep(state, getters, rootState, rootGetters) {
            return rootGetters['user/canEdit']('client_rep');
        },
        canOperateRep(state, getters, rootState, rootGetters) {
            return rootGetters['user/canOperate']('client_rep');
        },

        canReadPhone(state, getters, rootState, rootGetters) {
            return rootGetters['user/canRead']('client_phone');
        },
        canEditPhone(state, getters, rootState, rootGetters) {
            return rootGetters['user/canEdit']('client_phone');
        },
        canReadEmail(state, getters, rootState, rootGetters) {
            return rootGetters['user/canRead']('client_email');
        },
        canEditEmail(state, getters, rootState, rootGetters) {
            return rootGetters['user/canEdit']('client_email');
        },
        canEditSuivi(state, getters, rootState, rootGetters) {
            return rootGetters['user/canRead']('editableUpcoming');

        }
    },
    mutations: {
        updateData(state, data) {
            console.log("clientsModule ->updateData", data)
            let stateData = {};
            stateData.loaded = true;
            stateData.status = data["ClientStatus"];
            if (data["ClientColumns"] != null) {
                let columns = data["ClientColumns"];
                stateData.columns = Object.keys(columns).reduce((obj, column_field) => Object.assign(obj, {[column_field]: columns[column_field]["active"]}), {});
            }
            stateData.mailevents = data["Mailevents"];
            stateData.eventactions = data["ClientStatusActionEvents"];
            state.data = stateData;
        }
    },
    actions: {
        loadData({state, dispatch}) {
            return new Promise(function (resolve, reject) {
                if (state.data.loaded === true)
                    return resolve();

                if (Object.keys(state.data).length === 0 && state.data.constructor === Object) {
                    dispatch('getData').then(() => resolve(), (e) => reject(e));
                } else
                    return resolve();
            });
        },
        getData({state, commit, dispatch}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('client/data').then(
                    (ans) => {

                        commit('updateData', ans);
                        resolve(state);
                    }, (error) => {
                        dispatch('notifications/error',
                            {
                                title: 'Getting data',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        getClients(actions, {page, amountPerPage, filter, sortby, sortcolumn}) {

            if (typeof filter !== "object")
                filter = {};

            return new Promise(function (resolve, reject) {
                api.sendRequest('client/get',
                    {
                        amount: (amountPerPage == null ? 20 : amountPerPage),
                        page: page == null ? 0 : page,
                        buids: actions.rootGetters['data/getSelectedBranchesIds'].join(","),
                        sortby,
                        sortcolumn,
                    },filter).then(
                    (ans) => {

                        if (ans["Clients"] == null)
                            return reject('Clients failed to refresh');

                        let clientsData = {clients: ans["Clients"], page: {},}
                        clientsData.page.amountPerPage = ans["Amount"];
                        clientsData.page.total = ans["Total"];
                        clientsData.page.number = ans["Page"];

                        resolve(clientsData);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },

        //Get a client
        get(actions, {id}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('client/id/'+id).then(
                    (ans) => {
                        resolve({client: ans.Client, appointments: ans.Appointments})
                    }, (error) => {

                        reject(error.message)
                    })
            })
        },
        update({dispatch}, {id, client}) {
            return new Promise(function (resolve, reject) {
                //Check if client is not undefined
                if (typeof client !== "object")
                    reject("Invalid client");
                //generate a new object in order to not update the client object and add the field action
                let body = {...client};
                //In order to prevent a bug or a mistake, the id must be send
                body['id'] = id;
                body['account'] = null;
                body['client2Users'] = null;

                api.sendRequest('client/update',null, body).then(
                    (ans) => {
                        //In case of success send notification
                        dispatch('notifications/success',
                            {title: {text:'%s updated',arguments:['client']}, message:{text:'%s updated successfully',arguments:['Client']} },
                            {root: true});

                        //update table (in order to see the changes)
                        // dispatch('refresh', {page: state.page.number, amountPerPage: state.page.amountPerPage});
                        //send error
                        resolve(ans)
                    }, (error) => {
                        dispatch('notifications/error',
                            {
                                title: {text:'%s updated',arguments:['client']},
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },

        register({dispatch}, {client}) {
            return new Promise(function (resolve, reject) {
                //Check if client is not undefined
                if (typeof client !== "object")
                    return reject("Invalid client");

                console.log("register of client vuex ",client)
                api.sendRequest('client/add',null,client).then(
                    (ans) => {
                        //In case of success send notification
                        dispatch('notifications/success',
                            {title: {text:'Register %s',arguments:['client']}, message:{text:'%s created successfully with id: %s',arguments:['Client',ans.ClientId]} },
                            {root: true});

                        //update table (in order to see the changes)
                        //dispatch('refresh', {page: state.page.number, amountPerPage: state.page.amountPerPage});
                        //send error
                        resolve(ans["ClientId"])
                    }, (error) => {
                        dispatch('notifications/error',
                            {
                                title: {text:'Register %s',arguments:['client']},
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        setRepresentativeToAccount({dispatch}, {rep}) {
            return new Promise(function (resolve, reject) {
                //Check if client is not undefined
                if (typeof rep !== "object")
                    reject("Invalid client");
                api.sendRequest('client/client2user', null,rep).then(
                    (ans) => {
                        //In case of success send notification
                        dispatch('notifications/success',
                            {
                                title: {text:'Register %s',arguments:['representative to client']},
                                message: 'Representative added to account'},
                            {root: true});

                        //send error
                        resolve(ans["Account2User"])
                    }, (error) => {
                        dispatch('notifications/error',
                            {
                                title: {text:'Register %s',arguments:['representative to client']},
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        stopRepresentativeToAccount({dispatch}, rep) {
            return new Promise(function (resolve, reject) {
                //Check if client is not undefined
                if (typeof rep !== "object")
                    reject("Invalid client");
                api.sendRequest('client/stopClient2user', null,rep).then(
                    (ans) => {
                        //In case of success send notification
                        dispatch('notifications/success',
                            {title: 'representative to client', message: 'Representative finalized'},
                            {root: true});

                        //send error
                        resolve(ans["Account2User"])
                    }, (error) => {
                        dispatch('notifications/error',
                            {
                                title: 'Representative to client',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        bulkChange({dispatch}, {clientsId, statusId, buId}) {
            //Check if client is an array of ids
            if (!Array.isArray(clientsId))
                clientsId = [];

            return new Promise(function (resolve, reject) {
                api.sendRequest('client/bulk',
                    {
                        buid: buId, statusid: statusId,
                        // Print array as 1,2,3 and send request
                        bulklist: clientsId.toString().join(",")
                    }).then(
                    () => {
                        //In case of success send notification
                        dispatch('notifications/success',
                            {title: {text:'%s updated',arguments:['clients']}, message:{text:'%s updated successfully',arguments:['Clients']} },
                            {root: true});
                        //update table (in order to see the changes)
                        resolve();

                    }, (error) => {

                        dispatch('notifications/error',
                            {
                                title: {text:'%s - failed to update',arguments:['client']},
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});

                        reject(error.message)
                    })
            })
        },

        deleteClients({dispatch}, {clientsId, status}) {
            //Check if client is an array of ids
            if (!Array.isArray(clientsId))
                clientsId = [];

            return new Promise(function (resolve, reject) {
                api.sendRequest('client/deleteUndelete',
                    {
                        status: status,
                        // Print array as 1,2,3 and send request
                        bulklist: clientsId.toString().replace('[', '').replace(']', '')
                    }).then(
                    () => {
                        //In case of success send notification
                        dispatch('notifications/success',
                          {title: {text:'Delete %s',arguments:['clients']}, message:{text:'%s deleted successfully',arguments:['Clients']} },
                          {root: true});
                        resolve();

                    }, (error) => {

                        dispatch('notifications/error',
                            {
                                title: 'Bulk Error',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});

                        reject(error.message)
                    })
            })
        },
        bulkAddRepresentative({dispatch}, {clientsId, repId, from, until}) {
            //Check if client is an array of ids
            if (!Array.isArray(clientsId))
                clientsId = [];

            return new Promise(function (resolve, reject) {
                api.sendRequest('client/bulkAddRepresentative',
                    {
                        representativeid: repId,
                        // Print array as 1,2,3 and send request
                        bulklist: clientsId.toString().replace('[', '').replace(']', ''),
                        start: (from == null || from.trim() === "" ? null : from),
                        end: (until == null || until.trim() === "" ? null : until),

                    }).then(
                    () => {
                        //In case of success send notification
                        dispatch('notifications/success',
                            {title: 'Update client', message: 'Client updated successfully'},
                            {root: true});
                        //update table (in order to see the changes)
                        /* dispatch('refresh', {
                             page: 0,
                             amountPerPage: state.page.amountPerPage
                         }).then(() => resolve(ans));*/
                        resolve();

                    }, (error) => {

                        dispatch('notifications/error',
                            {
                                title: 'Bulk Error',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});

                        reject(error.message)
                    })
            })
        },
        uploadClientsFile(actions,{file, buFileUpload}) {

            console.log('uploadClientsFile', buFileUpload)

            return api.uploadFile('upload', file, "clients", {buFileUpload});
        },

        send_Mail({dispatch}, {clientid, smtp, template, mail, objecttype, objectid, userid}) {
            return new Promise(function (resolve, reject) {


                api.sendRequest('/mailevents', {
                    action: "send",
                    clientid,
                    smtp,
                    template,
                    mail,
                    objecttype,
                    objectid,
                    userid
                }).then(
                    () => {
                        //In case of success send notification
                        dispatch('notifications/success',
                            {title: 'Email', message: 'Mail sent successfully'},
                            {root: true});


                        //send error
                        resolve()
                    }, (error) => {
                        dispatch('notifications/error',
                            {
                                title: 'Sending mail client',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },

        registerClientStatus({dispatch}, clientStatus) {
            return new Promise(function (resolve, reject) {
                //Check if client is not undefined
                if (typeof clientStatus !== "object")
                    return reject("Invalid client status");
                //Reset creationTime and modified field in order to prevent a bug
                api.sendRequest('client/addClientStatus', null,{...clientStatus}).then(
                    (ans) => {
                        //In case of success send notification
                        dispatch('notifications/success',
                            {
                                title: 'Register Client status',
                                message: 'Client status created successfully with id: ' + ans.clientStatusId
                            },
                            {root: true});

                        resolve(ans)
                    }, (error) => {
                        dispatch('notifications/error',
                            {
                                title: 'Register Client status',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })

            })
        },

        updateClientStatus({dispatch}, {id, clientStatus}) {
            return new Promise(function (resolve, reject) {
                //Check if client is not undefined
                if (typeof clientStatus !== "object")
                    return reject("Invalid client status");
                if (isNaN(id))
                    return reject("Invalid client status id");
                //Reset creationTime and modified field in order to prevent a bug
                api.sendRequest('client/updateClientStatus',null,{...clientStatus, id}).then(
                    (ans) => {
                        //In case of success send notification
                        dispatch('notifications/success',
                            {
                                title: 'Register Client status',
                                message: 'Client status updated successfully with id: ' + ans.clientStatusId
                            },
                            {root: true});

                        resolve(ans)
                    }, (error) => {
                        dispatch('notifications/error',
                            {
                                title: 'Register Client status',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })

            })
        },

        updateStatus({dispatch}, {id, clientStatus}) {
            return new Promise(function (resolve, reject) {

                //Reset creationTime and modified field in order to prevent a bug
                api.sendRequest('client/updateStatus', {
                    dataStatus: clientStatus,
                    id: id,
                }).then(
                    (ans) => {
                        //In case of success send notification
                        dispatch('notifications/success',
                            {
                                title: 'Update Client status',
                                message: 'Success'
                            },
                            {root: true});

                        resolve(ans)
                    }, (error) => {
                        dispatch('notifications/error',
                            {
                                title: 'Register Client status',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })

            })
        },
        duplicate({dispatch}, {clientsId, branchId}) {
            return new Promise(function (resolve, reject) {
                //Check if client is an array of ids
                if (!Array.isArray(clientsId))
                    clientsId = [];
                let promises = [];
                clientsId.forEach(clientId => {
                    let promise = api.sendRequest('client/clone', { branchId: branchId, clientId: clientId });
                    promises.push(promise)
                });

                Promise.all(promises).then(
                  (clients) => {
                      console.log("duplicateClients()",clients);
                      //In case of success send notification
                      dispatch('notifications/success',
                        {title: {text:'%s duplicated',arguments:['clients']}, message:{text:'%s duplicated successfully',arguments:['Clients']} },
                        {root: true});
                      //update table (in order to see the changes)
                      resolve();

                  }, (error) => {

                      dispatch('notifications/error',
                        {
                            title: {text:'%s - failed to update',arguments:['client']},
                            message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                        },
                        {root: true});

                      reject(error.message)
                  });
            })
        },
        sendSms({dispatch}, sms){

            return new Promise(function (resolve, reject) {
                api.sendRequest('sms/sendToClient', null,sms
                ).then(
                    (ans) => {
                        //In case of success send notification
                        dispatch('notifications/success',
                            {
                                title: 'Sms sent successfully',
                                message: 'Success'
                            },
                            {root: true});

                        resolve(ans)
                    }, (error) => {
                        dispatch('notifications/error',
                            {
                                title: 'Sendind sms failed',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        generateToken({dispatch}, clientId) {
            return api.get('client/generate-token/' + clientId)
                .then(data=>data, error=> {
                    dispatch('notifications/error',
                        {
                            title: 'Generate token',
                            message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                        },
                        {root: true});
                    return error.message;
                });
        }

    }
};
