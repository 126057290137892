import api from '../../services/api.service'

export const organizationsModule = {
  namespaced: true,
  state: {
    data: {},
  },
  getters: {
    getTypes(state) {
      if (state.data == null || state.data.types == null) {
        return {}
      }
      return state.data.types
    },
  },
  actions: {
    getData({ state, dispatch }) {
      return new Promise(function (resolve, reject) {
        api.sendRequest('organization/data')
          .then(
            (ans) => {
              state.data.types = ans['types']
              resolve()
            }, (error) => {
              dispatch('notifications/error',
                {
                  title: 'Getting data',
                  message: (typeof error === 'object' && error.message !== undefined ? error.message : error)
                },
                { root: true })
              reject(error.message)
            })
      })
    },
    getAll({ state, dispatch }, filter) {
      if (typeof filter !== 'object') {
        filter = {}
      }
      return new Promise(function (resolve, reject) {
        dispatch('getData', false)
          .finally(() => {

              api.sendRequest('organization/get', null, filter)
                .then(
                  (ans) => {
                    if (ans['Organizations'] == null) {
                      return reject('Invalid organization')
                    }
                    resolve(ans['Organizations'])
                  }, (error) => {
                    reject(error.message)
                  })

            }
          )
      })
    },
    //Get a organization
    get(actions,  id ) {
      return new Promise(function (resolve, reject) {

          api.sendRequest('organization/id/' + id)
            .then(
              (ans) => {
                resolve(ans.Organization)
              }, (error) => {

                reject(error.message)
              })
        }
      )
    },
    update({ dispatch }, organization) {
      return new Promise(function (resolve, reject) {
        //Check if organization is not undefined
        if (typeof organization !== 'object') {
          reject('Invalid organization')
        }
        //generate a new object in order to not update the organization object and add the field action
        //In order to prevent a bug or a mistake, the id must be send
        let body = { ...organization, }
        api.sendRequest('organization/update', null, body)
          .then(
            (ans) => {
              //In case of success send notification
              dispatch('notifications/success',
                {
                  title: 'Update organization',
                  message: 'Organization updated successfully'
                },
                { root: true })

              //update table (in order to see the changes)
              //dispatch('refresh');
              //send error
              resolve(ans)
            }, (error) => {
              dispatch('notifications/error',
                {
                  title: 'Update organization',
                  message: (typeof error === 'object' && error.message !== undefined ? error.message : error)
                },
                { root: true })
              reject(error.message)
            })
      })
    },

    register({ dispatch }, organization) {
      return new Promise(function (resolve, reject) {
        //Check if organization is not undefined
        if (typeof organization !== 'object') {
          reject('Invalid organization')
        }
        api.sendRequest('organization/add', null, organization)
          .then(
            (ans) => {
              //In case of success send notification
              dispatch('notifications/success',
                {
                  title: 'Register organization',
                  message: 'Organization created successfully with id: ' + ans.OrgId
                },
                { root: true })

              resolve(ans['Organization'])
            }, (error) => {
              dispatch('notifications/error',
                {
                  title: 'Register organization',
                  message: (typeof error === 'object' && error.message !== undefined ? error.message : error)
                },
                { root: true })
              reject(error.message)
            })
      })
    },
    reload() {
      return new Promise(function (resolve, reject) {
        api.sendRequest('organization/reload')
          .then(
            (ans) => {
              resolve(ans)
            }, (error) => {

              reject(error.message)
            })
      })
    },
    setOrg(action,id){
      localStorage.base = id;
      window.location.reload();
    }
  }
}
