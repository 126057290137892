import api from "../../../services/api.service"


export const priorityClientModule = {
    namespaced: true,
    state: {
        data: {types: [], categories: []},
    },
    getters: {
        canReadRealEstate(state, getters, rootState, rootGetters) {
            return rootGetters['user/canRead']('real_estate');
        },
        canEditRealEstate(state, getters, rootState, rootGetters) {
            return rootGetters['user/canEdit']('real_estate');
        },


    },
    mutations: {
        updateData(state, data) {
            let stateData = {};
            stateData.loaded = true;

            state.data = stateData;
        }
    },
    actions: {




        getAllPriorityClients(actions, {page, amount, filter, sortcolumn, sortby}) {

            return new Promise(function (resolve, reject) {
                    api.sendRequest('priorityClient/get', {
                        buids: actions.rootGetters['data/getSelectedBranchesIds'].join(","),
                        page,
                        amount,
                        sortcolumn,
                        sortby
                    }, filter).then(
                        (ans) => {
                            if (ans["PriorityClients"] == null)
                                return reject('Could not get PriorityClients ');

                            resolve(ans);
                        }, (error) => {
                            reject(error.message)
                        })
                })

        },

        getPriorityClientById(actions, id) {
            return new Promise((resolve, reject) => {

                api.sendRequest('priorityClient/id/' + id).then(
                    (ans) => {

                        if (ans["PriorityClient"] == null)
                            return reject('Could not get priorityClient ');

                        resolve(ans["PriorityClient"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },



    },
};
