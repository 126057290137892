<template>
  <div id="app-inner" >
    <notification-listener/>
    <router-view/>
  </div>
</template>

<style>
html, body, #app {
  height: 100%;
}
</style>
<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import $ from 'jquery'
import NotificationListener from '@core/components/toastification/NotificationListener'

export default {
  name: "login",
  components: { NotificationListener },
  watch:{
    isBodyScrollDisabled: {
      handler: function (value) {
        this.toggleBodyClass(value ? 'addClass' : 'removeClass', 'overflow-hidden')
      },
      immediate: true
    }
  },
  data() {
    return {
      notificationAnimation: {
        enter(element) {
          let id = parseInt($(element).data('id'));
          let elementData = this.$data.list.find(o => o.id == id);
          console.log("Notification in", elementData);
          if (elementData != null && typeof elementData.data === "object" && typeof elementData.data.onEnter == "function") {
            elementData.data.onEnter(elementData.data)
          }
          return {
            height: [element.clientHeight, 0],
          }
        },
        leave(element) {
          let id = parseInt($(element).data('id'));
          let elementData = this.$data.list.find(o => o.id == id);
          console.log("Notification out", elementData);
          if (elementData != null && typeof elementData.data === "object" && typeof elementData.data.onClose == "function") {
            elementData.data.onClose(elementData.data)
          }
          return {
            height: 0,
            opacity: 0
          }
        }
      }
    }
  },
  computed: {
    // ...mapState('user', ['status'])
    ...mapGetters('appConfig', ['isBodyScrollDisabled']),
    ...mapState({
      user: function (store) {
        return {sidebar: store.user.status, user: store.user.status};
      }
    })
  },
  created() {
    this.$root.$on('bv::modal::shown', this.autoHidePopover);
  },
  beforeDestroy() {
    this.$root.$off('bv::modal::shown', this.autoHidePopover);
  },
  methods:{
    //Auto hide popover on modal open
    autoHidePopover() {
      this.$root.$emit('bv::hide::popover');
    },
    toggleBodyClass(addRemoveClass, className) {
      const el = document.body;

      if (addRemoveClass === 'addClass') {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
  }

}
</script>
