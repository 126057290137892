import api from '../../services/api.service'

export const moduleModule = {
  namespaced: true,
  actions: {
    getAllModules() {
      return new Promise(function (resolve, reject) {
        api.sendRequest('permissions/modules/all')
          .then(
            (ans) => {
              resolve(ans['modules'])
            }, (error) => {

              reject(error.message)
            })
      })
    },
    getOrgModules(actions,orgId) {
      return new Promise(function (resolve, reject) {
        api.sendRequest('permissions/modules/get/org/'+(orgId != null ? orgId :''))
          .then(
            (ans) => {
              resolve(ans['modules'])
            }, (error) => {
              reject(error.message)
            })
      })
    },
    updateOrgModules({dispatch}, { orgId,modulesName }) {
      return new Promise(function (resolve, reject) {
        api.sendRequest('permissions/modules/set/org/'+orgId,null,modulesName)
          .then(
            (ans) => {
              dispatch('notifications/success',
                {title: {text:'%s updated',arguments:['modules']}, message:{text:'%s updated successfully',arguments:['modules']} },
                {root: true});
              resolve(ans['modules'])
            }, (error) => {
              dispatch('notifications/error',
                {
                  title: {text:'Update %s',arguments:['modules']},
                  message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                },
                {root: true});
              reject(error.message)
            })
      })
    },
  }
}
