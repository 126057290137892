import api from "../../../services/api.service"


export const contractTemplateModule = {
    namespaced: true,
    state: {
        data: {types: [], categories: []},
    },
    getters: {
        canReadRealEstate(state, getters, rootState, rootGetters) {
            return rootGetters['user/canRead']('real_estate');
        },
        canEditRealEstate(state, getters, rootState, rootGetters) {
            return rootGetters['user/canEdit']('real_estate');
        },
        getAllCategories(state) {
            if (state.data == null || state.data.categories == null)
                return [];
            return state.data.categories;
        },

        getAllTypes(state) {
            if (state.data == null || state.data.types == null)
                return [];
            return state.data.types;
        },
        allStatusNoActive() {
            return [
                {value: "NEW", text: 'NEW'},
                {value: 'EXPOSE', text: 'EXPOSE'},
                {value: 'DISABLED', text: 'DISABLED'}
            ]
        },
        allStatus() {
            return [
                {value: "NEW", text: 'NEW'},
                {value: 'EXPOSE', text: 'EXPOSE'},
                {value: 'ACTIVE', text: 'ACTIVE'},
                {value: 'DISABLED', text: 'DISABLED'}
            ]
        },


    },
    mutations: {
        updateData(state, data) {
            let stateData = {};
            stateData.loaded = true;
            stateData.categories = data["Categories"];
            stateData.types = data["Types"];
            state.data = stateData;
        }
    },
    actions: {


        getAllTemplates(actions, {page, amount, filter, sortcolumn, sortby}) {

            return new Promise(function (resolve, reject) {
                api.sendRequest('contractTemplate/get', {
                    buids: actions.rootGetters['data/getSelectedBranchesIds'].join(","),
                    page,
                    amount,
                    sortcolumn,
                    sortby
                }, filter).then(
                    (ans) => {
                        if (ans["Templates"] == null)
                            return reject('Could not get Templates ');

                        resolve(ans);
                    }, (error) => {
                        reject(error.message)
                    })
            })

        },

        getContractTemplateById(actions, id) {
            return new Promise((resolve, reject) => {

                api.sendRequest('contractTemplate/id/' + id).then(
                    (ans) => {

                        if (ans["ContractTemplate"] == null)
                            return reject('Could not get contractTemplate ');

                        resolve(ans["ContractTemplate"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        getDefaultContractTemplate(actions, buid) {
            return new Promise((resolve, reject) => {
                console.log("getDefaultContractTemplate buid: ", buid)
                api.sendRequest('contractTemplate/default/buid/' + buid).then(
                    (ans) => {

                        if (ans["ContractTemplate"] == null)
                            return reject('Could not get contractTemplate ');

                        resolve(ans["ContractTemplate"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },

        updateContractTemplate(actions, contractTemplate) {
            return new Promise((resolve, reject) => {

                api.sendRequest('contractTemplate/update', null, contractTemplate).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Update contractTemplate', message: 'ContractTemplate updated successfully'},
                            {root: true});

                        if (ans["ContractTemplate"] == null)
                            return reject('Could not update contractTemplate ');

                        resolve(ans["ContractTemplate"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        addContractTemplate(actions, defaultContractTemplate) {
            return new Promise((resolve, reject) => {
                api.sendRequest('contractTemplate/add', null, defaultContractTemplate).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Add contractTemplate', message: 'ContractTemplate created successfully'},
                            {root: true});

                        if (ans["ContractTemplateId"] == null)
                            return reject('Could not create contractTemplate ');

                        console.log("contractTemplate answer created: ", ans)
                        resolve(ans["ContractTemplateId"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        }

    },
};
