import api from "../../../services/api.service"


export const whatsappModule = {
    namespaced: true,
    state: {},
    getters: {
        canReadWhatsapp(state, getters, rootState, rootGetters) {
            return rootGetters['user/canRead']('whatsapp');
        },
        canEditWhatsapp(state, getters, rootState, rootGetters) {
            return rootGetters['user/canEdit']('whatsapp');
        },
    },
    mutations: {},
    actions: {
        getData() {
            return new Promise(function (resolve) {

                resolve();

            })
        },

        getAllMessages(actions) {

            return new Promise(function (resolve, reject) {
                api.sendRequest('whatsapp/unseen',{
                    buids: actions.rootGetters['data/getSelectedBranchesIds'].join(","),

                }).then(
                    (ans) => {
                        if (ans["messages"] == null)
                            return reject('Could not get Messages ');

                        resolve(ans);
                    }, (error) => {

                        reject(error.message)
                    })
            })

        },
        getMessagesByClientId(actions, clientId) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('whatsapp/id/' + clientId).then(
                    (ans) => {
                        resolve(ans);
                    }, (error) => {

                        reject(error.message)
                    })
            })
        },
        sendWhatsappMessage(actions, {newMessage,template}) {
            console.log("sendWhatsappMessage", newMessage)
            return new Promise(function (resolve, reject) {
                api.sendRequest('whatsapp/send/'+template,null,newMessage).then(
                    (ans) => {
                        if (ans["messages"] == null)
                            return reject('Could not send message ');
                        actions.dispatch('notifications/success',
                            {
                                title: {text: 'Whatsapp'},
                                message: {text: '%s sent successfully', arguments: ['Message']}
                            },
                            {root: true});
                        resolve(ans);
                    }, (error) => {
                        actions.dispatch('notifications/error',
                            {
                                title: {text: 'Whatsapp '},
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },

        getWhatsappList() {

            return new Promise(function (resolve, reject) {
                api.sendRequest('whatsapp/getWhatsappList', null, null).then(
                    (ans) => {
                        if (ans["Whatsapps"] == null)
                            return reject('Could not get whatsapps ');

                        resolve(ans["Whatsapps"]);
                    }, (error) => {

                        reject(error.message)
                    })
            })

        },

        getWhatsappById(actions, id) {
            return new Promise((resolve, reject) => {

                api.sendRequest('whatsapp/id/' + id, {}).then(
                    (ans) => {

                        if (ans["whatsapp"] == null)
                            return reject('Could not get whatsapp ');

                        resolve(ans["whatsapp"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },

        updateWhatsapp(actions, whatsapp) {
            return new Promise((resolve, reject) => {

                api.sendRequest('whatsapp/update', null, whatsapp).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Update whatsapp', message: 'Whatsapp updated successfully'},
                            {root: true});

                        if (ans["whatsappId"] == null)
                            return reject('Could not update whatsapp ');

                        resolve(ans["whatsappId"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        createWhatsapp(actions, whatsapp) {
            return new Promise((resolve, reject) => {


                api.sendRequest('whatsapp/add', null, whatsapp).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Add whatsapp', message: 'Whatsapp added successfully'},
                            {root: true});

                        if (ans["whatsappId"] == null)
                            return reject('Could not create whatsapp ');

                        resolve(ans["whatsappId"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },

    },
};
