import api from "../../../services/api.service"


export const AdsVideosModule = {
    namespaced: true,
    state: {

    },
    getters: {

    },
    mutations: {
        updateData(state, data) {
            if (data["ProfileNames"] != null)
                state.data.profiles = data["ProfileNames"];
            state.data.loaded = true;
        }
    },
    actions: {


        getVideos(actions, filter) {
            if (typeof filter !== "object")
                filter = {};
            let buids;

            if(filter.buids != null && Array.isArray(filter.buids)){
                buids = filter.buids;
                delete filter.buids;
                console.log("getVideos: is in if",buids)
            }else{

                buids = actions.rootGetters['data/getSelectedBranchesIds'];

                console.log("getVideos: is in else",buids)
            }

            return new Promise(function (resolve, reject) {
                api.sendRequest('ads/videos', {buids: buids.join(", ")},filter).then(
                    (ans) => {
                        if (ans["videos"] == null)
                            return reject('videos failed to refresh');
                        resolve(ans["videos"]);
                    }, (error) => {
                        reject(error.message)
                    })

            })
        },




        updateVideo({dispatch}, {id, video}) {
            return new Promise(function (resolve, reject) {
                //Check if client is not undefined
                if (typeof video !== "object")
                    return reject("Invalid profile");

                let request = ({...video, id: id, creationTime: null});

                api.sendRequest('ads/videos/update',null, request).then(
                    (ans) => {
                        //In case of success send notification
                        dispatch('notifications/success',
                            {title: 'Update advertise', message: 'Advertise updated successfully'},
                            {root: true});

                        resolve(ans)
                    }, (error) => {
                        dispatch('notifications/error',
                            {
                                title: 'Update advertise',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },

        deleteAd({dispatch}, {id, video}) {
            return new Promise(function (resolve, reject) {
                //Check if client is not undefined
                if (typeof video !== "object")
                    return reject("Invalid profile");

                let request = ({...video, id: id, creationTime: null});

                api.sendRequest('ads/videos/delete',null, request).then(
                    (ans) => {
                        //In case of success send notification
                        dispatch('notifications/success',
                            {title: 'Delete advertise', message: 'Advertise deleted successfully'},
                            {root: true});

                        resolve(ans)
                    }, (error) => {
                        dispatch('notifications/error',
                            {
                                title: 'Delete advertise',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        updateType({dispatch}, {id, profile}) {
            return new Promise(function (resolve, reject) {
                //Check if client is not undefined
                if (typeof profile !== "object")
                    return reject("Invalid profile");
                //generate a new object in order to not update the client object and add the field action
                let body = {...profile, id: id};


                api.sendRequest('account/profile/editType', body).then(
                    (ans) => {
                        //In case of success send notification
                        dispatch('notifications/success',
                            {title: 'Update profile', message: 'Profile updated successfully'},
                            {root: true});

                        dispatch('getData', true);
                        resolve(ans)
                    }, (error) => {
                        dispatch('notifications/error',
                            {
                                title: 'Update profile',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        getVideosByCampaign(actions, campaignid) {

            return new Promise(function (resolve, reject) {
                api.sendRequest('ads/videos/campaign/get/'+campaignid, {}).then(
                    (ans) => {
                        if (ans["videos"] == null)
                            return reject('videos failed to refresh');
                        resolve(ans["videos"]);
                    }, (error) => {
                        reject(error.message)
                    })

            })
        },
        setCampaignVideos(actions, {campaignid,videoIds}) {

            return new Promise(function (resolve, reject) {
                if(!Array.isArray(videoIds)){
                    return reject("Invalid videos");
                }
                api.sendRequest('ads/videos/campaign/set/'+campaignid, {
                    id:campaignid,
                    vid:videoIds.join(","),
                }).then(
                    (a) => {
                        actions.dispatch('notifications/success',
                            {title: 'Update Videos', message: 'Videos updated successfully'},
                            {root: true});
                        resolve(a['videos']);
                    }, (error) => {
                        actions.dispatch('notifications/Error',
                            {title: 'Update Videos', message: 'Could not update video for device'},
                            {root: true});
                        reject(error.message)
                    })

            })
        },





    },
};
