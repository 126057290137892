import api from "../../../services/api.service"


export const googleApiModule = {
    namespaced: true,
    state: {

    },
    getters: {

    },
    mutations: {},
    actions: {
        calculateDistance(actions,{origin, destination}){
            console.log("calculateDistance()",{origin, destination})
            return new Promise((resolve,reject) => {

                if(!Array.isArray(origin)){
                    if(typeof origin === 'string' || origin instanceof String)
                        origin = [origin];
                    else
                        return reject("invalid origin");
                }

                if(!Array.isArray(destination))
                    return reject("invalid destination");

                let service = new window.google.maps.DistanceMatrixService();
                service.getDistanceMatrix(
                    {
                        origins: origin,
                        destinations: destination,
                        travelMode: 'DRIVING',
                    }, callback);

                function callback(response, status) {
                    console.log({response, status});
                    if(status != "OK")
                        reject(response);

                    /**
                     * response:
                     rows: Array(1)
                     0:
                     elements: Array(2)
                     0:
                     distance: {text: "737 km", value: 737239}
                     duration: {text: "6 hours 41 mins", value: 24055}
                     status: "OK"
                     __proto__: Object
                     1:
                     distance: {text: "552 km", value: 552133}
                     duration: {text: "5 hours 2 mins", value: 18137}
                     status: "OK"
                     */

                    let ans = response["rows"].reduce((obj,elementsObjects,index)=>{
                        return Object.assign(obj,{
                            [origin[index]]:{
                                ...elementsObjects["elements"].reduce((obj2,elementObj,index2)=>Object.assign(obj2,{[destination[index2]]:elementObj}),{})
                            }
                        })
                    },{});
                    console.log("calculateDistance() obj",ans)
                    resolve(ans)

                }
            })

        }
    }
}