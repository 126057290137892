import api from "../../../services/api.service"


export const ticketModule = {
    namespaced: true,
    state: {
        data: {types:[],categories:[]},
    },
    getters: {
        canReadRealEstate(state, getters, rootState, rootGetters) {
            return rootGetters['user/canRead']('real_estate');
        },
        canEditRealEstate(state, getters, rootState, rootGetters) {
            return rootGetters['user/canEdit']('real_estate');
        },
        getAllCategories(state) {
            if (state.data == null || state.data.categories == null)
                return [];
            return state.data.categories;
        },

        getAllTypes(state) {
            if (state.data == null || state.data.types == null)
                return [];
            return state.data.types;
        },

        allTicketStatus() {
            return [
                {value: "NEW", text: 'NEW TICKET'},
                {value: 'HANDLED', text: 'HANDLED CASE'}

               ]
        },


    },
    mutations: {
        updateData(state, data) {
            let stateData = {};
            stateData.loaded = true;
            stateData.categories = data["Categories"];
            stateData.types = data["Types"];
            state.data = stateData;
        }
    },
    actions: {
        getData({state, commit, dispatch}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/data').then(
                    (ans) => {

                        commit('updateData', ans);
                        resolve(state);
                    }, (error) => {
                        dispatch('notifications/error',
                            {
                                title: 'Getting data',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        copySuccess(actions,id){
            actions.dispatch('notifications/success',
                {title: 'Success', message: 'Ticket  ID '+id+' copied'},
                {root: true});

        },


        getAllTickets(actions,{ page, amount, filter,sortcolumn, sortby }) {

            return new Promise(function (resolve, reject) {
                actions.dispatch('getData', false).finally(() => {
                    api.sendRequest('ticket/get', {
                        buids: actions.rootGetters['data/getSelectedBranchesIds'].join(","),
                        page,
                        amount,
                        sortcolumn,
                        sortby
                    },filter).then(
                        (ans) => {
                            if (ans["Tickets"] == null)
                                return reject('Could not get Tickets ');

                            resolve(ans);
                        }, (error) => {
                            reject(error.message)
                        })
                })

            })
        },

        getTicketById(actions,id){
            return new Promise((resolve, reject) => {

                api.sendRequest('ticket/id/'+id, {}).then(
                    (ans) => {

                        if (ans["Ticket"] == null)
                            return reject('Could not get ticket ');

                        resolve(ans["Ticket"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },

        updateTicket(actions,ticket){
            return new Promise((resolve, reject) => {

                api.sendRequest('ticket/update',null, ticket).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Update ticket', message: 'Ticket updated successfully'},
                            {root: true});

                        if (ans["TicketId"] == null)
                            return reject('Could not update ticket ');

                        resolve(ans["TicketId"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        sendEmail(actions,ticket){
            return new Promise((resolve, reject) => {

                api.sendRequest('ticket/sendEmail',null, ticket).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Email Support', message: 'Email was sent successfully'},
                            {root: true});

                        if (ans["TicketId"] == null)
                            return reject('Could not sent email');

                        resolve(ans["TicketId"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        addTicket(actions,ticket){
            return new Promise((resolve, reject) => {
                api.sendRequest('ticket/add', null,ticket).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Add ticket', message: 'Ticket created successfully'},
                            {root: true});

                        if (ans["TicketId"] == null)
                            return reject('Could not create ticket ');

                        console.log("ticket answer created: ",ans)
                        resolve(ans["TicketId"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        }
    },
};
