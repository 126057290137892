<template>
  <span></span>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  name: 'NotificationListener',
  data:()=>({
    listener(){}
  }),
  created() {
    let $this = this;
    this.$root.$on('crm::notify',this.listener = notification => {
      $this.showToast(notification);
    })
  },
  beforeDestroy() {
    this.$root.$off('crm::notify',this.listener );
  },
  methods:{
    showToast(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.getText(message.title),
          icon:message.icon,
          text: this.getText(message.message),
          variant:message.variant,
          timeout: message.sticky !== true ? 5000 : false
        },
      })
      //this.playAudio()
    },
    getText(message){
      if(message != null && typeof message !== 'string' && message.text != null){
        let args = message.arguments || [];
        return this.msg(message.text).format(args.map(s=>this.msg(s)));
      }
      return this.msg(message);
    },
    playAudio(type) {
      let audio = new Audio("/sounds/notification1.mp3");// path to file
      audio.play().then(() => {
      }, e => console.log(e))
    },
    getLongAudio:()=>()=> {
      return new Audio("/sounds/long_notification.mp3");// path to file;
    }
  }
}
</script>
