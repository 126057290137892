import api from "../../../services/api.service"


export const UIComponentsModule = {
    namespaced: true,
    state: {

    },
    getters: {},
    mutations: {

    },
    actions: {
        getColumns({dispatch},{level,id}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('ui-components', {action: "columns",level,id}).then(
                    (ans) => {


                        resolve(ans["Columns"]);
                    }, (error) => {
                        dispatch('notifications/error',
                            {
                                title: 'Getting columns',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        getAllColumns({dispatch}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('ui-components', {action: "all-columns"}).then(
                    (ans) => {


                        resolve(ans["Columns"]);
                    }, (error) => {
                        dispatch('notifications/error',
                            {
                                title: 'Getting all columns',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        saveColumns({dispatch} ,{cols}){
            return new Promise(function (resolve, reject) {
                api.sendRequest('ui-components', {action: "save-columns","cols":JSON.stringify(cols)}).then(
                    (ans) => {
                        dispatch('notifications/success',
                            {
                                title: 'Update columns',
                                message: 'Columns updated successfully'
                            },
                            {root: true});

                        resolve(ans["Columns"]);
                    }, (error) => {
                        dispatch('notifications/error',
                            {
                                title: 'Update columns',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        }
    }
};