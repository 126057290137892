import api from "@/services/api.service";

export const realEstatesContractsAlertsModule = {
    namespaced: true,
    state: {
        data: {actions: [], codes: [], severities: []},
    },
    getters: {
        alertCodes(state) {
            return [
                ...state.data.codes
            ]
        },
        severities(state) {
            return [
                ...state.data.severities
            ]
        },
        actions(state) {
            return [
                ...state.data.actions
            ]
        }
    },
    mutations: {
        updateData(state, data) {
            if (data == null) {
                return
            }
            state.data.actions = data.alertActions;
            state.data.codes = data.alertCodes;
            state.data.severities = data.severity;
        }
    },
    actions: {
        getData({commit}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/contract/alerts/data').then(
                    (ans) => {
                        commit('updateData', ans)
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        getAllContractAlerts(actions, contractId) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/contract/alerts/get/' + contractId).then(
                    (ans) => {
                        if (ans["alerts"] == null)
                            return reject('Could not get contracts alerts');
                        resolve(ans);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        addContractAlert(actions, {contractId,alert}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/contract/alerts/add/' + contractId, null, alert).then(
                    (ans) => {
                        if (ans["alert"] == null)
                            return reject('Could not get contracts alerts');
                        resolve(ans["alert"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        editContractAlert(actions, {id, alert}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/contract/alerts/edit/' + id, null, alert).then(
                    (ans) => {
                        if (ans["alert"] == null)
                            return reject('Could not get contracts alerts');
                        resolve(ans["alert"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        removeContractAlert(actions, alertId) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/contract/alerts/remove/' + alertId).then(
                    (ans) => {
                        if (ans["alerts"] == null)
                            return reject('Could not get contracts alerts');
                        resolve(ans);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
    }
}
