import api from '../../services/api.service'

export const roleModule = {
  namespaced: true,
  state: {
    permissions: {},
    clientsGroups: [],
    status: {
      loaded: false
    },

  },
  mutations: {
    updateData(state, data) {
      state.permissions = data['Permissions']
      state.clientsGroups = data['ClientsGroups']
      state.status.loaded = false
    }
  },
  getters: {

    getClientsGroups(state) {
      return state.clientsGroups
    },
    getPermissions(state) {
      return state.permissions
    },
    canReadPermission(state, getters, rootState, rootGetters) {
      return rootGetters['user/canRead']('permission')
    },

  },
  actions: {
    getAll(actions) {
      return new Promise(function (resolve, reject) {
        api.sendRequest('role/get')
          .then(
            (ans) => {
              if (ans['Roles'] == null) {
                return reject('Roles failed to refresh')
              }
              resolve(ans['Roles'])
            }, (error) => {

              reject(error.message)
            })
      })
    },
    //Get a role
    getRole(actions, { id }) {
      return new Promise(function (resolve, reject) {
        api.sendRequest('superuser/role/id/' + id)
          .then(
            (ans) => {
              resolve(ans.Role)
            }, (error) => {

              reject(error.message)
            })
      })
    },
    register({ dispatch }, role) {
      return new Promise(function (resolve, reject) {
        //Check if permission is not undefined
        if (typeof role !== 'object') {
          reject('Invalid role permission')
        }

        api.sendRequest('role/add', null, role)
          .then(
            (ans) => {
              dispatch('notifications/success',
                {title: {text:'Register %s',arguments:['role']}, message:{text:'%s created successfully with id: %s',arguments:['role',ans.RoleId]} },
                {root: true});
              resolve(ans["RoleId"])
            }, (error) => {
              dispatch('notifications/error',
                {
                  title: {text:'Register %s',arguments:['role']},
                  message: (typeof error === 'object' && error.message !== undefined ? error.message : error)
                },
                { root: true })
              reject(error.message)
            })
      })
    },
    updateRole({ dispatch }, role) {
      return new Promise(function (resolve, reject) {
        //Check if permission is not undefined
        if (typeof role !== 'object') {
          reject('Invalid role permission')
        }

        api.sendRequest('role/update', null, role)
          .then(
            (ans) => {
              //In case of success send notification
              dispatch('notifications/success',
                {title: {text:'%s updated',arguments:['Role']}, message:{text:'%s updated successfully',arguments:['Role']} },
                {root: true});
              resolve(ans)
            }, (error) => {
              dispatch('notifications/error',
                {
                  title: {text:'Update %s',arguments:['role']},
                  message: (typeof error === 'object' && error.message !== undefined ? error.message : error)
                },
                { root: true })
              reject(error.message)
            })
      })
    },
  }
};
