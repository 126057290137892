import api from "../../services/api.service"


export const permissionsModule = {
    namespaced: true,
    state: {
        permissions: {},
        clientsGroups: [],
        modules: [],
        status: {
            loaded: false
        },


    },
    mutations: {
        updateData(state, data) {
            state.permissions = data["Permissions"];
            state.clientsGroups = data["ClientsGroups"];
            state.modules = data["modules"];
            state.status.loaded = false;
        }
    },
    getters: {
        getClientsGroups(state) {
            return state.clientsGroups
        },
        getModules(state) {
            return state.modules
        },
        getPermissions(state) {
            return state.permissions
        },
        canReadPermission(state, getters, rootState, rootGetters) {
            return rootGetters['user/canRead']('permission');
        },
    },
    actions: {
        refreshData(actions) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('permissions/data').then(
                    (ans) => {

                        actions.commit('updateData',ans)
                        resolve(ans);
                    }, (error) => {
                        actions.dispatch('notifications/error',
                            {
                                title: 'Getting data',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        //Get a permission
        get(actions, id) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('permissions/permissionsByRoleId/'+id).then(
                    (ans) => {
                        resolve(ans.Permissions)
                    }, (error) => {

                        reject(error.message)
                    })
            })
        },
        update({dispatch}, {roleId, permissions}) {
            return new Promise(function (resolve, reject) {
                if (isNaN(roleId))
                    return reject("Invalid role id");

                if (permissions == null)
                    return reject("Invalid permission");

                //generate a new object in order to not update the permission object and add the field action
                if (typeof permissions === "object")
                    permissions = Object.values(permissions);

                api.sendRequest('permissions/update/'+roleId,null, permissions).then(
                    (ans) => {
                        dispatch('notifications/success',
                          {title: {text:'%s updated',arguments:['permission']}, message:{text:'%s updated successfully',arguments:['permission']} },
                          {root: true});
                        //send error
                        resolve(ans)
                    }, (error) => {
                        dispatch('notifications/error',
                            {
                                title: {text:'Update %s',arguments:['permission']},
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },

        reload({dispatch},) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('permissions/reload"').then(
                    (ans) => {
                        dispatch('loadData',true);
                        resolve(ans)
                    }, (error) => {

                        reject(error.message)
                    })
            })
        },
    }
};
