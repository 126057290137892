import api from "@/services/api.service";
import {vue} from "@/main";

export const damagesModule = {
    namespaced: true,
    state: {
        data: {types: [], categories: []},
    },
    getters: {},
    mutations: {},
    actions: {
        getAllDamage(actions, {page, amount, filter, sortcolumn, sortby}) {
            return api.sendRequest('damage/get/all', {
                buids: actions.rootGetters['data/getSelectedBranchesIds'].join(","),
                page, amount, sortcolumn, sortby
            }, filter);
        },
        getAllDamagesCustom(actions, {page, amount, filter, sortcolumn, sortby}) {
            return api.sendRequest('damage/get/allCustom', {
                buids: actions.rootGetters['data/getSelectedBranchesIds'].join(","),
                page, amount, sortcolumn, sortby
            }, filter);
        },
        getById(actions, id) {
            return api.get('damage/get/id/' + id)
                .then(
                    response => response,
                    error => {
                        actions.dispatch('notifications/error',
                            {
                                title: {text: '%s - failed to retrieve', arguments: ['damage']},
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        return error;
                    }
                )
        },
        getContractDamage(actions, contractId) {
            return api.get('damage/get/contract/' + contractId, {contractId});
        },
        updateDamage(actions, {id, request}) {
            return vue.$api.baseApi().post('damage/update/' + id, request).then(
                response => {
                    actions.dispatch('notifications/success',
                        {
                            // title: vue.$t('update_success'),
                            title: 'Update damage',
                            message: 'Damage updated successfully',
                        },
                        {root: true});
                    return response.data.objects
                },
                error => {
                    actions.dispatch('notifications/error',
                        {
                            title: {text: '%s - failed to retrieve', arguments: ['damage']},
                            message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                        },
                        {root: true});
                    return error;
                }
            )
        }
    }
}
