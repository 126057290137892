import api from "../../../services/api.service"


export const hostawayModule = {
    namespaced: true,
    state: {},
    getters: {
        canReadHostaway(state, getters, rootState, rootGetters) {
            return rootGetters['user/canRead']('hostaway');
        },
        canEditHostaway(state, getters, rootState, rootGetters) {
            return rootGetters['user/canEdit']('hostaway');
        },
    },
    mutations: {},
    actions: {
        getData() {
            return new Promise(function (resolve) {

                resolve();

            })
        },

        getAllMessages(actions) {

            return new Promise(function (resolve, reject) {
                api.sendRequest('hostaway/unseen').then(
                    (ans) => {
                        if (ans["messages"] == null)
                            return reject('Could not get Messages ');

                        resolve(ans);
                    }, (error) => {

                        reject(error.message)
                    })
            })

        },
        getMessagesByReservationId(actions, reservationId) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('hostaway/id/' + reservationId).then(
                    (ans) => {
                        if (ans["messages"] == null)
                            return reject('Could not get messages ');

                        resolve(ans);
                    }, (error) => {

                        reject(error.message)
                    })
            })
        },
        sendHostawayMessage(actions, newMessage) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('hostaway/send',null,newMessage).then(
                    (ans) => {
                        if (ans["messages"] == null)
                            return reject('Could not send message ');
                        actions.dispatch('notifications/success',
                            {
                                title: {text: 'Hostaway'},
                                message: {text: '%s sent successfully', arguments: ['Message']}
                            },
                            {root: true});
                        resolve(ans);
                    }, (error) => {
                        actions.dispatch('notifications/error',
                            {
                                title: {text: 'Hostaway '},
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        blockCalendarDates(actions,{listingId, request}) {
            let $this = this;
            return new Promise(function (resolve, reject) {
                $this.$api.baseApi().post('hostaway/blockCalendar/' + listingId,request )
                    .then((response) => {
                        actions.dispatch('notifications/success',
                            {title: {text: 'Dates %s', arguments: ['blocked']}, message: {text: '%s successfully', arguments: ['']}},
                            {root: true});

                        resolve(response.data.objects);
                    }, (error) => {

                        actions.dispatch('notifications/error',
                            {
                                title: 'Blocking dates',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        unblockCalendarDates(actions,{calendarId}) {
            let $this = this;
            return new Promise(function (resolve, reject) {
                $this.$api.baseApi().post('hostaway/unblockCalendar/' + calendarId )
                    .then((response) => {
                        actions.dispatch('notifications/success',
                            {title: {text: 'Dates %s', arguments: ['unblocked']}, message: {text: '%s successfully', arguments: ['']}},
                            {root: true});

                        resolve(response.data.objects);
                    }, (error) => {

                        actions.dispatch('notifications/error',
                            {
                                title: 'Unblocking dates',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        getHostawayList() {

            return new Promise(function (resolve, reject) {
                api.sendRequest('hostaway/getHostawayList', null, null).then(
                    (ans) => {
                        if (ans["Hostaways"] == null)
                            return reject('Could not get hostaways ');

                        resolve(ans["Hostaways"]);
                    }, (error) => {

                        reject(error.message)
                    })
            })

        },

        getHostawayById(actions, id) {
            return new Promise((resolve, reject) => {

                api.sendRequest('hostaway/id/' + id, {}).then(
                    (ans) => {

                        if (ans["hostaway"] == null)
                            return reject('Could not get hostaway ');

                        resolve(ans["hostaway"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },

        updateHostaway(actions, hostaway) {
            return new Promise((resolve, reject) => {

                api.sendRequest('hostaway/update', null, hostaway).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Update hostaway', message: 'Hostaway updated successfully'},
                            {root: true});

                        if (ans["hostawayId"] == null)
                            return reject('Could not update hostaway ');

                        resolve(ans["hostawayId"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        createHostaway(actions, hostaway) {
            return new Promise((resolve, reject) => {


                api.sendRequest('hostaway/add', null, hostaway).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Add hostaway', message: 'Hostaway added successfully'},
                            {root: true});

                        if (ans["hostawayId"] == null)
                            return reject('Could not create hostaway ');

                        resolve(ans["hostawayId"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },

    },
};
